import { ConfigRequestBody, EnhancedConfig, Job, JobPatchRequest, Config } from "@speakap/types";
import { LOCKED } from "http-status-codes";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";
import { push, CallHistoryMethodAction } from "connected-react-router";

import { ApplicationState } from "../reducer";
import {
    deleteJob,
    getConfigs,
    patchJob,
    postJob,
    postNewConfig,
    postUpdatedConfig,
    syncUsers,
} from "../api";
import routes from "../routes";
import { selectEditItem } from "./selectors";

export const CREATE_NEW_CONFIG_START = "user-sync/configs/CREATE_NEW_CONFIG_START";
export const CREATE_NEW_CONFIG_STOP = "user-sync/configs/CREATE_NEW_CONFIG_STOP";

export const EDIT_CONFIG_START = "user-sync/configs/EDIT_CONFIG_START";
export const EDIT_CONFIG_STOP = "user-sync/configs/EDIT_CONFIG_STOP";

export const FETCH_CONFIGS_REQUEST = "user-sync/configs/FETCH_CONFIGS_REQUEST";
export const FETCH_CONFIGS_SUCCESS = "user-sync/configs/FETCH_CONFIGS_SUCCESS";
export const FETCH_CONFIGS_ERROR = "user-sync/configs/FETCH_CONFIGS_ERROR";

export const SET_SCHEDULE_REQUEST = "user-sync/configs/SET_SCHEDULE_REQUEST";
export const SET_SCHEDULE_SUCCESS = "user-sync/configs/SET_SCHEDULE_SUCCESS";
export const SET_SCHEDULE_ERROR = "user-sync/configs/SET_SCHEDULE_ERROR";

export const SYNC_USERS_REQUEST = "user-sync/configs/SYNC_USERS_REQUEST";
export const SYNC_USERS_SUCCESS = "user-sync/configs/SYNC_USERS_SUCCESS";
export const SYNC_USERS_ERROR = "user-sync/configs/SYNC_USERS_ERROR";

export const SUBMIT_NEW_CONFIG_REQUEST = "user-sync/configs/SUBMIT_NEW_CONFIG_REQUEST";
export const SUBMIT_NEW_CONFIG_SUCCESS = "user-sync/configs/SUBMIT_NEW_CONFIG_SUCCESS";
export const SUBMIT_NEW_CONFIG_ERROR = "user-sync/configs/SUBMIT_NEW_CONFIG_ERROR";
export const SUBMIT_UPDATED_CONFIG_REQUEST = "user-sync/configs/SUBMIT_UPDATED_CONFIG_REQUEST";
export const SUBMIT_UPDATED_CONFIG_SUCCESS = "user-sync/configs/SUBMIT_UPDATED_CONFIG_SUCCESS";
export const SUBMIT_UPDATED_CONFIG_ERROR = "user-sync/configs/SUBMIT_UPDATED_CONFIG_ERROR";

export const SET_SELECTED_CONFIG = "user-sync/configs/SET_SELECTED_CONFIG";

export const UNSET_SCHEDULE_REQUEST = "user-sync/configs/UNSET_SCHEDULE_REQUEST";
export const UNSET_SCHEDULE_SUCCESS = "user-sync/configs/UNSET_SCHEDULE_SUCCESS";
export const UNSET_SCHEDULE_ERROR = "user-sync/configs/UNSET_SCHEDULE_ERROR";

export const UPDATE_EDIT_CONFIG = "user-sync/configs/UPDATE_EDIT_CONFIG";

interface CreateNewConfigStartAction {
    type: typeof CREATE_NEW_CONFIG_START;
}

interface CreateNewConfigStopAction {
    type: typeof CREATE_NEW_CONFIG_STOP;
}

interface EditConfigStartAction {
    payload: EnhancedConfig;
    type: typeof EDIT_CONFIG_START;
}

interface EditConfigStopAction {
    type: typeof EDIT_CONFIG_STOP;
}

interface FetchConfigsRequestAction {
    type: typeof FETCH_CONFIGS_REQUEST;
    payload: string;
}

interface FetchConfigsSuccessAction {
    type: typeof FETCH_CONFIGS_SUCCESS;
    payload: Array<EnhancedConfig>;
}

interface FetchConfigsErrorAction {
    type: typeof FETCH_CONFIGS_ERROR;
}

interface SetScheduleRequestAction {
    type: typeof SET_SCHEDULE_REQUEST;
}

interface SetScheduleSuccessAction {
    payload: Job;
    type: typeof SET_SCHEDULE_SUCCESS;
}

interface SetScheduleErrorAction {
    type: typeof SET_SCHEDULE_ERROR;
}

interface SubmitNewConfigRequestAction {
    type: typeof SUBMIT_NEW_CONFIG_REQUEST;
    payload: ConfigRequestBody;
}

interface SubmitNewConfigSuccessAction {
    type: typeof SUBMIT_NEW_CONFIG_SUCCESS;
    payload: EnhancedConfig;
}

interface SubmitNewConfigErrorAction {
    type: typeof SUBMIT_NEW_CONFIG_ERROR;
}

interface SubmitUpdatedConfigRequestAction {
    type: typeof SUBMIT_UPDATED_CONFIG_REQUEST;
    payload: { activeRevisionId: number; config: ConfigRequestBody };
}

interface SubmitUpdatedConfigSuccessAction {
    type: typeof SUBMIT_UPDATED_CONFIG_SUCCESS;
    payload: EnhancedConfig;
}

interface SubmitUpdatedConfigErrorAction {
    type: typeof SUBMIT_UPDATED_CONFIG_ERROR;
}

interface SetSelectedConfigAction {
    type: typeof SET_SELECTED_CONFIG;
    payload: string;
}

interface SyncUsersRequestAction {
    type: typeof SYNC_USERS_REQUEST;
}

interface SyncUsersSuccessAction {
    type: typeof SYNC_USERS_SUCCESS;
}

interface SyncUsersErrorAction {
    type: typeof SYNC_USERS_ERROR;
}

interface UnsetScheduleRequestAction {
    type: typeof UNSET_SCHEDULE_REQUEST;
}

interface UnsetScheduleSuccessAction {
    type: typeof UNSET_SCHEDULE_SUCCESS;
}

interface UnsetScheduleErrorAction {
    type: typeof UNSET_SCHEDULE_ERROR;
}

interface UpdateEditConfigAction {
    type: typeof UPDATE_EDIT_CONFIG;
    payload: Partial<ConfigRequestBody>;
}

export type ConfigsAction =
    | CreateNewConfigStartAction
    | CreateNewConfigStopAction
    | EditConfigStartAction
    | EditConfigStopAction
    | FetchConfigsRequestAction
    | FetchConfigsSuccessAction
    | FetchConfigsErrorAction
    | SetScheduleRequestAction
    | SetScheduleSuccessAction
    | SetScheduleErrorAction
    | SetSelectedConfigAction
    | SubmitNewConfigRequestAction
    | SubmitNewConfigSuccessAction
    | SubmitNewConfigErrorAction
    | SubmitUpdatedConfigRequestAction
    | SubmitUpdatedConfigSuccessAction
    | SubmitUpdatedConfigErrorAction
    | SyncUsersRequestAction
    | SyncUsersSuccessAction
    | SyncUsersErrorAction
    | UnsetScheduleRequestAction
    | UnsetScheduleSuccessAction
    | UnsetScheduleErrorAction
    | UpdateEditConfigAction;

type Thunk<T = void> = ThunkAction<
    Promise<T>,
    ApplicationState,
    void,
    CallHistoryMethodAction | ConfigsAction
>;

export const setSelectedConfig = (configName: string): SetSelectedConfigAction => ({
    payload: configName,
    type: SET_SELECTED_CONFIG,
});

export const createNewConfigStart = (): CreateNewConfigStartAction => ({
    type: CREATE_NEW_CONFIG_START,
});

export const createNewConfigStop = (): CreateNewConfigStopAction => ({
    type: CREATE_NEW_CONFIG_STOP,
});

export const editConfigStart = (config: EnhancedConfig): EditConfigStartAction => ({
    payload: config,
    type: EDIT_CONFIG_START,
});

export const editConfigStop = (): EditConfigStopAction => ({
    type: EDIT_CONFIG_STOP,
});

export const fetchConfigs = (networkId: string): Thunk => async dispatch => {
    try {
        dispatch({ payload: networkId, type: FETCH_CONFIGS_REQUEST });

        const response = await getConfigs({ networkId });
        dispatch({ payload: response.configs, type: FETCH_CONFIGS_SUCCESS });
    } catch (error) {
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_CONFIGS_ERROR,
        });
    }
};

export const setNewSchedule = (
    config: EnhancedConfig,
    schedule: string,
): Thunk<[string | null, Job | null]> => async dispatch => {
    try {
        dispatch({ type: SET_SCHEDULE_REQUEST });

        const job = await postJob(config, {
            networkId: config.networkId,
            resourceIdentifier: config.name,
            schedule,
        });
        dispatch({ payload: job, type: SET_SCHEDULE_SUCCESS });
        return [null, job];
    } catch (error) {
        dispatch({ type: SET_SCHEDULE_ERROR });
        return [
            `Error setting schedule: ${error instanceof Error ? error.message : "Error"}`,
            null,
        ];
    }
};

export const updateSchedule = (
    config: EnhancedConfig,
    jobId: string,
    jobRequest: JobPatchRequest,
): Thunk<[string | null, Job | null]> => async dispatch => {
    try {
        dispatch({ type: SET_SCHEDULE_REQUEST });

        const job = await patchJob(config, jobId, jobRequest);
        dispatch({ payload: job, type: SET_SCHEDULE_SUCCESS });

        return [null, job];
    } catch (error) {
        dispatch({ type: SET_SCHEDULE_ERROR });
        return [
            `Error updating schedule: ${error instanceof Error ? error.message : "Error"}`,
            null,
        ];
    }
};

export const setUpdatedSchedule = (jobRequest: JobPatchRequest): Thunk => async (
    dispatch,
    getState,
) => {
    const editItem = selectEditItem(getState());
    if (!editItem?.config.job?.id) {
        throw new Error("Cannot update schedule without scheduled config");
    }

    try {
        dispatch({ type: SET_SCHEDULE_REQUEST });

        const job = await patchJob(editItem.config, editItem.config.job.id, jobRequest);
        dispatch({ payload: job, type: SET_SCHEDULE_SUCCESS });

        notification.success({
            message: job.active === false ? "Schedule suspended" : "Schedule updated",
        });
    } catch (error) {
        dispatch({ type: SET_SCHEDULE_ERROR });

        notification.error({
            message: `Error updating schedule: ${error instanceof Error ? error.message : "Error"}`,
        });

        throw error;
    }
};

export const submitNewConfig = (config: ConfigRequestBody): Thunk => async dispatch => {
    try {
        dispatch({ payload: config, type: SUBMIT_NEW_CONFIG_REQUEST });

        const response = await postNewConfig(config);
        dispatch({ payload: response, type: SUBMIT_NEW_CONFIG_SUCCESS });

        notification.success({ message: "Successfully created" });
        dispatch(push(routes.configs.url(config.networkId)));
    } catch (error) {
        dispatch({ type: SUBMIT_NEW_CONFIG_ERROR });

        notification.error({
            message: `Error creating config: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const submitUpdatedConfig = (
    activeRevisionId: number,
    config: ConfigRequestBody,
): Thunk => async dispatch => {
    try {
        dispatch({ payload: { activeRevisionId, config }, type: SUBMIT_UPDATED_CONFIG_REQUEST });

        const response = await postUpdatedConfig(activeRevisionId, config);
        dispatch({ payload: response, type: SUBMIT_UPDATED_CONFIG_SUCCESS });

        notification.success({ message: "Successfully updated" });
    } catch (error) {
        dispatch({ type: SUBMIT_UPDATED_CONFIG_ERROR });

        notification.error({
            message: `Error updating config: ${error instanceof Error ? error.message : "Error"}`,
        });
    }
};

export const syncUsersToSpeakap = (
    config: Config,
    dryRunToken: string,
    skipMismatch = false,
): Thunk => async dispatch => {
    try {
        dispatch({ type: SYNC_USERS_REQUEST });

        await syncUsers(config, dryRunToken, skipMismatch);
        dispatch({ type: SYNC_USERS_SUCCESS });
        dispatch(push(routes.syncProtocols.url(config.networkId)));

        notification.success({ message: "Successfully synced users!" });
    } catch (error) {
        dispatch({ type: SYNC_USERS_ERROR });
        const message =
            get(error, ["response", "status"]) === LOCKED
                ? "This config is currently being synced and was therefore locked. Please try again in a few minutes."
                : get(error, "response.data", error instanceof Error ? error.message : "Error");
        notification.error({
            message,
        });
    }
};

export const unsetSchedule = (): Thunk => async (dispatch, getState) => {
    const editItem = selectEditItem(getState());
    if (!editItem?.config.job?.id) {
        throw new Error("Cannot unset schedule without scheduled config");
    }

    try {
        dispatch({ type: UNSET_SCHEDULE_REQUEST });

        await deleteJob(editItem.config, editItem.config.job.id);
        dispatch({ type: UNSET_SCHEDULE_SUCCESS });

        notification.success({ message: "Schedule unset" });
    } catch (error) {
        dispatch({ type: UNSET_SCHEDULE_ERROR });

        notification.error({
            message: `Error unsetting schedule: ${
                error instanceof Error ? error.message : "Error"
            }`,
        });

        throw error;
    }
};

export const updateEditConfig = (updates: Partial<ConfigRequestBody>): UpdateEditConfigAction => ({
    payload: updates,
    type: UPDATE_EDIT_CONFIG,
});
