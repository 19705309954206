import produce from "immer";

import { BaseResource } from "../api";
import { LOADING_STATES } from "../../types";
import {
    RevisionsActions,
    FETCH_REVISIONS_ERROR,
    FETCH_REVISIONS_REQUEST,
    FETCH_REVISIONS_SUCCESS,
} from "./actions";

export interface RevisionsState {
    loadingState: LOADING_STATES;
    items: Array<BaseResource>;
}

export const initialState: RevisionsState = {
    items: [],
    loadingState: LOADING_STATES.INITIAL,
};

const revisionsReducer = (
    state: RevisionsState = initialState,
    action: RevisionsActions,
): RevisionsState =>
    produce(state, (draft: RevisionsState) => {
        switch (action.type) {
            case FETCH_REVISIONS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_REVISIONS_SUCCESS: {
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = action.payload;
                break;
            }
            case FETCH_REVISIONS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default revisionsReducer;
