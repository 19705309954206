import { RoleMapping } from "@speakap/types";
import { keyBy } from "lodash";
import produce from "immer";

import { DELETE_RESOURCE_SUCCESS } from "../delete-resources/actions";
import {
    FETCH_ROLE_MAPPINGS_ERROR,
    FETCH_ROLE_MAPPINGS_REQUEST,
    FETCH_ROLE_MAPPINGS_SUCCESS,
    SUBMIT_NEW_ROLE_MAPPING_REQUEST,
    SUBMIT_NEW_ROLE_MAPPING_SUCCESS,
    SUBMIT_UPDATED_ROLE_MAPPING_REQUEST,
    SUBMIT_UPDATED_ROLE_MAPPING_SUCCESS,
    SUBMIT_ROLE_MAPPING_ERROR,
} from "./actions";
import { LOADING_STATES } from "../../types";
import { UserSyncAction } from "../actions";

export interface RoleMappingsState {
    loadingState: LOADING_STATES;
    results: Array<string>;
    items: {
        [name: string]: RoleMapping;
    };
    edit: {
        loadingState: LOADING_STATES;
    };
    networkId: string;
}

export const initialState: RoleMappingsState = {
    edit: {
        loadingState: LOADING_STATES.INITIAL,
    },
    items: {},
    loadingState: LOADING_STATES.INITIAL,
    networkId: "",
    results: [],
};

const roleMappingsReducer = (
    state: RoleMappingsState = initialState,
    action: UserSyncAction,
): RoleMappingsState =>
    produce(state, (draft: RoleMappingsState) => {
        switch (action.type) {
            case FETCH_ROLE_MAPPINGS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_ROLE_MAPPINGS_SUCCESS: {
                const dictionary = keyBy(action.payload.roleMappings, "name");
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = dictionary;
                draft.networkId = action.payload.networkId;
                draft.results = Object.keys(dictionary).sort();
                break;
            }
            case FETCH_ROLE_MAPPINGS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case SUBMIT_NEW_ROLE_MAPPING_REQUEST:
            case SUBMIT_UPDATED_ROLE_MAPPING_REQUEST:
                draft.edit.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_NEW_ROLE_MAPPING_SUCCESS:
            case SUBMIT_UPDATED_ROLE_MAPPING_SUCCESS: {
                draft.edit.loadingState = LOADING_STATES.SUCCESSFUL;
                const roleMapping = action.payload;
                draft.items[roleMapping.name] = roleMapping;
                if (!draft.results.includes(roleMapping.name)) {
                    draft.results.push(roleMapping.name);
                    draft.results.sort();
                }
                break;
            }
            case SUBMIT_ROLE_MAPPING_ERROR:
                draft.edit.loadingState = LOADING_STATES.ERROR;
                break;
            case DELETE_RESOURCE_SUCCESS: {
                const { resourceType, resourceName } = action.payload;
                if (resourceType === "role-mappings") {
                    draft.results = draft.results.filter(name => name !== resourceName);
                }
                break;
            }
        }
    });

export default roleMappingsReducer;
