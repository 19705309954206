import { Comment } from "@speakap/types";
import produce from "immer";

import {
    CommentsActions,
    FETCH_COMMENTS_ERROR,
    FETCH_COMMENTS_REQUEST,
    FETCH_COMMENTS_SUCCESS,
    SUBMIT_COMMENT_ERROR,
    SUBMIT_COMMENT_REQUEST,
    SUBMIT_COMMENT_SUCCESS,
} from "./actions";
import { LOADING_STATES } from "../../types";

export interface CommentsState {
    loadingState: LOADING_STATES;
    items: Array<Comment>;
    submitLoadingState: LOADING_STATES;
}

export const initialState: CommentsState = {
    items: [],
    loadingState: LOADING_STATES.INITIAL,
    submitLoadingState: LOADING_STATES.INITIAL,
};

const revisionsReducer = (
    state: CommentsState = initialState,
    action: CommentsActions,
): CommentsState =>
    produce(state, (draft: CommentsState) => {
        switch (action.type) {
            case FETCH_COMMENTS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_COMMENTS_SUCCESS: {
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = action.payload;
                break;
            }
            case FETCH_COMMENTS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case SUBMIT_COMMENT_REQUEST:
                draft.submitLoadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_COMMENT_SUCCESS: {
                draft.submitLoadingState = LOADING_STATES.SUCCESSFUL;
                draft.items.push(action.payload);
                break;
            }
            case SUBMIT_COMMENT_ERROR:
                draft.submitLoadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default revisionsReducer;
