import axios from "./request";

export type DeleteResourceType =
    | "configs"
    | "connectors"
    | "column-mappings"
    | "group-mappings"
    | "role-mappings";

export const deleteResourceByName = (
    networkId: string,
    resourceType: DeleteResourceType,
    resourceName: string,
): Promise<void> => axios.delete(`/networks/${networkId}/${resourceType}/name/${resourceName}`);
