import { SpeakapGroup, ScimUser } from "@speakap/types";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../reducer";
import { getOrganizationalOverview } from "../api";

export const FETCH_ORGANIZATIONAL_OVERVIEW_REQUEST = "FETCH_ORGANIZATIONAL_OVERVIEW_REQUEST";
export const FETCH_ORGANIZATIONAL_OVERVIEW_SUCCESS = "FETCH_ORGANIZATIONAL_OVERVIEW_SUCCESS";
export const FETCH_ORGANIZATIONAL_OVERVIEW_ERROR = "FETCH_ORGANIZATIONAL_OVERVIEW_ERROR";

interface FetchOrganizationalOverviewsRequestAction {
    type: typeof FETCH_ORGANIZATIONAL_OVERVIEW_REQUEST;
}

interface FetchOrganizationalOverviewsSuccessAction {
    type: typeof FETCH_ORGANIZATIONAL_OVERVIEW_SUCCESS;
    payload: {
        groups: Array<SpeakapGroup>;
        users: Array<ScimUser>;
    };
}

interface FetchOrganizationalOverviewsErrorAction {
    type: typeof FETCH_ORGANIZATIONAL_OVERVIEW_ERROR;
}

export type OrganizationalOverviewActions =
    | FetchOrganizationalOverviewsSuccessAction
    | FetchOrganizationalOverviewsRequestAction
    | FetchOrganizationalOverviewsErrorAction;

type Thunk<Result> = ThunkAction<Result, ApplicationState, void, OrganizationalOverviewActions>;

export const fetchOrganizationalOverview = (
    networkId: string,
    configName: string,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: FETCH_ORGANIZATIONAL_OVERVIEW_REQUEST });

        const response = await getOrganizationalOverview({ configName, networkId });
        dispatch({ payload: response, type: FETCH_ORGANIZATIONAL_OVERVIEW_SUCCESS });
    } catch (error) {
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_ORGANIZATIONAL_OVERVIEW_ERROR,
        });
    }
};
