import { Config, EnhancedConfig } from "@speakap/types";
import { keyBy } from "lodash";
import produce from "immer";

import {
    CREATE_NEW_CONFIG_START,
    CREATE_NEW_CONFIG_STOP,
    EDIT_CONFIG_START,
    EDIT_CONFIG_STOP,
    FETCH_CONFIGS_REQUEST,
    FETCH_CONFIGS_SUCCESS,
    FETCH_CONFIGS_ERROR,
    SUBMIT_NEW_CONFIG_REQUEST,
    SUBMIT_NEW_CONFIG_SUCCESS,
    SUBMIT_NEW_CONFIG_ERROR,
    SUBMIT_UPDATED_CONFIG_REQUEST,
    SUBMIT_UPDATED_CONFIG_SUCCESS,
    SUBMIT_UPDATED_CONFIG_ERROR,
    SET_SELECTED_CONFIG,
    SYNC_USERS_REQUEST,
    SYNC_USERS_SUCCESS,
    SYNC_USERS_ERROR,
    UPDATE_EDIT_CONFIG,
    SET_SCHEDULE_ERROR,
    SET_SCHEDULE_SUCCESS,
    SET_SCHEDULE_REQUEST,
    UNSET_SCHEDULE_REQUEST,
    UNSET_SCHEDULE_SUCCESS,
    UNSET_SCHEDULE_ERROR,
} from "./actions";
import { DELETE_RESOURCE_SUCCESS } from "../delete-resources/actions";
import { LOADING_STATES } from "../../types";
import { UserSyncAction } from "../actions";

export interface ConfigsState {
    readonly editItem: {
        readonly config: EnhancedConfig;
        readonly loadingState: LOADING_STATES;
        readonly scheduleLoadingState: LOADING_STATES;
    } | null;
    readonly items: {
        readonly [name: string]: EnhancedConfig;
    };
    readonly loadingState: LOADING_STATES;
    readonly newItem: {
        readonly loadingState: LOADING_STATES;
    } | null;
    readonly results: Array<string>;
    readonly selectedConfig?: string;
    readonly syncUsers: {
        readonly loadingState: LOADING_STATES;
    };
}

export const initialState: ConfigsState = {
    editItem: null,
    items: {},
    loadingState: LOADING_STATES.INITIAL,
    newItem: null,
    results: [],
    syncUsers: {
        loadingState: LOADING_STATES.INITIAL,
    },
};

export default function configsReducer(
    state: ConfigsState = initialState,
    action: UserSyncAction,
): ConfigsState {
    switch (action.type) {
        case CREATE_NEW_CONFIG_START:
            return { ...state, newItem: { loadingState: LOADING_STATES.INITIAL } };
        case CREATE_NEW_CONFIG_STOP:
            return { ...state, newItem: null };
        case EDIT_CONFIG_START:
            return {
                ...state,
                editItem: {
                    config: action.payload,
                    loadingState: LOADING_STATES.INITIAL,
                    scheduleLoadingState: LOADING_STATES.INITIAL,
                },
            };
        case EDIT_CONFIG_STOP:
            return { ...state, editItem: null };
        case FETCH_CONFIGS_REQUEST:
            return { ...state, loadingState: LOADING_STATES.FETCHING };
        case FETCH_CONFIGS_SUCCESS:
            return produce(state, draft => {
                const dictionary = keyBy(action.payload, "name");
                draft.items = dictionary;
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.results = Object.keys(dictionary).sort();
            });
        case FETCH_CONFIGS_ERROR:
            return { ...state, loadingState: LOADING_STATES.ERROR };
        case SET_SCHEDULE_REQUEST:
        case UNSET_SCHEDULE_REQUEST:
            return produce(state, draft => {
                if (draft.editItem) {
                    draft.editItem.scheduleLoadingState = LOADING_STATES.FETCHING;
                }
            });
        case SET_SCHEDULE_SUCCESS:
            return produce(state, draft => {
                if (draft.editItem) {
                    draft.editItem.config.job = action.payload;
                    draft.items[draft.editItem.config.name].job = action.payload;
                    draft.editItem.scheduleLoadingState = LOADING_STATES.SUCCESSFUL;
                }
            });
        case SET_SCHEDULE_ERROR:
        case UNSET_SCHEDULE_ERROR:
            return produce(state, draft => {
                if (draft.editItem) {
                    draft.editItem.scheduleLoadingState = LOADING_STATES.ERROR;
                }
            });
        case SUBMIT_NEW_CONFIG_REQUEST:
            return produce(state, draft => {
                if (draft.newItem) {
                    draft.newItem.loadingState = LOADING_STATES.FETCHING;
                }
            });
        case SUBMIT_NEW_CONFIG_SUCCESS:
            return produce(state, draft => {
                const newConfig = action.payload;
                draft.items[newConfig.name] = newConfig;
                draft.results.push(newConfig.name);
                draft.results.sort();
                draft.newItem = null;
            });
        case SUBMIT_NEW_CONFIG_ERROR:
            return produce(state, draft => {
                if (draft.newItem) {
                    draft.newItem.loadingState = LOADING_STATES.ERROR;
                }
            });
        case SUBMIT_UPDATED_CONFIG_REQUEST:
            return produce(state, draft => {
                if (draft.editItem) {
                    draft.editItem.loadingState = LOADING_STATES.FETCHING;
                }
            });
        case SUBMIT_UPDATED_CONFIG_SUCCESS:
            return produce(state, draft => {
                const updatedConfig = action.payload;
                draft.items[updatedConfig.name] = updatedConfig;
                draft.editItem = null;
            });
        case SUBMIT_UPDATED_CONFIG_ERROR:
            return produce(state, draft => {
                if (draft.editItem) {
                    draft.editItem.loadingState = LOADING_STATES.ERROR;
                }
            });
        case SET_SELECTED_CONFIG:
            return produce(state, draft => {
                draft.selectedConfig = action.payload;
            });
        case SYNC_USERS_REQUEST:
            return produce(state, draft => {
                draft.syncUsers.loadingState = LOADING_STATES.FETCHING;
            });
        case SYNC_USERS_SUCCESS:
            return produce(state, draft => {
                draft.syncUsers.loadingState = LOADING_STATES.SUCCESSFUL;
            });
        case SYNC_USERS_ERROR:
            return produce(state, draft => {
                draft.syncUsers.loadingState = LOADING_STATES.ERROR;
            });
        case UNSET_SCHEDULE_SUCCESS:
            return produce(state, draft => {
                if (draft.editItem) {
                    delete draft.editItem.config.job;
                    delete draft.items[draft.editItem.config.name].job;
                    draft.editItem.scheduleLoadingState = LOADING_STATES.SUCCESSFUL;
                }
            });
        case UPDATE_EDIT_CONFIG:
            return produce(state, draft => {
                if (draft.editItem) {
                    draft.editItem.config = { ...draft.editItem.config, ...action.payload };
                }
            });
        case DELETE_RESOURCE_SUCCESS:
            return produce(state, draft => {
                if (draft.editItem) {
                    let replaceField:
                        | keyof Pick<
                              Config,
                              | "connectorName"
                              | "columnMappingName"
                              | "groupMappingName"
                              | "roleMappingName"
                          >
                        | null = null;
                    const { resourceType, resourceName } = action.payload;
                    if (resourceType === "connectors") {
                        replaceField = "connectorName";
                    } else if (resourceType === "column-mappings") {
                        replaceField = "columnMappingName";
                    } else if (resourceType === "group-mappings") {
                        replaceField = "groupMappingName";
                    } else if (resourceType === "role-mappings") {
                        replaceField = "roleMappingName";
                    } else if (resourceType === "configs") {
                        draft.results = draft.results.filter(name => name !== resourceName);
                    }
                    if (replaceField) {
                        draft.editItem.config = {
                            ...draft.editItem.config,
                            [replaceField]: undefined,
                        };
                    }
                }
            });
        default:
            return state;
    }
}
