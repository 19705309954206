import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { ConfigsState } from "./reducer";
import { LOADING_STATES } from "../../types";
import { selectConnectorsItems } from "../connectors/selectors";

const selectConfigsState = (state: ApplicationState): ConfigsState => state.configs;

export const selectConfigItems = createSelector(selectConfigsState, ({ items }) => items);

const selectConfigResults = createSelector(selectConfigsState, ({ results }) => results);

export const selectConfig = createSelector(
    selectConfigsState,
    (state: ApplicationState, configName: string) => configName,
    ({ items }, configName) => Object.values(items).find(config => config.name === configName),
);

export const selectConfigsList = createSelector(
    [selectConfigItems, selectConfigResults],
    (items, results) => results.map(id => items[id]),
);

export const selectConfigsLoadingState = createSelector(
    selectConfigsState,
    ({ loadingState }) => loadingState,
);

export const selectEditItem = createSelector(selectConfigsState, ({ editItem }) => editItem);
export const selectEditItemLoadingState = createSelector(selectEditItem, editItem => {
    if (editItem) {
        return editItem.loadingState;
    }
});
export const selectEditItemConfig = createSelector(selectEditItem, editItem => {
    if (editItem) {
        return editItem.config;
    }
});
export const selectScheduleLoadingState = createSelector(selectEditItem, editItem => {
    if (editItem) {
        return editItem.scheduleLoadingState;
    }
});
export const selectConnectorName = createSelector(selectEditItem, editItem => {
    if (editItem) {
        return editItem.config.connectorName;
    }
});
export const selectColumnMappingName = createSelector(selectEditItem, editItem => {
    if (editItem) {
        return editItem.config.columnMappingName;
    }
});
export const selectGroupMappingName = createSelector(selectEditItem, editItem => {
    if (editItem) {
        return editItem.config.groupMappingName;
    }
});
export const selectRoleMappingName = createSelector(selectEditItem, editItem => {
    if (editItem) {
        return editItem.config.roleMappingName;
    }
});

export const selectIsCreatingConfig = createSelector(
    selectConfigsState,
    ({ newItem }) => !!newItem,
);

export const selectIsSubmittingNewConfig = createSelector(selectConfigsState, ({ newItem }) =>
    newItem ? newItem.loadingState === LOADING_STATES.FETCHING : false,
);

export const selectConfigName = createSelector(
    selectConfigsState,
    ({ selectedConfig }) => selectedConfig,
);

export const selectScheduledStatus = createSelector(
    [selectConfig, selectConnectorsItems],
    (config, connectors) => {
        if (config) {
            if (config.job) {
                return config.job.active ? "yes" : "paused";
            }

            if (config.connectorName) {
                const connector = connectors[config.connectorName];
                if (connector?.type === "sftp_upload") {
                    return connector.syncOnUpload;
                }
            }
        }

        return "no";
    },
);

export const selectSyncUsersLoadingState = createSelector(
    selectConfigsState,
    state => state.syncUsers.loadingState,
);
