import React from "react";
import { Row, Col, Form, Checkbox } from "antd";
import { get } from "lodash";

import { FieldOption, FieldComponentProps } from "./column-mappings-utils";
import styles from "./column-mappings-form.module.css";

export function createRadioButton(
    field: Omit<FieldOption, "key"> & { value: number; key: string | Array<string | number> },
) {
    return function RadioButtonComponent(props: FieldComponentProps) {
        const { columnMapping } = props;
        const { label, selector, key } = field;
        const initialValue = get(columnMapping, selector, false);

        return (
            <Row key={key.toString()} align="top" gutter={{ lg: 12 }}>
                <Col lg={4} className={styles.inputLabel}>
                    {label}
                </Col>
                <Col lg={20}>
                    <Form.Item initialValue={initialValue} name={key} valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                </Col>
            </Row>
        );
    };
}
