import { SpeakapGroup, ScimUser } from "@speakap/types";
import produce from "immer";

import { LOADING_STATES } from "../../types";
import {
    OrganizationalOverviewActions,
    FETCH_ORGANIZATIONAL_OVERVIEW_ERROR,
    FETCH_ORGANIZATIONAL_OVERVIEW_REQUEST,
    FETCH_ORGANIZATIONAL_OVERVIEW_SUCCESS,
} from "./actions";

export interface OrganizationalOverviewState {
    loadingState: LOADING_STATES;
    response: {
        groups: Array<SpeakapGroup>;
        users: Array<ScimUser>;
    };
}

export const initialState = {
    loadingState: LOADING_STATES.INITIAL,
    response: {
        groups: [],
        users: [],
    },
};

const organizationalOverviewReducer = (
    state: OrganizationalOverviewState = initialState,
    action: OrganizationalOverviewActions,
): OrganizationalOverviewState =>
    produce(state, (draft: OrganizationalOverviewState) => {
        switch (action.type) {
            case FETCH_ORGANIZATIONAL_OVERVIEW_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_ORGANIZATIONAL_OVERVIEW_SUCCESS:
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.response = action.payload;
                break;
            case FETCH_ORGANIZATIONAL_OVERVIEW_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                draft.response = {
                    groups: [],
                    users: [],
                };
                break;
        }
    });

export default organizationalOverviewReducer;
