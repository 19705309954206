import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { AuthState } from "./reducer";

const selectAuth = (state: ApplicationState): AuthState => state.auth;

export const selectUser = createSelector(selectAuth, ({ user }) => user);

export const selectUserLoadingState = createSelector(
    selectAuth,
    ({ userLoadingState }) => userLoadingState,
);

export const selectLogoutLoadingState = createSelector(
    selectAuth,
    ({ logoutLoadingState }) => logoutLoadingState,
);

export const selectGoogleLoadingState = createSelector(
    selectAuth,
    ({ googleLoadingState }) => googleLoadingState,
);

export const selectLoginLoadingState = createSelector(
    selectAuth,
    ({ loginLoadingState }) => loginLoadingState,
);
