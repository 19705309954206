import { ScimUser, SpeakapGroup } from "@speakap/types";

import axios from "./request";

interface GetOrganizationalOverviewParams {
    networkId: string;
    configName: string;
}

interface GetOrganizationalOverviewResponse {
    groups: Array<SpeakapGroup>;
    users: Array<ScimUser>;
}

export const getOrganizationalOverview = ({
    networkId,
    configName,
}: GetOrganizationalOverviewParams): Promise<GetOrganizationalOverviewResponse> =>
    axios
        .get(`/networks/${networkId}/configs/${configName}/organizational-overview`)
        .then(({ data }) => data);
