import { SyncProtocol, SyncedUser, ResyncResponse, SyncRequestHistory } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { createResyncRequest, confirmResyncRequest } from "../api";

export const CREATE_RESYNC_REQUEST_REQUEST = "CREATE_RESYNC_REQUEST_REQUEST";
export const CREATE_RESYNC_REQUEST_SUCCESS = "CREATE_RESYNC_REQUEST_SUCCESS";
export const CREATE_RESYNC_REQUEST_ERROR = "CREATE_RESYNC_REQUEST_ERROR";
export const CREATE_RESYNC_REQUEST_RESET = "CREATE_RESYNC_REQUEST_RESET";

export const CONFIRM_RESYNC_REQUEST_REQUEST = "CONFIRM_RESYNC_REQUEST_REQUEST";
export const CONFIRM_RESYNC_REQUEST_SUCCESS = "CONFIRM_RESYNC_REQUEST_SUCCESS";
export const CONFIRM_RESYNC_REQUEST_ERROR = "CONFIRM_RESYNC_REQUEST_ERROR";

interface CreateResyncRequestRequestAction {
    type: typeof CREATE_RESYNC_REQUEST_REQUEST;
    payload: SyncedUser;
}

interface CreateResyncRequestSuccessAction {
    type: typeof CREATE_RESYNC_REQUEST_SUCCESS;
    payload: ResyncResponse;
}

interface CreateResyncRequestErrorAction {
    type: typeof CREATE_RESYNC_REQUEST_ERROR;
}

interface CreateResyncRequestResetAction {
    type: typeof CREATE_RESYNC_REQUEST_RESET;
}

interface ConfirmResyncRequestRequestAction {
    type: typeof CONFIRM_RESYNC_REQUEST_REQUEST;
}

interface ConfirmResyncRequestSuccessAction {
    type: typeof CONFIRM_RESYNC_REQUEST_SUCCESS;
    payload: {
        syncProtocol: SyncProtocol;
        syncRequestHistories: Array<SyncRequestHistory>;
    };
}

interface ConfirmResyncRequestErrorAction {
    type: typeof CONFIRM_RESYNC_REQUEST_ERROR;
}

export type ResyncRequestsActions =
    | CreateResyncRequestSuccessAction
    | CreateResyncRequestRequestAction
    | CreateResyncRequestErrorAction
    | CreateResyncRequestResetAction
    | ConfirmResyncRequestSuccessAction
    | ConfirmResyncRequestRequestAction
    | ConfirmResyncRequestErrorAction;

export const resetRequest = (): CreateResyncRequestResetAction => ({
    type: "CREATE_RESYNC_REQUEST_RESET",
});

type Thunk<Result> = ThunkAction<Result, ApplicationState, void, ResyncRequestsActions>;

export const createRequest = (
    networkId: string,
    syncedUser: SyncedUser,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ payload: syncedUser, type: CREATE_RESYNC_REQUEST_REQUEST });

        const response = await createResyncRequest(networkId, syncedUser.id);
        dispatch({ payload: response, type: CREATE_RESYNC_REQUEST_SUCCESS });
    } catch (error) {
        notification.error({
            message: get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            ),
        });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: CREATE_RESYNC_REQUEST_ERROR,
        });
    }
};

export const confirmRequest = (
    networkId: string,
    resyncToken: string,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: CONFIRM_RESYNC_REQUEST_REQUEST });

        const response = await confirmResyncRequest(networkId, resyncToken);
        dispatch({ payload: response, type: CONFIRM_RESYNC_REQUEST_SUCCESS });
        notification.success({ message: "User was successfully reinvited" });
    } catch (error) {
        notification.error({
            message: get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            ),
        });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: CONFIRM_RESYNC_REQUEST_ERROR,
        });
    }
};
