const USERSYNC_USER_TOKEN = "usersync:token";

const getToken = (): string | null => {
    const url = new URL(location.href);
    const accessToken = url.searchParams.get("access_token");

    if (accessToken) {
        url.searchParams.delete("access_token");
        history.replaceState(null, "", url.toString());
        localStorage.setItem(USERSYNC_USER_TOKEN, accessToken);
    }

    return localStorage.getItem(USERSYNC_USER_TOKEN);
};

const removeToken = (): void => localStorage.removeItem(USERSYNC_USER_TOKEN);

export { getToken, removeToken };
