import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { RoleMappingsState } from "./reducer";

const selectRoleMappings = (state: ApplicationState): RoleMappingsState => state.roleMappings;

export const selectRoleMappingsItems = createSelector(selectRoleMappings, ({ items }) => items);
const selectRoleMappingsResults = createSelector(selectRoleMappings, ({ results }) => results);

export const selectRoleMappingsList = createSelector(
    [selectRoleMappingsItems, selectRoleMappingsResults],
    (items, results) => results.map(id => items[id]),
);

export const selectRoleMappingsLoadingState = createSelector(
    selectRoleMappings,
    ({ loadingState }) => loadingState,
);

const selectEdit = createSelector(selectRoleMappings, ({ edit }) => edit);

export const selectEditLoadingState = createSelector(
    selectEdit,
    ({ loadingState }) => loadingState,
);

export const selectRoleMappingsNetworkId = createSelector(
    selectRoleMappings,
    ({ networkId }) => networkId,
);
