import { UserSyncUser } from "@speakap/types";
import produce from "immer";

import {
    FETCH_SPEAKAP_USERS_ERROR,
    FETCH_SPEAKAP_USERS_REQUEST,
    FETCH_SPEAKAP_USERS_SUCCESS,
    AccessManagementActions,
    SUBMIT_SPEAKAP_USER_SUCCESS,
    UPDATE_SPEAKAP_USER_SUCCESS,
    SET_EDIT_SPEAKAP_USER,
    DELETE_SPEAKAP_USER_SUCCESS,
    DELETE_SPEAKAP_USER_REQUEST,
    DELETE_SPEAKAP_USER_ERROR,
    SUBMIT_SPEAKAP_USER_REQUEST,
    SUBMIT_SPEAKAP_USER_ERROR,
} from "./actions";
import { LOADING_STATES } from "../../types";

export interface AccessManagementState {
    loadingState: LOADING_STATES;
    users: Array<UserSyncUser>;
    editUser: {
        item?: UserSyncUser;
        loadingState: LOADING_STATES;
        mode: "new" | "edit" | "closed";
    };
    deleteUser: {
        loadingState: LOADING_STATES;
    };
}

export const initialState: AccessManagementState = {
    deleteUser: {
        loadingState: LOADING_STATES.INITIAL,
    },
    editUser: {
        loadingState: LOADING_STATES.INITIAL,
        mode: "closed",
    },
    loadingState: LOADING_STATES.INITIAL,
    users: [],
};

const networksReducer = (
    state: AccessManagementState = initialState,
    action: AccessManagementActions,
): AccessManagementState =>
    produce(state, (draft: AccessManagementState) => {
        switch (action.type) {
            case FETCH_SPEAKAP_USERS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_SPEAKAP_USERS_SUCCESS:
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.users = action.payload;
                break;
            case FETCH_SPEAKAP_USERS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case SUBMIT_SPEAKAP_USER_REQUEST:
                draft.editUser.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_SPEAKAP_USER_ERROR:
                draft.editUser.loadingState = LOADING_STATES.ERROR;
                break;
            case SUBMIT_SPEAKAP_USER_SUCCESS:
                draft.users.push(action.payload);
                draft.editUser.mode = "closed";
                draft.editUser.loadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case SET_EDIT_SPEAKAP_USER:
                draft.editUser.item = action.payload.user;
                draft.editUser.mode = action.payload.mode;
                break;
            case UPDATE_SPEAKAP_USER_SUCCESS:
                draft.editUser.mode = "closed";
                draft.users = state.users.map(user =>
                    user.id === action.payload.id ? action.payload : user,
                );
                break;
            case DELETE_SPEAKAP_USER_REQUEST:
                draft.deleteUser.loadingState = LOADING_STATES.FETCHING;
                break;
            case DELETE_SPEAKAP_USER_SUCCESS:
                draft.users = state.users.filter(user => user.id !== action.payload);
                draft.deleteUser.loadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case DELETE_SPEAKAP_USER_ERROR:
                draft.deleteUser.loadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default networksReducer;
