import { ColumnMapping, ImportUser } from "@speakap/types";
import { keyBy } from "lodash";
import produce from "immer";

import { DELETE_RESOURCE_SUCCESS } from "../delete-resources/actions";
import {
    FETCH_COLUMN_MAPPINGS_ERROR,
    FETCH_COLUMN_MAPPINGS_REQUEST,
    FETCH_COLUMN_MAPPINGS_SUCCESS,
    SUBMIT_NEW_COLUMN_MAPPING_REQUEST,
    SUBMIT_NEW_COLUMN_MAPPING_SUCCESS,
    SUBMIT_UPDATED_COLUMN_MAPPING_REQUEST,
    SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS,
    SUBMIT_COLUMN_MAPPING_ERROR,
    APPLY_COLUMN_MAPPINGS_ERROR,
    APPLY_COLUMN_MAPPINGS_REQUEST,
    APPLY_COLUMN_MAPPINGS_SUCCESS,
} from "./actions";
import { LOADING_STATES } from "../../types";
import { UserSyncAction } from "../actions";

export interface ColumnMappingsState {
    readonly loadingState: LOADING_STATES;
    readonly results: ReadonlyArray<string>;
    readonly items: {
        readonly [name: string]: ColumnMapping;
    };
    readonly edit: {
        readonly loadingState: LOADING_STATES;
    };
    readonly applyColumnMapping: {
        readonly configName: string;
        readonly loadingState: LOADING_STATES;
        readonly users: ReadonlyArray<ImportUser>;
    };
    readonly networkId: string;
}

export const initialState: ColumnMappingsState = {
    applyColumnMapping: {
        configName: "",
        loadingState: LOADING_STATES.INITIAL,
        users: [],
    },
    edit: {
        loadingState: LOADING_STATES.INITIAL,
    },
    items: {},
    loadingState: LOADING_STATES.INITIAL,
    networkId: "",
    results: [],
};

const columnMappingsReducer = (
    state: ColumnMappingsState = initialState,
    action: UserSyncAction,
): ColumnMappingsState =>
    produce(state, draft => {
        switch (action.type) {
            case FETCH_COLUMN_MAPPINGS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_COLUMN_MAPPINGS_SUCCESS: {
                const dictionary = keyBy(action.payload.columnMappings, "name");
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = dictionary;
                draft.results = Object.keys(dictionary).sort();
                draft.networkId = action.payload.networkId;
                break;
            }
            case FETCH_COLUMN_MAPPINGS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case APPLY_COLUMN_MAPPINGS_REQUEST:
                draft.applyColumnMapping.configName = action.payload.configName;
                draft.applyColumnMapping.loadingState = LOADING_STATES.FETCHING;
                break;
            case APPLY_COLUMN_MAPPINGS_SUCCESS:
                draft.applyColumnMapping.users = action.payload;
                draft.applyColumnMapping.loadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case APPLY_COLUMN_MAPPINGS_ERROR:
                draft.applyColumnMapping.loadingState = LOADING_STATES.ERROR;
                break;
            case SUBMIT_NEW_COLUMN_MAPPING_REQUEST:
            case SUBMIT_UPDATED_COLUMN_MAPPING_REQUEST:
                draft.edit.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_NEW_COLUMN_MAPPING_SUCCESS:
            case SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS: {
                draft.edit.loadingState = LOADING_STATES.SUCCESSFUL;
                const columnMapping = action.payload;
                draft.items[columnMapping.name] = columnMapping;
                if (!draft.results.includes(columnMapping.name)) {
                    draft.results.push(columnMapping.name);
                    draft.results.sort();
                }
                break;
            }
            case SUBMIT_COLUMN_MAPPING_ERROR:
                draft.edit.loadingState = LOADING_STATES.ERROR;
                break;
            case DELETE_RESOURCE_SUCCESS: {
                const { resourceType, resourceName } = action.payload;
                if (resourceType === "column-mappings") {
                    draft.results = draft.results.filter(name => name !== resourceName);
                }
                break;
            }
        }
    });

export default columnMappingsReducer;
