import { Config, DryRunsResponse, GroupMapping, ColumnMapping } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { getDryRuns } from "../api";

export const DISMISS_RESULTS = "speakap/dry-runs/DISMISS_RESULTS";
export const FETCH_RESULTS_REQUEST = "FETCH_RESULTS_REQUEST";
export const FETCH_RESULTS_SUCCESS = "FETCH_RESULTS_SUCCESS";
export const FETCH_RESULTS_ERROR = "FETCH_RESULTS_ERROR";

interface DismissResultsAction {
    type: typeof DISMISS_RESULTS;
}

interface FetchResultsRequestAction {
    type: typeof FETCH_RESULTS_REQUEST;
    payload: {
        config: Config;
    };
}

interface FetchResultsSuccessAction {
    type: typeof FETCH_RESULTS_SUCCESS;
    payload: DryRunsResponse;
}

interface FetchResultsErrorAction {
    type: typeof FETCH_RESULTS_ERROR;
}

export type DryRunsActions =
    | DismissResultsAction
    | FetchResultsSuccessAction
    | FetchResultsRequestAction
    | FetchResultsErrorAction;

interface FetchResultsParams {
    columnMapping?: ColumnMapping;
    config: Config;
    groupMapping?: GroupMapping;
}

type Thunk = ThunkAction<Promise<void>, ApplicationState, void, DryRunsActions>;

export const dismissResults = (): DismissResultsAction => ({ type: DISMISS_RESULTS });

export const fetchResults = (params: FetchResultsParams): Thunk => async dispatch => {
    try {
        dispatch({ payload: params, type: FETCH_RESULTS_REQUEST });

        const response = await getDryRuns(params);
        dispatch({ payload: response, type: FETCH_RESULTS_SUCCESS });
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        dispatch({ message: errorMessage, type: FETCH_RESULTS_ERROR });
        notification.error({
            message: errorMessage,
        });
    }
};
