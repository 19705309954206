import { Config } from "@speakap/types";
import produce from "immer";

import {
    DeleteResourcesAction,
    DELETE_RESOURCE_ERROR,
    DELETE_RESOURCE_REQUEST,
    DELETE_RESOURCE_SUCCESS,
} from "./actions";
import { LOADING_STATES } from "../../types";

export interface DeleteResourcesState {
    loadingState: LOADING_STATES;
    configs?: Array<Config>;
    networkId?: string;
    resourceName?: string;
}

export const initialState: DeleteResourcesState = {
    loadingState: LOADING_STATES.INITIAL,
};

const networksReducer = (
    state: DeleteResourcesState = initialState,
    action: DeleteResourcesAction,
): DeleteResourcesState =>
    produce(state, draft => {
        switch (action.type) {
            case DELETE_RESOURCE_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                draft.networkId = action.payload.networkId;
                draft.resourceName = action.payload.resourceName;
                break;
            case DELETE_RESOURCE_SUCCESS:
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case DELETE_RESOURCE_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                draft.configs = action.payload;
                break;
        }
    });

export default networksReducer;
