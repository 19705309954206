import { AxiosRequestConfig } from "axios";
import {
    Connector,
    ConnectorRequestBody,
    ConnectorRun,
    ConnectorStats,
    ConnectorType,
    ImportUser,
} from "@speakap/types";

import axios from "./request";

interface GetConnectorsParams {
    networkId: string;
}

interface GetConnectorsResponse {
    connectors: Array<Connector>;
}

interface GetImportUsersResponse {
    importUsers: Array<ImportUser>;
}
export interface ManualUploadPayload {
    file: File;
}

export interface PostRunsParams {
    networkId?: string;
    connector: Connector;
    data?: RunsPayload;
}

export type RunsPayload = ManualUploadPayload;

export const getConnectors = (params: GetConnectorsParams): Promise<GetConnectorsResponse> =>
    axios.get(`/networks/${params.networkId}/connectors/`).then(({ data }) => data);

export const getImportUsers = ({ id, networkId }: Connector): Promise<GetImportUsersResponse> =>
    axios.get(`/networks/${networkId}/connectors/${id}/runs/most-recent/`).then(({ data }) => data);

export const postNewConnector = (connector: ConnectorRequestBody): Promise<Connector> =>
    axios.post(`/networks/${connector.networkId}/connectors/`, connector).then(({ data }) => data);

export const postUpdatedConnector = (
    activeRevisionId: number,
    connector: ConnectorRequestBody,
): Promise<Connector> =>
    axios
        .post(
            `/networks/${connector.networkId}/connectors/?active_revision_id=${activeRevisionId}`,
            connector,
        )
        .then(({ data }) => data);

const extendManualUpload = (
    axiosConfig: AxiosRequestConfig,
    params: PostRunsParams,
): AxiosRequestConfig => {
    const formData = new FormData();
    if (!params.data) {
        throw new Error('Cannot upload file without "file"');
    }
    formData.append("file", params.data.file);

    return {
        ...axiosConfig,
        data: formData,
        headers: {
            ...axiosConfig.headers,
            "Content-Type": "multipart/form-data",
        },
    };
};

const extendAxiosConfig = (
    axiosConfig: AxiosRequestConfig,
    params: PostRunsParams,
): AxiosRequestConfig => {
    const { connector } = params;

    if (connector.type === ConnectorType.ManualUpload) {
        return extendManualUpload(axiosConfig, params);
    }

    return axiosConfig;
};

export const postRuns = (params: PostRunsParams): Promise<ConnectorRun> => {
    const { connector } = params;
    const url = `/networks/${connector.networkId}/connectors/${connector.id}/runs/`;

    const axiosConfig: AxiosRequestConfig = {
        method: "post",
        url,
    };

    const extendedAxiosConfig = extendAxiosConfig(axiosConfig, params);

    return axios(extendedAxiosConfig).then(({ data }) => data);
};

export const getConnectorsByName = (networkId: string, name: string): Promise<Array<Connector>> =>
    axios.get(`/networks/${networkId}/connectors/name/${name}`).then(({ data }) => data);

export const getConnectorStats = (
    networkId: string,
    connectorId: number,
): Promise<ConnectorStats> =>
    axios.get(`/networks/${networkId}/connectors/${connectorId}/stats`).then(({ data }) => data);
