import { GroupMapping, SpeakapGroup, ScimUser } from "@speakap/types";
import { keyBy, sortBy } from "lodash";
import produce from "immer";

import { ConditionOption } from "../../components/expression-builder/types";
import { DELETE_RESOURCE_SUCCESS } from "../delete-resources/actions";
import {
    FETCH_GROUP_MAPPINGS_ERROR,
    FETCH_GROUP_MAPPINGS_REQUEST,
    FETCH_GROUP_MAPPINGS_SUCCESS,
    SUBMIT_NEW_GROUP_MAPPING_REQUEST,
    SUBMIT_NEW_GROUP_MAPPING_SUCCESS,
    SUBMIT_UPDATED_GROUP_MAPPING_REQUEST,
    SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS,
    SUBMIT_GROUP_MAPPING_ERROR,
    FETCH_GROUPS_ERROR,
    FETCH_GROUPS_REQUEST,
    FETCH_GROUPS_SUCCESS,
    FETCH_SUGGESTIONS_REQUEST,
    FETCH_SUGGESTIONS_ERROR,
    SET_SUGGESTIONS,
    UPDATE_SUGGESTIONS_ERROR,
    UPDATE_SUGGESTIONS_REQUEST,
    UPDATE_SUGGESTIONS_SUCCESS,
    PREPARE_FORM_REQUEST,
    PREPARE_FORM_SUCCESS,
    PREPARE_FORM_ERROR,
} from "./actions";
import { LOADING_STATES } from "../../types";
import { UserSyncAction } from "../actions";

export interface SpeakapGroupUI extends SpeakapGroup {
    parent?: SpeakapGroup;
}

export interface GroupMappingsState {
    loadingState: LOADING_STATES;
    results: Array<string>;
    items: {
        [name: string]: GroupMapping;
    };
    edit: {
        loadingState: LOADING_STATES;
    };
    groups: {
        loadingState: LOADING_STATES;
        results: Array<string>;
        items: {
            [name: string]: SpeakapGroupUI;
        };
        networkId: string | undefined;
    };
    builder: {
        loadingState: LOADING_STATES;
        subjects: Array<ConditionOption>;
        objects: {
            [subjectKey: string]: Array<ConditionOption>;
        };
    };
    updateSuggestions: {
        loadingState: LOADING_STATES;
    };
    networkId: string;
    prepareForm: {
        loadingState: LOADING_STATES;
        networkId: string;
        scimUsers: Array<ScimUser>;
    };
}

export const initialState: GroupMappingsState = {
    builder: {
        loadingState: LOADING_STATES.INITIAL,
        objects: {},
        subjects: [],
    },
    edit: {
        loadingState: LOADING_STATES.INITIAL,
    },
    groups: {
        items: {},
        loadingState: LOADING_STATES.INITIAL,
        networkId: undefined,
        results: [],
    },
    items: {},
    loadingState: LOADING_STATES.INITIAL,
    networkId: "",
    prepareForm: {
        loadingState: LOADING_STATES.INITIAL,
        networkId: "",
        scimUsers: [],
    },
    results: [],
    updateSuggestions: {
        loadingState: LOADING_STATES.INITIAL,
    },
};

const groupMappingsReducer = (
    state: GroupMappingsState = initialState,
    action: UserSyncAction,
): GroupMappingsState =>
    produce(state, (draft: GroupMappingsState) => {
        switch (action.type) {
            case FETCH_GROUP_MAPPINGS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_GROUP_MAPPINGS_SUCCESS: {
                const dictionary = keyBy(action.payload.groupMappings, "name");
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = dictionary;
                draft.networkId = action.payload.networkId;
                draft.results = Object.keys(dictionary).sort();
                break;
            }
            case FETCH_GROUP_MAPPINGS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case SUBMIT_NEW_GROUP_MAPPING_REQUEST:
            case SUBMIT_UPDATED_GROUP_MAPPING_REQUEST:
                draft.edit.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_NEW_GROUP_MAPPING_SUCCESS:
            case SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS: {
                draft.edit.loadingState = LOADING_STATES.SUCCESSFUL;
                const groupMapping = action.payload;
                draft.items[groupMapping.name] = groupMapping;
                if (!draft.results.includes(groupMapping.name)) {
                    draft.results.push(groupMapping.name);
                    draft.results.sort();
                }
                break;
            }
            case SUBMIT_GROUP_MAPPING_ERROR:
                draft.edit.loadingState = LOADING_STATES.ERROR;
                break;
            case FETCH_GROUPS_REQUEST:
                draft.groups.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_GROUPS_SUCCESS: {
                const sortedGroups = sortBy(action.payload.groups, "name");
                const dictionary = keyBy<SpeakapGroupUI>(sortedGroups, "id");
                for (const group of action.payload.groups) {
                    const parentId = group.parentId;
                    if (parentId) {
                        const parent = dictionary[parentId];
                        if (parent) {
                            dictionary[group.id].parent = parent;
                        }
                    }
                }
                draft.groups.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.groups.items = dictionary;
                draft.groups.results = sortedGroups.map(group => group.id);
                draft.groups.networkId = action.payload.networkId;
                break;
            }
            case FETCH_GROUPS_ERROR:
                draft.groups.loadingState = LOADING_STATES.ERROR;
                break;
            case FETCH_SUGGESTIONS_REQUEST:
                draft.builder.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_SUGGESTIONS_ERROR:
                draft.builder.loadingState = LOADING_STATES.ERROR;
                draft.builder.subjects = [];
                draft.builder.objects = {};
                break;
            case SET_SUGGESTIONS:
                draft.builder.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.builder.subjects = action.payload.subjects;
                draft.builder.objects = action.payload.objects || {};
                break;
            case UPDATE_SUGGESTIONS_REQUEST:
                draft.updateSuggestions.loadingState = LOADING_STATES.FETCHING;
                break;
            case UPDATE_SUGGESTIONS_SUCCESS:
                draft.updateSuggestions.loadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case UPDATE_SUGGESTIONS_ERROR:
                draft.updateSuggestions.loadingState = LOADING_STATES.ERROR;
                break;
            case PREPARE_FORM_REQUEST:
                draft.prepareForm.loadingState = LOADING_STATES.FETCHING;
                break;
            case PREPARE_FORM_SUCCESS:
                draft.prepareForm.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.prepareForm.networkId = action.payload.networkId;
                draft.prepareForm.scimUsers = action.payload.scimUsers.filter(
                    user => user.active !== false,
                );
                break;
            case PREPARE_FORM_ERROR:
                draft.prepareForm.loadingState = LOADING_STATES.ERROR;
                break;
            case DELETE_RESOURCE_SUCCESS: {
                const { resourceType, resourceName } = action.payload;
                if (resourceType === "group-mappings") {
                    draft.results = draft.results.filter(name => name !== resourceName);
                }
                break;
            }
        }
    });

export default groupMappingsReducer;
