import { SyncProtocol, SyncRequestHistory } from "@speakap/types";

import axios from "./request";

interface GetSyncProtocolsParams {
    networkId: string;
    limit: number;
    offset: number;
}

export const getSyncProtocols = ({
    networkId,
    limit,
    offset,
}: GetSyncProtocolsParams): Promise<Array<SyncProtocol>> =>
    axios
        .get(`/networks/${networkId}/sync-protocols?limit=${limit}&offset=${offset}`)
        .then(({ data }) => data.protocols);

interface GetSyncRequestHistoriesParams {
    networkId: string;
    syncProtocolId: number;
}

export const getSyncRequestHistories = ({
    syncProtocolId,
    networkId,
}: GetSyncRequestHistoriesParams): Promise<Array<SyncRequestHistory>> =>
    axios
        .get(`/networks/${networkId}/sync-protocols/${syncProtocolId}/sync-request-histories`)
        .then(({ data }) => data.syncRequestHistories);
