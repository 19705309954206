import {
    Config,
    DryRunsResponse,
    SpeakapGroup,
    ScimUser,
    MissingGroupLogEntry,
    LogEntry,
    InvalidRoleLogEntry,
    InactiveUserLogEntry,
} from "@speakap/types";
import { LocationChangeAction, LOCATION_CHANGE } from "connected-react-router";
import { keyBy, groupBy, toPairs } from "lodash";
import produce from "immer";

import {
    DryRunsActions,
    DISMISS_RESULTS,
    FETCH_RESULTS_ERROR,
    FETCH_RESULTS_REQUEST,
    FETCH_RESULTS_SUCCESS,
} from "./actions";
import { LOADING_STATES } from "../../types";
import {
    SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS,
    SubmitUpdatedColumnMappingSuccessAction,
} from "../column-mappings/actions";
import {
    SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS,
    SubmitUpdatedGroupMappingSuccessAction,
} from "../group-mappings/actions";

export interface DryRunsState {
    loadingState: LOADING_STATES;
    results: DryRunsResponse & {
        missingGroups: Array<{
            groupId: string;
            users: Array<ScimUser>;
        }>;
        invalidRoles: Array<{
            roleId: string;
            users: Array<ScimUser>;
        }>;
        inactiveUsers: Array<Partial<ScimUser>>;
    };
    groupsMap: Record<string, SpeakapGroup>;
    config?: Config;
    showResults: boolean;
}

export const initialState: DryRunsState = {
    groupsMap: {},
    loadingState: LOADING_STATES.INITIAL,
    results: {
        currentUsers: {
            available: false,
        },
        dryRunToken: "",
        groups: [],
        inactiveUsers: [],
        invalidRoles: [],
        logEntries: [],
        missingGroups: [],
        speakapSyncRequests: [],
        users: [],
    },
    showResults: false,
};

const getMissingGroups = (
    logEntries: Array<LogEntry>,
): Array<{
    groupId: string;
    users: Array<ScimUser>;
}> => {
    const missingGroupLogEntries = logEntries.filter(
        logEntry => logEntry.type === "missing_group",
    ) as Array<MissingGroupLogEntry>;
    const missingGroups = toPairs(groupBy(missingGroupLogEntries, "group.groupId")).map(
        ([groupId, logEntries]) => ({
            groupId,
            users: logEntries.map(logEntry => (logEntry as MissingGroupLogEntry).group.user),
        }),
    );
    return missingGroups;
};

const getInvalidRoles = (
    logEntries: Array<LogEntry>,
): Array<{
    roleId: string;
    users: Array<ScimUser>;
}> => {
    const invalidRoleLogEntries = logEntries.filter(
        logEntry => logEntry.type === "invalid_role",
    ) as Array<InvalidRoleLogEntry>;
    const invalidRoles = toPairs(groupBy(invalidRoleLogEntries, "role.roleId")).map(
        ([roleId, logEntries]) => ({
            roleId,
            users: logEntries.map(logEntry => (logEntry as InvalidRoleLogEntry).role.user),
        }),
    );
    return invalidRoles;
};

const getInactiveUsers = (logEntries: Array<LogEntry>): Array<Partial<ScimUser>> => {
    const inactiveUserLogEntries = logEntries.filter(
        logEntry => logEntry.type === "inactive_user",
    ) as Array<InactiveUserLogEntry>;
    return inactiveUserLogEntries.map(logEntry => (logEntry as InactiveUserLogEntry).user);
};

const networksReducer = (
    state: DryRunsState = initialState,
    action:
        | DryRunsActions
        | SubmitUpdatedGroupMappingSuccessAction
        | SubmitUpdatedColumnMappingSuccessAction
        | LocationChangeAction,
): DryRunsState =>
    produce(state, (draft: DryRunsState) => {
        switch (action.type) {
            case FETCH_RESULTS_REQUEST:
                draft.config = action.payload.config;
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_RESULTS_SUCCESS: {
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                const logEntries = action.payload.logEntries;
                draft.results = {
                    ...action.payload,
                    inactiveUsers: getInactiveUsers(logEntries),
                    invalidRoles: getInvalidRoles(logEntries),
                    missingGroups: getMissingGroups(logEntries),
                };
                draft.groupsMap = keyBy(action.payload.groups, "id");
                draft.showResults = true;
                break;
            }
            case FETCH_RESULTS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case LOCATION_CHANGE:
            case SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS:
            case SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS:
            case DISMISS_RESULTS:
                draft.showResults = false;
                break;
        }
    });

export default networksReducer;
