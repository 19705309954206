import { Connector, ImportUser, ConnectorStats } from "@speakap/types";
import { keyBy } from "lodash";
import produce from "immer";

import { DELETE_RESOURCE_SUCCESS } from "../delete-resources/actions";
import {
    FETCH_CONNECTORS_ERROR,
    FETCH_CONNECTORS_REQUEST,
    FETCH_CONNECTORS_SUCCESS,
    FETCH_IMPORT_USERS_ERROR,
    FETCH_IMPORT_USERS_REQUEST,
    FETCH_IMPORT_USERS_SUCCESS,
    SUBMIT_NEW_CONNECTOR_REQUEST,
    SUBMIT_NEW_CONNECTOR_SUCCESS,
    SUBMIT_UPDATED_CONNECTOR_REQUEST,
    SUBMIT_UPDATED_CONNECTOR_SUCCESS,
    SUBMIT_CONNECTOR_ERROR,
    FETCH_CONNECTOR_STATS_ERROR,
    FETCH_CONNECTOR_STATS_SUCCESS,
    FETCH_CONNECTOR_STATS_REQUEST,
} from "./actions";
import { LOADING_STATES } from "../../types";
import { SUBMIT_RUNS_SUCCESS } from "../runs/actions";
import { UserSyncAction } from "../actions";

export interface ConnectorsState {
    readonly importUsers: {
        readonly connectorId?: number;
        readonly items: Array<ImportUser>;
        readonly loadingState: LOADING_STATES;
    };
    readonly edit: {
        readonly loadingState: LOADING_STATES;
    };
    readonly items: {
        readonly [name: string]: Connector;
    };
    readonly loadingState: LOADING_STATES;
    readonly results: ReadonlyArray<string>;
    readonly networkId: string;
    readonly stats: {
        readonly loadingState: LOADING_STATES;
        readonly value: ConnectorStats;
    };
}

export const initialState: ConnectorsState = {
    edit: {
        loadingState: LOADING_STATES.INITIAL,
    },
    importUsers: {
        items: [],
        loadingState: LOADING_STATES.INITIAL,
    },
    items: {},
    loadingState: LOADING_STATES.INITIAL,
    networkId: "",
    results: [],
    stats: {
        loadingState: LOADING_STATES.INITIAL,
        value: {
            atime: 0,
            filename: "",
            mtime: 0,
            size: 0,
        },
    },
};

const networksReducer = (
    state: ConnectorsState = initialState,
    action: UserSyncAction,
): ConnectorsState =>
    produce(state, draft => {
        switch (action.type) {
            case FETCH_CONNECTORS_REQUEST:
                draft.items = {};
                draft.loadingState = LOADING_STATES.FETCHING;
                draft.results = [];
                break;
            case FETCH_CONNECTORS_SUCCESS: {
                const dictionary = keyBy(action.payload.connectors, "name");
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = dictionary;
                draft.results = Object.keys(dictionary).sort();
                draft.networkId = action.payload.networkId;
                break;
            }
            case FETCH_CONNECTORS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case FETCH_IMPORT_USERS_REQUEST:
                draft.importUsers = {
                    connectorId: action.payload,
                    items: [],
                    loadingState: LOADING_STATES.FETCHING,
                };
                break;
            case SUBMIT_RUNS_SUCCESS:
            case FETCH_IMPORT_USERS_SUCCESS:
                draft.importUsers.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.importUsers.items = action.payload.importUsers;
                draft.importUsers.connectorId = action.payload.connectorId;
                break;
            case FETCH_IMPORT_USERS_ERROR:
                draft.importUsers.loadingState = LOADING_STATES.ERROR;
                draft.importUsers.connectorId = undefined;
                break;
            case SUBMIT_NEW_CONNECTOR_REQUEST:
            case SUBMIT_UPDATED_CONNECTOR_REQUEST:
                draft.edit.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_NEW_CONNECTOR_SUCCESS:
            case SUBMIT_UPDATED_CONNECTOR_SUCCESS: {
                draft.edit.loadingState = LOADING_STATES.SUCCESSFUL;
                const connector = action.payload;
                draft.items[connector.name] = connector;
                if (!draft.results.includes(connector.name)) {
                    draft.results.push(connector.name);
                    draft.results.sort();
                }
                break;
            }
            case SUBMIT_CONNECTOR_ERROR:
                draft.edit.loadingState = LOADING_STATES.ERROR;
                break;
            case DELETE_RESOURCE_SUCCESS: {
                const { resourceType, resourceName } = action.payload;
                if (resourceType === "connectors") {
                    draft.results = draft.results.filter(name => name !== resourceName);
                }
                break;
            }
            case FETCH_CONNECTOR_STATS_REQUEST:
                draft.stats.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_CONNECTOR_STATS_SUCCESS:
                draft.stats.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.stats.value = action.payload;
                break;
            case FETCH_CONNECTOR_STATS_ERROR:
                draft.stats.loadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default networksReducer;
