import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../reducer";
import {
    BaseResource,
    getColumnMappingsRevisions,
    getGroupMappingsRevisions,
    getConnectorsRevisions,
    getConfigsRevisions,
    getRoleMappingsRevisions,
} from "../api";

export const FETCH_REVISIONS_REQUEST = "FETCH_REVISIONS_REQUEST";
export const FETCH_REVISIONS_SUCCESS = "FETCH_REVISIONS_SUCCESS";
export const FETCH_REVISIONS_ERROR = "FETCH_REVISIONS_ERROR";

interface FetchRevisionsRequestAction {
    type: typeof FETCH_REVISIONS_REQUEST;
}

interface FetchRevisionsSuccessAction {
    type: typeof FETCH_REVISIONS_SUCCESS;
    payload: Array<BaseResource>;
}

interface FetchRevisionsErrorAction {
    type: typeof FETCH_REVISIONS_ERROR;
}

export type RevisionsActions =
    | FetchRevisionsRequestAction
    | FetchRevisionsSuccessAction
    | FetchRevisionsErrorAction;

type Thunk<T = void> = ThunkAction<Promise<T>, ApplicationState, void, RevisionsActions>;

const fetchRevisions = (
    getResources: () => Promise<Array<BaseResource>>,
): Thunk<Array<BaseResource> | undefined> => async dispatch => {
    dispatch({
        type: FETCH_REVISIONS_REQUEST,
    });
    try {
        const resources = await getResources();
        dispatch({
            payload: resources,
            type: FETCH_REVISIONS_SUCCESS,
        });
        return resources;
    } catch {
        dispatch({
            type: FETCH_REVISIONS_ERROR,
        });
    }
};

export const fetchColumnMappingsRevisions = (
    networkId: string,
    columnMappingsName: string,
): Thunk<Array<BaseResource> | undefined> => dispatch =>
    dispatch(fetchRevisions(getColumnMappingsRevisions(networkId, columnMappingsName)));

export const fetchGroupMappingsRevisions = (
    networkId: string,
    groupMappingsName: string,
): Thunk<Array<BaseResource> | undefined> => dispatch =>
    dispatch(fetchRevisions(getGroupMappingsRevisions(networkId, groupMappingsName)));

export const fetchRoleMappingsRevisions = (
    networkId: string,
    roleMappingsName: string,
): Thunk<Array<BaseResource> | undefined> => dispatch =>
    dispatch(fetchRevisions(getRoleMappingsRevisions(networkId, roleMappingsName)));

export const fetchConnectorsRevisions = (
    networkId: string,
    connectorsName: string,
): Thunk<Array<BaseResource> | undefined> => dispatch =>
    dispatch(fetchRevisions(getConnectorsRevisions(networkId, connectorsName)));

export const fetchConfigsRevisions = (
    networkId: string,
    configsName: string,
): Thunk<Array<BaseResource> | undefined> => dispatch =>
    dispatch(fetchRevisions(getConfigsRevisions(networkId, configsName)));
