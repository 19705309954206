import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { ColumnMappingsState } from "./reducer";

const selectColumnMappings = (state: ApplicationState): ColumnMappingsState => state.columnMappings;

export const selectColumnMappingsItems = createSelector(selectColumnMappings, ({ items }) => items);

const selectColumnMappingsResults = createSelector(selectColumnMappings, ({ results }) => results);

export const selectColumnMappingsList = createSelector(
    [selectColumnMappingsItems, selectColumnMappingsResults],
    (items, results) => results.map(id => items[id]),
);

export const selectColumnMappingsLoadingState = createSelector(
    selectColumnMappings,
    ({ loadingState }) => loadingState,
);

const selectEdit = createSelector(selectColumnMappings, ({ edit }) => edit);

export const selectEditLoadingState = createSelector(
    selectEdit,
    ({ loadingState }) => loadingState,
);

const selectApplyColumnMapping = createSelector(
    selectColumnMappings,
    ({ applyColumnMapping }) => applyColumnMapping,
);

export const selectApplyColumnMappingUsers = createSelector(
    selectApplyColumnMapping,
    ({ users }) => users,
);

export const selectApplyColumnMappingLoadingState = createSelector(
    selectApplyColumnMapping,
    ({ loadingState }) => loadingState,
);

export const selectApplyColumnMappingConfigName = createSelector(
    selectApplyColumnMapping,
    ({ configName }) => configName,
);

export const selectColumnMappingsNetworkId = createSelector(
    selectColumnMappings,
    ({ networkId }) => networkId,
);
