/* eslint-disable @typescript-eslint/no-unused-vars */
import { SpeakapGroup } from "@speakap/types";

import axios from "./request";

interface GetGroupsParams {
    networkId: string;
}

interface GetGroupsResponse {
    groups: Array<SpeakapGroup>;
}

export const getGroups = (params: GetGroupsParams): Promise<GetGroupsResponse> =>
    axios.get(`/networks/${params.networkId}/groups/`).then(({ data }) => data);
