import { NetworksActions, SET_ACTIVE_NETWORK } from "../networks/actions";

export type NetworksRecentState = Array<{
    networkId: string;
    networkName: string;
    networkSubdomain: string;
    lastActive: string;
}>;

export const initialState = [];

const networksReducer = (
    state: NetworksRecentState = initialState,
    action: NetworksActions,
): NetworksRecentState => {
    switch (action.type) {
        case SET_ACTIVE_NETWORK: {
            const { networkId, networkName, networkSubdomain } = action.payload;
            return [
                {
                    lastActive: new Date().toISOString(),
                    networkId,
                    networkName,
                    networkSubdomain,
                },
                ...state.filter(item => item.networkId !== action.payload.networkId),
            ];
        }
        default:
            return state;
    }
};

export default networksReducer;
