import {
    SyncProtocol,
    ResyncResponse,
    ImportResyncRequest,
    SyncRequestHistory,
} from "@speakap/types";

import axios from "./request";

export const createResyncRequest = async (
    networkId: string,
    syncedUserId: number,
): Promise<ResyncResponse> => {
    const request: ImportResyncRequest = {
        syncedUserId,
        type: "import",
    };

    const { data } = await axios.post(`/networks/${networkId}/resync-requests`, request);
    return data;
};

export const confirmResyncRequest = (
    networkId: string,
    resyncToken: string,
): Promise<{ syncProtocol: SyncProtocol; syncRequestHistories: Array<SyncRequestHistory> }> =>
    axios.post(`/networks/${networkId}/resync-confirms`, { resyncToken }).then(({ data }) => data);
