import React from "react";
import { Row, Col } from "antd";

import { FieldOption, FieldComponentProps } from "./column-mappings-utils";
import { createInputField } from "./column-mappings-form-input";
import styles from "./column-mappings-form.module.css";

export function createObjectField(fields: Array<FieldOption>) {
    return function(field: FieldOption) {
        return function ObjectFieldComponent(props: FieldComponentProps) {
            const { removeButton, ...restProps } = props;
            const { label, selector, key } = field;

            return (
                <Row align="top" gutter={{ lg: 12 }}>
                    <Col lg={2} className={styles.arrayLabel}>
                        {label}
                    </Col>
                    <Col lg={22}>
                        <Row align="middle" justify="end">
                            <Col>{removeButton}</Col>
                        </Row>
                        <div className={styles.arrayWrapper}>
                            {fields.map(
                                ({ key: fieldKey, selector: fieldSelector, ...restField }) =>
                                    createInputField({
                                        ...restField,
                                        key: `${key}.${fieldKey}`,
                                        selector: [...selector, ...fieldSelector],
                                    })(restProps),
                            )}
                        </div>
                    </Col>
                </Row>
            );
        };
    };
}
