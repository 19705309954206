import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import loadable from "@loadable/component";

import Auth from "./components/auth";
import history from "./modules/history";
import { store, persistor } from "./modules/store";

const LoadableRouter = loadable(() => import("./components/router"));

ReactDOM.render(
    <ConfigProvider
        theme={{
            token: {
                colorLink: "#fa4b34",
                colorPrimary: "#fa4b34",
                colorText: "#63626f",
                colorTextHeading: "#2d2c33",
                fontFamily: "'Lato','Helvetica Neue',sans-serif",
            },
        }}
    >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Auth>
                        <LoadableRouter />
                    </Auth>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    </ConfigProvider>,
    document.querySelector("#root"),
);
