import { Comment, CommentRequestBody } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { getComments, postComment } from "../api";

export const FETCH_COMMENTS_REQUEST = "FETCH_COMMENTS_REQUEST";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_ERROR = "FETCH_COMMENTS_ERROR";

export const SUBMIT_COMMENT_REQUEST = "SUBMIT_COMMENT_REQUEST";
export const SUBMIT_COMMENT_SUCCESS = "SUBMIT_COMMENT_SUCCESS";
export const SUBMIT_COMMENT_ERROR = "SUBMIT_COMMENT_ERROR";

interface FetchCommentsRequestAction {
    type: typeof FETCH_COMMENTS_REQUEST;
}

interface FetchCommentsSuccessAction {
    type: typeof FETCH_COMMENTS_SUCCESS;
    payload: Array<Comment>;
}

interface FetchCommentsErrorAction {
    type: typeof FETCH_COMMENTS_ERROR;
}

interface SubmitCommentRequestAction {
    type: typeof SUBMIT_COMMENT_REQUEST;
}

interface SubmitCommentSuccessAction {
    type: typeof SUBMIT_COMMENT_SUCCESS;
    payload: Comment;
}

interface SubmitCommentErrorAction {
    type: typeof SUBMIT_COMMENT_ERROR;
}

export type CommentsActions =
    | FetchCommentsRequestAction
    | FetchCommentsSuccessAction
    | FetchCommentsErrorAction
    | SubmitCommentRequestAction
    | SubmitCommentSuccessAction
    | SubmitCommentErrorAction;

type Thunk<T = void> = ThunkAction<Promise<T>, ApplicationState, void, CommentsActions>;

export const fetchComments = (
    networkId: string,
): Thunk<Array<Comment> | undefined> => async dispatch => {
    dispatch({
        type: FETCH_COMMENTS_REQUEST,
    });
    try {
        const comments = await getComments(networkId);
        dispatch({
            payload: comments,
            type: FETCH_COMMENTS_SUCCESS,
        });
        return comments;
    } catch {
        dispatch({
            type: FETCH_COMMENTS_ERROR,
        });
    }
};

export const createComment = (
    comment: CommentRequestBody,
): Thunk<Comment | undefined> => async dispatch => {
    dispatch({
        type: SUBMIT_COMMENT_REQUEST,
    });
    try {
        const newComment = await postComment(comment);
        dispatch({
            payload: newComment,
            type: SUBMIT_COMMENT_SUCCESS,
        });
        return newComment;
    } catch {
        notification.error({
            message: "Could not send comment",
        });
        dispatch({
            type: SUBMIT_COMMENT_ERROR,
        });
    }
};
