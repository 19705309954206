import { CallHistoryMethodAction } from "connected-react-router";
import {
    Connector,
    ConnectorRequestBody,
    ConnectorStats,
    ConnectorType,
    ImportUser,
} from "@speakap/types";
import { NOT_FOUND } from "http-status-codes";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import {
    getConnectors,
    getConnectorStats,
    getImportUsers,
    postNewConnector,
    postUpdatedConnector,
} from "../api";
import {
    selectConnectorsNetworkId,
    selectImportUsersConnectorId,
    selectImportUsersItems,
} from "./selectors";
import { submitRuns, SubmitRunsSuccessAction } from "../runs/actions";

export const FETCH_CONNECTORS_REQUEST = "user-sync/connectors/FETCH_CONNECTORS_REQUEST";
export const FETCH_CONNECTORS_SUCCESS = "user-sync/connectors/FETCH_CONNECTORS_SUCCESS";
export const FETCH_CONNECTORS_ERROR = "user-sync/connectors/FETCH_CONNECTORS_ERROR";

export const FETCH_IMPORT_USERS_REQUEST = "user-sync/connectors/FETCH_IMPORT_USERS_REQUEST";
export const FETCH_IMPORT_USERS_SUCCESS = "user-sync/connectors/FETCH_IMPORT_USERS_SUCCESS";
export const FETCH_IMPORT_USERS_ERROR = "user-sync/connectors/FETCH_IMPORT_USERS_ERROR";

export const SUBMIT_NEW_CONNECTOR_REQUEST = "user-sync/connectors/SUBMIT_NEW_CONNECTOR_REQUEST";
export const SUBMIT_NEW_CONNECTOR_SUCCESS = "user-sync/connectors/SUBMIT_NEW_CONNECTOR_SUCCESS";
export const SUBMIT_UPDATED_CONNECTOR_REQUEST =
    "user-sync/connectors/SUBMIT_UPDATED_CONNECTOR_REQUEST";
export const SUBMIT_UPDATED_CONNECTOR_SUCCESS =
    "user-sync/connectors/SUBMIT_UPDATED_CONNECTOR_SUCCESS";
export const SUBMIT_CONNECTOR_ERROR = "user-sync/connectors/SUBMIT_CONNECTOR_ERROR";

export const FETCH_CONNECTOR_STATS_REQUEST = "user-sync/connectors/FETCH_CONNECTOR_STATS_REQUEST";
export const FETCH_CONNECTOR_STATS_SUCCESS = "user-sync/connectors/FETCH_CONNECTOR_STATS_SUCCESS";
export const FETCH_CONNECTOR_STATS_ERROR = "user-sync/connectors/FETCH_CONNECTOR_STATS_ERROR";

interface FetchConnectorsRequestAction {
    type: typeof FETCH_CONNECTORS_REQUEST;
    payload: string;
}

interface FetchConnectorsSuccessAction {
    type: typeof FETCH_CONNECTORS_SUCCESS;
    payload: {
        connectors: Array<Connector>;
        networkId: string;
    };
}

interface FetchConnectorsErrorAction {
    type: typeof FETCH_CONNECTORS_ERROR;
}

interface FetchImportUsersRequestAction {
    type: typeof FETCH_IMPORT_USERS_REQUEST;
    payload: number;
}

interface FetchImportUsersSuccessAction {
    type: typeof FETCH_IMPORT_USERS_SUCCESS;
    payload: {
        connectorId: number;
        importUsers: Array<ImportUser>;
    };
}

interface FetchImportUsersErrorAction {
    type: typeof FETCH_IMPORT_USERS_ERROR;
}

interface SubmitNewConnectorRequestAction {
    type: typeof SUBMIT_NEW_CONNECTOR_REQUEST;
    payload: ConnectorRequestBody;
}

interface SubmitNewConnectorSuccessAction {
    type: typeof SUBMIT_NEW_CONNECTOR_SUCCESS;
    payload: Connector;
}

interface SubmitUpdatedConnectorRequestAction {
    type: typeof SUBMIT_UPDATED_CONNECTOR_REQUEST;
    payload: { activeRevisionId: number; connector: ConnectorRequestBody };
}

interface SubmitUpdatedConnectorSuccessAction {
    type: typeof SUBMIT_UPDATED_CONNECTOR_SUCCESS;
    payload: Connector;
}

interface SubmitConnectorErrorAction {
    type: typeof SUBMIT_CONNECTOR_ERROR;
}

interface FetchConnectorStatsRequestAction {
    type: typeof FETCH_CONNECTOR_STATS_REQUEST;
}

interface FetchConnectorStatsSuccessAction {
    type: typeof FETCH_CONNECTOR_STATS_SUCCESS;
    payload: ConnectorStats;
}

interface FetchConnectorStatsErrorAction {
    type: typeof FETCH_CONNECTOR_STATS_ERROR;
}

export type ConnectorsAction =
    | FetchConnectorsSuccessAction
    | FetchConnectorsRequestAction
    | FetchConnectorsErrorAction
    | FetchImportUsersRequestAction
    | FetchImportUsersSuccessAction
    | FetchImportUsersErrorAction
    | SubmitNewConnectorRequestAction
    | SubmitNewConnectorSuccessAction
    | SubmitUpdatedConnectorRequestAction
    | SubmitUpdatedConnectorSuccessAction
    | SubmitConnectorErrorAction
    | SubmitRunsSuccessAction
    | FetchConnectorStatsRequestAction
    | FetchConnectorStatsSuccessAction
    | FetchConnectorStatsErrorAction;

type Thunk<T> = ThunkAction<
    Promise<T>,
    ApplicationState,
    void,
    CallHistoryMethodAction | ConnectorsAction
>;

export const fetchConnectors = (networkId: string): Thunk<void> => async (dispatch, getState) => {
    const currentNetworkId = selectConnectorsNetworkId(getState());
    if (networkId !== currentNetworkId) {
        try {
            dispatch({ payload: networkId, type: FETCH_CONNECTORS_REQUEST });

            const response = await getConnectors({ networkId });
            dispatch({
                payload: { connectors: response.connectors, networkId },
                type: FETCH_CONNECTORS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                message: error instanceof Error ? error.message : "Error",
                type: FETCH_CONNECTORS_ERROR,
            });
        }
    }
};

export const fetchImportUsers = (
    connector: Connector,
): Thunk<[null | string, null | Array<ImportUser>]> => async (dispatch, getState) => {
    const state = getState();
    const currentConnectorId = selectImportUsersConnectorId(state);
    const connectorId = connector.id;
    if (!currentConnectorId || currentConnectorId !== connectorId) {
        try {
            dispatch({ payload: connectorId, type: FETCH_IMPORT_USERS_REQUEST });

            const { importUsers } = await getImportUsers(connector);
            dispatch({
                payload: { connectorId, importUsers },
                type: FETCH_IMPORT_USERS_SUCCESS,
            });
            return [null, importUsers];
        } catch (error) {
            let errorMessage =
                get(error, ["response", "data", "message"]) ||
                get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
            if (
                get(error, ["response", "status"]) === NOT_FOUND &&
                connector.type !== ConnectorType.ManualUpload
            ) {
                const [runsErrorMessage, importUsers] = await dispatch(submitRuns({ connector }));
                if (importUsers) {
                    return [null, importUsers];
                }
                errorMessage = runsErrorMessage;
            }
            dispatch({ message: errorMessage, type: FETCH_IMPORT_USERS_ERROR });
            return [errorMessage, null];
        }
    } else if (currentConnectorId === connectorId) {
        return [null, selectImportUsersItems(state)];
    } else {
        return ["Cannot fetch export file", null];
    }
};

export const submitNewConnector = (
    connector: ConnectorRequestBody,
): Thunk<Connector | undefined> => async dispatch => {
    try {
        dispatch({ payload: connector, type: SUBMIT_NEW_CONNECTOR_REQUEST });

        const response = await postNewConnector(connector);
        dispatch({ payload: response, type: SUBMIT_NEW_CONNECTOR_SUCCESS });
        return response;
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        notification.error({ message: `Error creating connector: ${errorMessage}` });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: SUBMIT_CONNECTOR_ERROR,
        });
    }
};

export const submitUpdatedConnector = (
    activeRevisionId: number,
    connector: ConnectorRequestBody,
): Thunk<[string | null, Connector | null]> => async dispatch => {
    try {
        dispatch({
            payload: { activeRevisionId, connector },
            type: SUBMIT_UPDATED_CONNECTOR_REQUEST,
        });

        const response = await postUpdatedConnector(activeRevisionId, connector);
        dispatch({ payload: response, type: SUBMIT_UPDATED_CONNECTOR_SUCCESS });
        return [null, response];
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        dispatch({ message: errorMessage, type: SUBMIT_CONNECTOR_ERROR });
        return [errorMessage, null];
    }
};

export const fetchConnectorStats = (
    connector: Connector,
): Thunk<[string | null, ConnectorStats | null]> => async dispatch => {
    try {
        dispatch({
            type: FETCH_CONNECTOR_STATS_REQUEST,
        });

        const response = await getConnectorStats(connector.networkId, connector.id);
        dispatch({ payload: response, type: FETCH_CONNECTOR_STATS_SUCCESS });
        return [null, response];
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        dispatch({ message: errorMessage, type: FETCH_CONNECTOR_STATS_ERROR });
        return [errorMessage, null];
    }
};
