import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { ExpressionHelpersState } from "./reducer";

const selectExpressionHelpers = (state: ApplicationState): ExpressionHelpersState =>
    state.expressionHelpers;

export const selectExpressionHelpersLoadingState = createSelector(
    selectExpressionHelpers,
    ({ loadingState }) => loadingState,
);
export const selectExpressionHelpersItems = createSelector(
    selectExpressionHelpers,
    ({ items }) => items,
);
