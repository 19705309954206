import { ColumnMapping, ColumnMappingRequestBody } from "@speakap/types";

import axios from "./request";

interface GetColumnMappingsParams {
    networkId: string;
    active?: boolean;
    name?: string;
}

interface GetColumnMappingsResponse {
    mappings: Array<ColumnMapping>;
}

export const getColumnMappings = ({
    networkId,
    ...restParams
}: GetColumnMappingsParams): Promise<GetColumnMappingsResponse> =>
    axios
        .get(`/networks/${networkId}/column-mappings/`, { params: restParams })
        .then(({ data }) => data);

export const postNewColumnMapping = (
    columnMapping: ColumnMappingRequestBody,
): Promise<ColumnMapping> =>
    axios
        .post(`/networks/${columnMapping.networkId}/column-mappings/`, columnMapping)
        .then(({ data }) => data);

export const postUpdatedColumnMapping = (
    activeRevisionId: number,
    columnMapping: ColumnMappingRequestBody,
): Promise<ColumnMapping> =>
    axios
        .post(
            `/networks/${columnMapping.networkId}/column-mappings/?active_revision_id=${activeRevisionId}`,
            columnMapping,
        )
        .then(({ data }) => data);
