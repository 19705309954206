import { CommentRequestBody, Comment } from "@speakap/types";

import axios from "./request";

export const getComments = (networkId: string): Promise<Array<Comment>> =>
    axios.get(`/networks/${networkId}/network-comments`).then(({ data }) => data.comments);

export const postComment = (comment: CommentRequestBody): Promise<Comment> =>
    axios
        .post(`/networks/${comment.networkId}/network-comments/`, comment)
        .then(({ data }) => data);
