import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { SyncedUsersState } from "./reducer";

const selectSyncedUsersState = (state: ApplicationState): SyncedUsersState => state.syncedUsers;

export const selectSyncedUsersLoadingState = createSelector(
    selectSyncedUsersState,
    ({ loadingState }) => loadingState,
);
export const selectSyncedUsers = createSelector(
    selectSyncedUsersState,
    ({ syncedUsers }) => syncedUsers,
);
export const selectSyncedUsersNetworkId = createSelector(
    selectSyncedUsersState,
    ({ networkId }) => networkId,
);

export const selectIsLoadingMore = createSelector(
    selectSyncedUsersState,
    ({ isLoadingMore }) => isLoadingMore,
);
