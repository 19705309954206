import { GoogleOutlined, LoginOutlined } from "@ant-design/icons";
import React from "react";
import { Row, Col, Form, Input, Button, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { LOADING_STATES } from "../../types";
import Logo from "../logo";
import { redirectToGoogle, loginUser } from "../../modules/auth/actions";
import { selectGoogleLoadingState, selectLoginLoadingState } from "../../modules/auth/selectors";

const Login = (): JSX.Element => {
    const [form] = Form.useForm();
    const { validateFields } = form;
    const dispatch = useDispatch();
    const googleLoadingState = useSelector(selectGoogleLoadingState);
    const loginLoadingState = useSelector(selectLoginLoadingState);

    const onSubmit = (): void => {
        validateFields().then(values => {
            dispatch(loginUser(values.username, values.password));
        });
    };

    return (
        <Row style={{ marginTop: 60 }}>
            <Col offset={9} span={6}>
                <Row gutter={[24, 24]}>
                    <Col
                        span={24}
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Logo size="large" />
                    </Col>
                    <Col span={24}>
                        <Form form={form} name="usersync" onFinish={onSubmit} layout="vertical">
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        required
                                        label="Username"
                                        name="username"
                                        rules={[
                                            {
                                                message: "Please enter a username",
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        required
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                message: "Please enter a password",
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input type="password" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        icon={<LoginOutlined />}
                                        loading={loginLoadingState === LOADING_STATES.FETCHING}
                                    >
                                        Login
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Divider>or</Divider>
                    </Col>
                    <Col span={24}>
                        <Button
                            icon={<GoogleOutlined />}
                            block
                            style={{ backgroundColor: "#4285F4", color: "#fff" }}
                            size="large"
                            loading={googleLoadingState === LOADING_STATES.FETCHING}
                            onClick={() => dispatch(redirectToGoogle())}
                        >
                            Login with Google
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Login;
