import { SyncedUser, SyncProtocol, ResyncResponse, SyncRequestHistory } from "@speakap/types";
import produce from "immer";

import { LOADING_STATES } from "../../types";
import {
    ResyncRequestsActions,
    CREATE_RESYNC_REQUEST_ERROR,
    CREATE_RESYNC_REQUEST_SUCCESS,
    CREATE_RESYNC_REQUEST_REQUEST,
    CREATE_RESYNC_REQUEST_RESET,
    CONFIRM_RESYNC_REQUEST_ERROR,
    CONFIRM_RESYNC_REQUEST_SUCCESS,
    CONFIRM_RESYNC_REQUEST_REQUEST,
} from "./actions";

export interface ResyncRequestsState {
    create: {
        loadingState: LOADING_STATES;
        syncedUser?: SyncedUser;
        resyncResponse?: ResyncResponse;
    };
    confirm: {
        loadingState: LOADING_STATES;
        syncProtocol?: SyncProtocol;
        syncRequestHistories?: Array<SyncRequestHistory>;
    };
}

export const initialState: ResyncRequestsState = {
    confirm: {
        loadingState: LOADING_STATES.INITIAL,
    },
    create: {
        loadingState: LOADING_STATES.INITIAL,
    },
};

const resyncRequestsReducer = (
    state: ResyncRequestsState = initialState,
    action: ResyncRequestsActions,
): ResyncRequestsState =>
    produce(state, (draft: ResyncRequestsState) => {
        switch (action.type) {
            case CREATE_RESYNC_REQUEST_REQUEST:
                draft.create.loadingState = LOADING_STATES.FETCHING;
                draft.create.syncedUser = action.payload;
                draft.create.resyncResponse = undefined;
                break;
            case CREATE_RESYNC_REQUEST_SUCCESS:
                draft.create.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.create.resyncResponse = action.payload;
                break;
            case CREATE_RESYNC_REQUEST_ERROR:
                draft.create.loadingState = LOADING_STATES.ERROR;
                break;
            case CREATE_RESYNC_REQUEST_RESET:
                draft.create.loadingState = LOADING_STATES.INITIAL;
                draft.create.resyncResponse = undefined;
                draft.create.syncedUser = undefined;
                draft.confirm.loadingState = LOADING_STATES.INITIAL;
                draft.confirm.syncProtocol = undefined;
                break;
            case CONFIRM_RESYNC_REQUEST_REQUEST:
                draft.confirm.loadingState = LOADING_STATES.FETCHING;
                break;
            case CONFIRM_RESYNC_REQUEST_SUCCESS:
                draft.confirm.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.confirm.syncProtocol = action.payload.syncProtocol;
                draft.confirm.syncRequestHistories = action.payload.syncRequestHistories;
                break;
            case CONFIRM_RESYNC_REQUEST_ERROR:
                draft.confirm.loadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default resyncRequestsReducer;
