import { SyncedUser } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { getSyncedUsers } from "../api";
import { selectSyncedUsersNetworkId } from "./selectors";

export const FETCH_SYNCED_USERS_REQUEST = "FETCH_SYNCED_USERS_REQUEST";
export const FETCH_SYNCED_USERS_SUCCESS = "FETCH_SYNCED_USERS_SUCCESS";
export const FETCH_SYNCED_USERS_ERROR = "FETCH_SYNCED_USERS_ERROR";
export const FETCH_SYNCED_USERS_BATCH = "FETCH_SYNCED_USERS_BATCH";

interface FetchSyncedUsersRequestAction {
    type: typeof FETCH_SYNCED_USERS_REQUEST;
    payload: string;
}

interface FetchSyncedUsersSuccessAction {
    type: typeof FETCH_SYNCED_USERS_SUCCESS;
}

interface FetchSyncedUsersErrorAction {
    type: typeof FETCH_SYNCED_USERS_ERROR;
    payload: string;
}

interface FetchSyncedUsersBatchAction {
    type: typeof FETCH_SYNCED_USERS_BATCH;
    payload: Array<SyncedUser>;
}

export type SyncedUsersActions =
    | FetchSyncedUsersSuccessAction
    | FetchSyncedUsersRequestAction
    | FetchSyncedUsersErrorAction
    | FetchSyncedUsersBatchAction;

type Thunk = ThunkAction<Promise<void>, ApplicationState, void, SyncedUsersActions>;

export const fetchSyncedUsers = (networkId: string): Thunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const currentNetworkId = selectSyncedUsersNetworkId(state);
        let offset = 0;
        const limit = 100;
        let hasMoreSyncedUsers = true;

        if (currentNetworkId !== networkId) {
            dispatch({ payload: networkId, type: FETCH_SYNCED_USERS_REQUEST });

            while (hasMoreSyncedUsers) {
                const newSyncedUsers = await getSyncedUsers(networkId, limit, offset);

                if (newSyncedUsers.length === 0) {
                    hasMoreSyncedUsers = false;
                } else {
                    dispatch({
                        payload: newSyncedUsers,
                        type: FETCH_SYNCED_USERS_BATCH,
                    });
                    offset += limit;
                }
            }

            dispatch({ type: FETCH_SYNCED_USERS_SUCCESS });
        }
    } catch (error) {
        const message = get(
            error,
            "response.data",
            error instanceof Error ? error.message : "Error",
        );
        dispatch({
            payload: error instanceof Error ? error.message : "Error",
            type: FETCH_SYNCED_USERS_ERROR,
        });
        notification.error({
            message,
        });
    }
};
