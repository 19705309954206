import { RoleMappingRequestBody, RoleMapping } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { goBack, CallHistoryMethodAction } from "connected-react-router";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { getRoleMappings, postNewRoleMapping, postUpdatedRoleMapping } from "../api";
import { selectRoleMappingsNetworkId } from "./selectors";

export const FETCH_ROLE_MAPPINGS_REQUEST = "user-sync/role-mappings/FETCH_ROLE_MAPPINGS_REQUEST";
export const FETCH_ROLE_MAPPINGS_SUCCESS = "user-sync/role-mappings/FETCH_ROLE_MAPPINGS_SUCCESS";
export const FETCH_ROLE_MAPPINGS_ERROR = "user-sync/role-mappings/FETCH_ROLE_MAPPINGS_ERROR";

export const SUBMIT_NEW_ROLE_MAPPING_REQUEST =
    "user-sync/role-mappings/SUBMIT_NEW_ROLE_MAPPING_REQUEST";
export const SUBMIT_NEW_ROLE_MAPPING_SUCCESS =
    "user-sync/role-mappings/SUBMIT_NEW_ROLE_MAPPING_SUCCESS";
export const SUBMIT_UPDATED_ROLE_MAPPING_REQUEST =
    "user-sync/role-mappings/SUBMIT_UPDATED_ROLE_MAPPING_REQUEST";
export const SUBMIT_UPDATED_ROLE_MAPPING_SUCCESS =
    "user-sync/role-mappings/SUBMIT_UPDATED_ROLE_MAPPING_SUCCESS";
export const SUBMIT_ROLE_MAPPING_ERROR = "user-sync/role-mappings/SUBMIT_ROLE_MAPPING_ERROR";

interface FetchRoleMappingsRequestAction {
    type: typeof FETCH_ROLE_MAPPINGS_REQUEST;
    payload: string;
}

interface FetchRoleMappingsSuccessAction {
    type: typeof FETCH_ROLE_MAPPINGS_SUCCESS;
    payload: {
        roleMappings: Array<RoleMapping>;
        networkId: string;
    };
}

interface FetchRoleMappingsErrorAction {
    type: typeof FETCH_ROLE_MAPPINGS_ERROR;
}

interface SubmitNewRoleMappingRequestAction {
    type: typeof SUBMIT_NEW_ROLE_MAPPING_REQUEST;
    payload: RoleMappingRequestBody;
}

interface SubmitNewRoleMappingSuccessAction {
    type: typeof SUBMIT_NEW_ROLE_MAPPING_SUCCESS;
    payload: RoleMapping;
}

interface SubmitUpdatedRoleMappingRequestAction {
    type: typeof SUBMIT_UPDATED_ROLE_MAPPING_REQUEST;
    payload: { activeRevisionId: number; roleMapping: RoleMapping };
}

export interface SubmitUpdatedRoleMappingSuccessAction {
    type: typeof SUBMIT_UPDATED_ROLE_MAPPING_SUCCESS;
    payload: RoleMapping;
}

interface SubmitRoleMappingErrorAction {
    type: typeof SUBMIT_ROLE_MAPPING_ERROR;
}

export type RoleMappingsAction =
    | FetchRoleMappingsSuccessAction
    | FetchRoleMappingsRequestAction
    | FetchRoleMappingsErrorAction
    | SubmitNewRoleMappingRequestAction
    | SubmitNewRoleMappingSuccessAction
    | SubmitUpdatedRoleMappingRequestAction
    | SubmitUpdatedRoleMappingSuccessAction
    | SubmitRoleMappingErrorAction;

type Thunk<Result = void> = ThunkAction<
    Promise<Result>,
    ApplicationState,
    void,
    CallHistoryMethodAction | RoleMappingsAction
>;

export const fetchRoleMappings = (networkId: string): Thunk => async (dispatch, getState) => {
    const currentNetworkId = selectRoleMappingsNetworkId(getState());
    if (networkId !== currentNetworkId) {
        try {
            dispatch({ payload: networkId, type: FETCH_ROLE_MAPPINGS_REQUEST });

            const response = await getRoleMappings({ networkId });
            dispatch({
                payload: { networkId, roleMappings: response.mappings },
                type: FETCH_ROLE_MAPPINGS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                message: error instanceof Error ? error.message : "Error",
                type: FETCH_ROLE_MAPPINGS_ERROR,
            });
        }
    }
};

export const submitNewRoleMapping = (
    roleMapping: RoleMappingRequestBody,
): Thunk<RoleMapping | undefined> => async dispatch => {
    try {
        dispatch({ payload: roleMapping, type: SUBMIT_NEW_ROLE_MAPPING_REQUEST });

        const response = await postNewRoleMapping(roleMapping);
        dispatch({ payload: response, type: SUBMIT_NEW_ROLE_MAPPING_SUCCESS });
        return response;
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        notification.error({ message: `Error creating role mapping: ${errorMessage}` });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: SUBMIT_ROLE_MAPPING_ERROR,
        });
    }
};

export const submitUpdatedRoleMapping = (
    activeRevisionId: number,
    roleMapping: RoleMapping,
): Thunk => async dispatch => {
    try {
        dispatch({
            payload: { activeRevisionId, roleMapping },
            type: SUBMIT_UPDATED_ROLE_MAPPING_REQUEST,
        });

        const response = await postUpdatedRoleMapping(activeRevisionId, roleMapping);
        dispatch({ payload: response, type: SUBMIT_UPDATED_ROLE_MAPPING_SUCCESS });

        notification.success({ message: "Successfully updated" });
        dispatch(goBack());
    } catch (error) {
        notification.error({
            message: get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            ),
        });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: SUBMIT_ROLE_MAPPING_ERROR,
        });
    }
};
