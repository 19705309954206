import {
    EnhancedNotification,
    NotificationRequestBody,
    Subscriber,
    SubscriberRequestBody,
    EmailNotificationRequestBody,
    JobPostRequest,
    Job,
    Email,
    JobPatchRequest,
} from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import {
    getNotifications,
    getSubscribers,
    postNewNotification,
    postNewSubscriber,
    sendEmailsForNotification,
    sendScheduleNotification,
    updateNotification,
    putSubscriber,
    deleteNotification,
    patchScheduleNotification,
} from "../api";

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";

export const FETCH_NETWORK_SUBSCRIBERS_REQUEST = "FETCH_NETWORK_SUBSCRIBERS_REQUEST";
export const FETCH_NETWORK_SUBSCRIBERS_SUCCESS = "FETCH_NETWORK_SUBSCRIBERS_SUCCESS";
export const FETCH_NETWORK_SUBSCRIBERS_ERROR = "FETCH_NETWORK_SUBSCRIBERS_ERROR";

export const SUBMIT_NOTIFICATION_REQUEST = "SUBMIT_NOTIFICATION_REQUEST";
export const SUBMIT_NOTIFICATION_SUCCESS = "SUBMIT_NOTIFICATION_SUCCESS";
export const SUBMIT_NOTIFICATION_ERROR = "SUBMIT_NOTIFICATION_ERROR";

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";

export const SUBMIT_SUBSCRIBER_REQUEST = "SUBMIT_SUBSCRIBER_REQUEST";
export const SUBMIT_SUBSCRIBER_SUCCESS = "SUBMIT_SUBSCRIBER_SUCCESS";
export const SUBMIT_SUBSCRIBER_ERROR = "SUBMIT_SUBSCRIBER_ERROR";

export const SEND_EMAIL_NOTIFICATION_REQUEST = "SEND_EMAIL_NOTIFICATION_REQUEST";
export const SEND_EMAIL_NOTIFICATION_SUCCESS = "SEND_EMAIL_NOTIFICATION_SUCCESS";
export const SEND_EMAIL_NOTIFICATION_ERROR = "SEND_EMAIL_NOTIFICATION_ERROR";
export const SEND_EMAIL_NOTIFICATION_RESET = "SEND_EMAIL_NOTIFICATION_RESET";

export const SCHEDULE_NOTIFICATION_REQUEST = "SCHEDULE_NOTIFICATION_REQUEST";
export const SCHEDULE_NOTIFICATION_SUCCESS = "SCHEDULE_NOTIFICATION_SUCCESS";
export const SCHEDULE_NOTIFICATION_ERROR = "SCHEDULE_NOTIFICATION_ERROR";

export const SET_EDIT_SUBSCRIBER = "SET_EDIT_SUBSCRIBER";
export const SET_EDIT_NOTIFICATION = "SET_EDIT_NOTIFICATION";

interface FetchNotificationsRequestAction {
    type: typeof FETCH_NOTIFICATIONS_REQUEST;
}

interface FetchNotificationsSuccessAction {
    type: typeof FETCH_NOTIFICATIONS_SUCCESS;
    payload: Array<EnhancedNotification>;
}

interface FetchNotificationsErrorAction {
    type: typeof FETCH_NOTIFICATIONS_ERROR;
}

interface FetchSubscribersRequestAction {
    type: typeof FETCH_NETWORK_SUBSCRIBERS_REQUEST;
}

interface FetchSubscribersSuccessAction {
    type: typeof FETCH_NETWORK_SUBSCRIBERS_SUCCESS;
    payload: Array<Subscriber>;
}

interface FetchSubscribersErrorAction {
    type: typeof FETCH_NETWORK_SUBSCRIBERS_ERROR;
}

interface SubmitNotificationRequestAction {
    type: typeof SUBMIT_NOTIFICATION_REQUEST;
}

interface SubmitNotificationSuccessAction {
    type: typeof SUBMIT_NOTIFICATION_SUCCESS;
    payload: EnhancedNotification;
}

interface SubmitNotificationErrorAction {
    type: typeof SUBMIT_NOTIFICATION_ERROR;
}

interface UpdateNotificationRequestAction {
    type: typeof UPDATE_NOTIFICATION_REQUEST;
}

interface UpdateNotificationSuccessAction {
    type: typeof UPDATE_NOTIFICATION_SUCCESS;
    payload: EnhancedNotification;
}

interface UpdateNotificationErrorAction {
    type: typeof UPDATE_NOTIFICATION_ERROR;
}

interface DeleteNotificationRequestAction {
    type: typeof DELETE_NOTIFICATION_REQUEST;
}

interface DeleteNotificationSuccessAction {
    type: typeof DELETE_NOTIFICATION_SUCCESS;
    payload: number;
}

interface DeleteNotificationErrorAction {
    type: typeof DELETE_NOTIFICATION_ERROR;
}

interface SubmitSubscriberRequestAction {
    type: typeof SUBMIT_SUBSCRIBER_REQUEST;
}

interface SubmitSubscriberSuccessAction {
    type: typeof SUBMIT_SUBSCRIBER_SUCCESS;
    payload: Subscriber;
}

interface SubmitSubscriberErrorAction {
    type: typeof SUBMIT_SUBSCRIBER_ERROR;
}

interface SendEmailNotificationRequestAction {
    type: typeof SEND_EMAIL_NOTIFICATION_REQUEST;
}

interface SendEmailNotificationSuccessAction {
    type: typeof SEND_EMAIL_NOTIFICATION_SUCCESS;
    payload: {
        status: number;
        email: Email;
    };
}

interface SendEmailNotificationErrorAction {
    type: typeof SEND_EMAIL_NOTIFICATION_ERROR;
}

interface SendEmailNotificationResetAction {
    type: typeof SEND_EMAIL_NOTIFICATION_RESET;
}

interface ScheduleNotificationRequestAction {
    type: typeof SCHEDULE_NOTIFICATION_REQUEST;
}

interface ScheduleNotificationSuccessAction {
    type: typeof SCHEDULE_NOTIFICATION_SUCCESS;
    payload: Job;
}

interface ScheduleNotificationErrorAction {
    type: typeof SCHEDULE_NOTIFICATION_ERROR;
}

interface SetEditSubscriberAction {
    type: typeof SET_EDIT_SUBSCRIBER;
    payload: {
        subscriber?: Subscriber;
        mode: "new" | "edit" | "closed";
    };
}

interface SetEditNotificationAction {
    type: typeof SET_EDIT_NOTIFICATION;
    payload: {
        notification?: EnhancedNotification;
        mode: "new" | "edit" | "closed";
    };
}

export type NotificationsActions =
    | FetchNotificationsRequestAction
    | FetchNotificationsSuccessAction
    | FetchNotificationsErrorAction
    | SubmitNotificationRequestAction
    | SubmitNotificationSuccessAction
    | SubmitNotificationErrorAction
    | FetchSubscribersSuccessAction
    | FetchSubscribersRequestAction
    | FetchSubscribersErrorAction
    | SubmitSubscriberRequestAction
    | SubmitSubscriberSuccessAction
    | SubmitSubscriberErrorAction
    | UpdateNotificationErrorAction
    | UpdateNotificationRequestAction
    | UpdateNotificationSuccessAction
    | DeleteNotificationErrorAction
    | DeleteNotificationRequestAction
    | DeleteNotificationSuccessAction
    | SendEmailNotificationErrorAction
    | SendEmailNotificationRequestAction
    | SendEmailNotificationSuccessAction
    | SendEmailNotificationResetAction
    | ScheduleNotificationErrorAction
    | ScheduleNotificationSuccessAction
    | ScheduleNotificationRequestAction
    | SetEditSubscriberAction
    | SetEditNotificationAction;

type Thunk<Result> = ThunkAction<Result, ApplicationState, void, NotificationsActions>;

export const setEditSubscriber = ({
    subscriber,
    mode,
}: {
    subscriber?: Subscriber;
    mode: "new" | "edit" | "closed";
}): SetEditSubscriberAction => ({
    payload: {
        mode,
        subscriber,
    },
    type: SET_EDIT_SUBSCRIBER,
});

export const setEditNotification = ({
    notification,
    mode,
}: {
    notification?: EnhancedNotification;
    mode: "new" | "edit" | "closed";
}): SetEditNotificationAction => ({
    payload: {
        mode,
        notification,
    },
    type: SET_EDIT_NOTIFICATION,
});

export const fetchNotifications = (networkId: string): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });

        const response = await getNotifications(networkId);
        dispatch({ payload: response.notifications, type: FETCH_NOTIFICATIONS_SUCCESS });
    } catch (error) {
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_NOTIFICATIONS_ERROR,
        });
    }
};

export const submitNewNotification = (
    newNotification: NotificationRequestBody,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: SUBMIT_NOTIFICATION_REQUEST });

        const response = await postNewNotification(newNotification);
        dispatch({ payload: response, type: SUBMIT_NOTIFICATION_SUCCESS });
        notification.success({ message: "Notification successfully created!" });
    } catch (error) {
        dispatch({ type: SUBMIT_NOTIFICATION_ERROR });

        notification.error({
            message: `Error creating notification: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const postUpdateNotification = (
    enhancedNotification: EnhancedNotification,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ payload: enhancedNotification, type: UPDATE_NOTIFICATION_REQUEST });

        const response: EnhancedNotification = await updateNotification(enhancedNotification);
        dispatch({ payload: response, type: UPDATE_NOTIFICATION_SUCCESS });
        notification.success({ message: "Notification successfully updated" });
    } catch (error) {
        dispatch({ type: UPDATE_NOTIFICATION_ERROR });
        notification.error({
            message: `Error updating notification: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const postDeleteNotification = (
    networkId: string,
    notificationId: number,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: DELETE_NOTIFICATION_REQUEST });

        await deleteNotification(networkId, notificationId);
        dispatch({ payload: notificationId, type: DELETE_NOTIFICATION_SUCCESS });
        notification.success({ message: "Notification successfully deleted" });
    } catch (error) {
        dispatch({ type: DELETE_NOTIFICATION_ERROR });
        notification.error({
            message: `Error delete notification: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const sendEmailsNotification = (
    notificationObject: EmailNotificationRequestBody,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: SEND_EMAIL_NOTIFICATION_REQUEST });
        const { status, email } = await sendEmailsForNotification(notificationObject);
        dispatch({
            payload: {
                email,
                status,
            },
            type: SEND_EMAIL_NOTIFICATION_SUCCESS,
        });
        if (status === 201) {
            notification.success({ message: "Emails sent successfully" });
        } else if (status === 204) {
            notification.warning({ message: "No log entries found" });
        }
    } catch (error) {
        dispatch({ type: SEND_EMAIL_NOTIFICATION_ERROR });
        notification.error({
            message: `Error sending emails: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const sendEmailsReset = (): SendEmailNotificationResetAction => ({
    type: "SEND_EMAIL_NOTIFICATION_RESET",
});

export const fetchNetworkSubscribers = (
    networkId: string,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: FETCH_NETWORK_SUBSCRIBERS_REQUEST });

        const response = await getSubscribers(networkId);
        dispatch({ payload: response.subscribers, type: FETCH_NETWORK_SUBSCRIBERS_SUCCESS });
    } catch (error) {
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_NETWORK_SUBSCRIBERS_ERROR,
        });
    }
};

export const submitNewSubscriber = (
    newSubscriber: SubscriberRequestBody,
    networkId: string,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: SUBMIT_SUBSCRIBER_REQUEST });

        const response = await postNewSubscriber(newSubscriber, networkId);
        dispatch({ payload: response, type: SUBMIT_SUBSCRIBER_SUCCESS });
        notification.success({ message: "Subscriber successfully created" });
    } catch (error) {
        dispatch({ type: SUBMIT_SUBSCRIBER_ERROR });
        notification.error({
            message: `Error creating Subscriber: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const updateSubscriber = (
    subscriber: Subscriber,
    networkId: string,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: SUBMIT_SUBSCRIBER_REQUEST });

        const response = await putSubscriber(subscriber, networkId);
        dispatch({ payload: response, type: SUBMIT_SUBSCRIBER_SUCCESS });
        notification.success({ message: "Subscriber successfully updated" });
    } catch (error) {
        dispatch({ type: SUBMIT_SUBSCRIBER_ERROR });
        notification.error({
            message: `Error updating Subscriber: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const scheduleNotification = (
    scheduleObject: Omit<JobPostRequest, "type" | "url">,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: SCHEDULE_NOTIFICATION_REQUEST });
        const response = await sendScheduleNotification(scheduleObject);
        dispatch({ payload: response, type: SCHEDULE_NOTIFICATION_SUCCESS });
        notification.success({ message: "Notification successfully scheduled" });
    } catch (error) {
        dispatch({ type: SCHEDULE_NOTIFICATION_ERROR });
        notification.error({
            message: `Error scheduling notification: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const updateScheduleNotification = (
    jobPatch: JobPatchRequest,
    networkId: string,
    jobId: string,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: SCHEDULE_NOTIFICATION_REQUEST });
        const response = await patchScheduleNotification(jobPatch, networkId, jobId);
        dispatch({ payload: response, type: SCHEDULE_NOTIFICATION_SUCCESS });
        if (jobPatch.schedule) {
            notification.success({ message: "Schedule successfully updated" });
        } else if (jobPatch.active) {
            notification.success({ message: "Schedule successfully unpaused" });
        } else {
            notification.success({ message: "Schedule successfully paused" });
        }
    } catch (error) {
        dispatch({ type: SCHEDULE_NOTIFICATION_ERROR });
        notification.error({
            message: `Error pausing notification: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};
