import React, { useEffect } from "react";
import { Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { LOADING_STATES } from "../../types";
import Login from "../login";
import { fetchUserSyncUser } from "../../modules/auth/actions";
import { selectUser, selectUserLoadingState } from "../../modules/auth/selectors";

interface AuthProps {
    children: JSX.Element;
}

const Auth = (props: AuthProps): JSX.Element => {
    const user = useSelector(selectUser);
    const loadingState = useSelector(selectUserLoadingState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            dispatch(fetchUserSyncUser());
        }
    }, [user]);

    if (loadingState === LOADING_STATES.SUCCESSFUL) {
        return props.children;
    }

    if (loadingState === LOADING_STATES.ERROR) {
        return <Login />;
    }

    return <Spin delay={250} />;
};

export default Auth;
