import { ExpressionHelpersLookup } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { mapHelpersToLookup } from "@speakap/expression-engine";
import staticFunctions from "@speakap/expression-engine/dist/functions.json";

import { ApplicationState } from "../reducer";
import { LOADING_STATES } from "../../types";
import { getExpressionHelpers } from "../api";
import { selectExpressionHelpersItems, selectExpressionHelpersLoadingState } from "./selectors";

export const FETCH_EXPRESSION_HELPERS_REQUEST = "FETCH_EXPRESSION_HELPERS_REQUEST";
export const FETCH_EXPRESSION_HELPERS_SUCCESS = "FETCH_EXPRESSION_HELPERS_SUCCESS";
export const FETCH_EXPRESSION_HELPERS_ERROR = "FETCH_EXPRESSION_HELPERS_ERROR";

interface FetchExpressionHelpersRequestAction {
    type: typeof FETCH_EXPRESSION_HELPERS_REQUEST;
}

interface FetchExpressionHelpersSuccessAction {
    type: typeof FETCH_EXPRESSION_HELPERS_SUCCESS;
    payload: ExpressionHelpersLookup;
}

interface FetchExpressionHelpersErrorAction {
    type: typeof FETCH_EXPRESSION_HELPERS_ERROR;
    payload: ExpressionHelpersLookup;
}

export type ExpressionHelpersActions =
    | FetchExpressionHelpersRequestAction
    | FetchExpressionHelpersSuccessAction
    | FetchExpressionHelpersErrorAction;

type Thunk<T = void> = ThunkAction<Promise<T>, ApplicationState, void, ExpressionHelpersActions>;

export const fetchExpressionHelpers = (): Thunk<ExpressionHelpersLookup> => async (
    dispatch,
    getState,
) => {
    const state = getState();
    const loadingState = selectExpressionHelpersLoadingState(state);
    if (loadingState !== LOADING_STATES.INITIAL) {
        return selectExpressionHelpersItems(state);
    }
    dispatch({
        type: FETCH_EXPRESSION_HELPERS_REQUEST,
    });
    try {
        const expressionHelpers = await getExpressionHelpers();
        const expressionHelpersLookup = mapHelpersToLookup(expressionHelpers);
        dispatch({
            payload: expressionHelpersLookup,
            type: FETCH_EXPRESSION_HELPERS_SUCCESS,
        });
        return expressionHelpersLookup;
    } catch {
        const expressionHelpersLookup = mapHelpersToLookup(staticFunctions.functions);
        dispatch({
            payload: expressionHelpersLookup,
            type: FETCH_EXPRESSION_HELPERS_ERROR,
        });
        return expressionHelpersLookup;
    }
};
