import { ThunkAction } from "redux-thunk";
import { UserSyncUser } from "@speakap/types";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import {
    getUserSyncUser,
    logoutUserSyncUser,
    getAuthenticationUrl,
    loginSpeakapUser,
} from "../api";

export const FETCH_USERSYNC_USER_REQUEST = "FETCH_USERSYNC_USER_REQUEST";
export const FETCH_USERSYNC_USER_SUCCESS = "FETCH_USERSYNC_USER_SUCCESS";
export const FETCH_USERSYNC_USER_ERROR = "FETCH_USERSYNC_USER_ERROR";

export const FETCH_GOOGLE_URL_REQUEST = "FETCH_GOOGLE_URL_REQUEST";
export const FETCH_GOOGLE_URL_SUCCESS = "FETCH_GOOGLE_URL_SUCCESS";
export const FETCH_GOOGLE_URL_ERROR = "FETCH_GOOGLE_URL_ERROR";

export const LOGIN_SPEAKAP_USER_REQUEST = "LOGIN_SPEAKAP_USER_REQUEST";
export const LOGIN_SPEAKAP_USER_SUCCESS = "LOGIN_SPEAKAP_USER_SUCCESS";
export const LOGIN_SPEAKAP_USER_ERROR = "LOGIN_SPEAKAP_USER_ERROR";

export const LOGOUT_USERSYNC_USER_REQUEST = "LOGOUT_USERSYNC_USER_REQUEST";
export const LOGOUT_USERSYNC_USER_SUCCESS = "LOGOUT_USERSYNC_USER_SUCCESS";
export const LOGOUT_USERSYNC_USER_ERROR = "LOGOUT_USERSYNC_USER_ERROR";

interface FetchUserSyncUserRequestAction {
    type: typeof FETCH_USERSYNC_USER_REQUEST;
}

interface FetchUserSyncUserSuccessAction {
    type: typeof FETCH_USERSYNC_USER_SUCCESS;
    payload: UserSyncUser;
}

interface FetchUserSyncUserErrorAction {
    type: typeof FETCH_USERSYNC_USER_ERROR;
}

interface FetchGoogleUrlRequestAction {
    type: typeof FETCH_GOOGLE_URL_REQUEST;
}

interface FetchGoogleUrlSuccessAction {
    type: typeof FETCH_GOOGLE_URL_SUCCESS;
}

interface FetchGoogleUrlErrorAction {
    type: typeof FETCH_GOOGLE_URL_ERROR;
}

interface LoginSpeakapUserRequestAction {
    type: typeof LOGIN_SPEAKAP_USER_REQUEST;
}

interface LoginSpeakapUserSuccessAction {
    type: typeof LOGIN_SPEAKAP_USER_SUCCESS;
}

interface LoginSpeakapUserErrorAction {
    type: typeof LOGIN_SPEAKAP_USER_ERROR;
}

interface LogoutUserSyncUserRequestAction {
    type: typeof LOGOUT_USERSYNC_USER_REQUEST;
}

interface LogoutUserSyncUserSuccessAction {
    type: typeof LOGOUT_USERSYNC_USER_SUCCESS;
}

interface LogoutUserSyncUserErrorAction {
    type: typeof LOGOUT_USERSYNC_USER_ERROR;
}

export type AuthActions =
    | FetchUserSyncUserRequestAction
    | FetchUserSyncUserSuccessAction
    | FetchUserSyncUserErrorAction
    | FetchGoogleUrlRequestAction
    | FetchGoogleUrlSuccessAction
    | FetchGoogleUrlErrorAction
    | LoginSpeakapUserRequestAction
    | LoginSpeakapUserSuccessAction
    | LoginSpeakapUserErrorAction
    | LogoutUserSyncUserRequestAction
    | LogoutUserSyncUserSuccessAction
    | LogoutUserSyncUserErrorAction;

type Thunk = ThunkAction<Promise<void>, ApplicationState, void, AuthActions>;

export const fetchUserSyncUser = (): Thunk => async dispatch => {
    try {
        dispatch({ type: FETCH_USERSYNC_USER_REQUEST });

        const response = await getUserSyncUser();
        dispatch({ payload: response, type: FETCH_USERSYNC_USER_SUCCESS });
    } catch (error) {
        dispatch({ type: FETCH_USERSYNC_USER_ERROR });
    }
};

export const logoutUser = (): Thunk => async dispatch => {
    try {
        dispatch({ type: LOGOUT_USERSYNC_USER_REQUEST });

        await logoutUserSyncUser();
        dispatch({ type: LOGOUT_USERSYNC_USER_SUCCESS });
    } catch (error) {
        dispatch({ type: LOGOUT_USERSYNC_USER_ERROR });
    }
};

export const redirectToGoogle = (): Thunk => async dispatch => {
    try {
        dispatch({ type: FETCH_GOOGLE_URL_REQUEST });

        const url = await getAuthenticationUrl(`${location.origin}${location.pathname}`);
        window.location.href = url;
        dispatch({ type: FETCH_GOOGLE_URL_SUCCESS });
    } catch (error) {
        notification.error({
            message: "Could not redirect to Google. Please try again later.",
        });
        dispatch({ type: FETCH_GOOGLE_URL_ERROR });
    }
};

export const loginUser = (username: string, password: string): Thunk => async dispatch => {
    try {
        dispatch({ type: LOGIN_SPEAKAP_USER_REQUEST });

        const accessToken = await loginSpeakapUser(username, password);
        const url = new URL(location.href);
        url.searchParams.append("access_token", accessToken);
        window.location.href = url.toString();
        dispatch({ type: LOGIN_SPEAKAP_USER_SUCCESS });
    } catch (error) {
        notification.error({
            message: "Could not login",
        });
        dispatch({ type: LOGIN_SPEAKAP_USER_ERROR });
    }
};
