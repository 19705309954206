import { UserSyncUser, SpeakapUserPostPayload } from "@speakap/types";

import axios from "./request";

export const getSpeakapUsers = (networkId: string): Promise<Array<UserSyncUser>> =>
    axios.get(`/speakap-users?networkId=${networkId}`).then(({ data }) => data.users);

export const createSpeakapUser = (speakapUser: SpeakapUserPostPayload): Promise<UserSyncUser> =>
    axios.post("/speakap-users", speakapUser).then(({ data }) => data.user);

export const updateSpeakapUser = (user: UserSyncUser): Promise<UserSyncUser> =>
    axios.put(`/speakap-users/${user.id}`, user).then(({ data }) => data.user);

export const deleteSpeakapUser = (userId: number, networkId: string): Promise<void> =>
    axios.delete(`/speakap-users/${userId}/networks/${networkId}`);
