import { EnhancedNotification, Subscriber, Email } from "@speakap/types";
import produce from "immer";

import {
    FETCH_NETWORK_SUBSCRIBERS_ERROR,
    FETCH_NETWORK_SUBSCRIBERS_REQUEST,
    FETCH_NETWORK_SUBSCRIBERS_SUCCESS,
    FETCH_NOTIFICATIONS_ERROR,
    FETCH_NOTIFICATIONS_REQUEST,
    FETCH_NOTIFICATIONS_SUCCESS,
    NotificationsActions,
    SCHEDULE_NOTIFICATION_ERROR,
    SCHEDULE_NOTIFICATION_REQUEST,
    SCHEDULE_NOTIFICATION_SUCCESS,
    SEND_EMAIL_NOTIFICATION_ERROR,
    SEND_EMAIL_NOTIFICATION_REQUEST,
    SEND_EMAIL_NOTIFICATION_SUCCESS,
    SUBMIT_NOTIFICATION_ERROR,
    SUBMIT_NOTIFICATION_REQUEST,
    SUBMIT_NOTIFICATION_SUCCESS,
    SUBMIT_SUBSCRIBER_ERROR,
    SUBMIT_SUBSCRIBER_REQUEST,
    SUBMIT_SUBSCRIBER_SUCCESS,
    UPDATE_NOTIFICATION_ERROR,
    UPDATE_NOTIFICATION_REQUEST,
    UPDATE_NOTIFICATION_SUCCESS,
    SET_EDIT_SUBSCRIBER,
    SET_EDIT_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_REQUEST,
    DELETE_NOTIFICATION_ERROR,
    SEND_EMAIL_NOTIFICATION_RESET,
} from "./actions";
import { LOADING_STATES } from "../../types";

export interface NotificationsState {
    loadingState: LOADING_STATES;
    notifications: Array<EnhancedNotification>;
    deleteNotification: {
        loadingState: LOADING_STATES;
    };
    scheduleNotification: {
        loadingState: LOADING_STATES;
    };
    sendNotification: {
        loadingState: LOADING_STATES;
        status?: number;
        email?: Email;
    };
    subscribers: {
        items: Array<Subscriber>;
        loadingState: LOADING_STATES;
    };
    editSubscriber: {
        item?: Subscriber;
        loadingState: LOADING_STATES;
        mode: "new" | "edit" | "closed";
    };
    editNotification: {
        item?: EnhancedNotification;
        loadingState: LOADING_STATES;
        mode: "new" | "edit" | "closed";
    };
}

export const initialState: NotificationsState = {
    deleteNotification: {
        loadingState: LOADING_STATES.INITIAL,
    },
    editNotification: {
        loadingState: LOADING_STATES.INITIAL,
        mode: "closed",
    },
    editSubscriber: {
        loadingState: LOADING_STATES.INITIAL,
        mode: "closed",
    },
    loadingState: LOADING_STATES.INITIAL,
    notifications: [],
    scheduleNotification: {
        loadingState: LOADING_STATES.INITIAL,
    },
    sendNotification: {
        loadingState: LOADING_STATES.INITIAL,
    },
    subscribers: {
        items: [],
        loadingState: LOADING_STATES.INITIAL,
    },
};

const networksReducer = (
    state: NotificationsState = initialState,
    action: NotificationsActions,
): NotificationsState =>
    produce(state, (draft: NotificationsState) => {
        switch (action.type) {
            case FETCH_NOTIFICATIONS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_NOTIFICATIONS_SUCCESS:
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.notifications = action.payload;
                break;
            case FETCH_NOTIFICATIONS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
            case SUBMIT_NOTIFICATION_REQUEST:
                draft.editNotification.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_NOTIFICATION_SUCCESS:
                draft.notifications.push(action.payload);
                draft.editNotification.mode = "closed";
                draft.editNotification.loadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case SUBMIT_NOTIFICATION_ERROR:
                draft.editNotification.loadingState = LOADING_STATES.ERROR;
                break;
            case FETCH_NETWORK_SUBSCRIBERS_REQUEST:
                draft.subscribers.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_NETWORK_SUBSCRIBERS_SUCCESS:
                draft.subscribers.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.subscribers.items = action.payload;
                break;
            case FETCH_NETWORK_SUBSCRIBERS_ERROR:
                draft.subscribers.loadingState = LOADING_STATES.ERROR;
                draft.subscribers.items = [];
                break;
            case SUBMIT_SUBSCRIBER_SUCCESS: {
                if (state.editSubscriber.mode === "new") {
                    draft.subscribers.items.push(action.payload);
                } else if (state.editSubscriber.mode === "edit") {
                    draft.subscribers.items = state.subscribers.items.map(subscriber =>
                        subscriber.id === action.payload.id ? action.payload : subscriber,
                    );
                }
                draft.editSubscriber.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.editSubscriber.mode = "closed";
                draft.editSubscriber.item = undefined;
                break;
            }
            case SUBMIT_SUBSCRIBER_REQUEST:
                draft.editSubscriber.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_SUBSCRIBER_ERROR:
                draft.editSubscriber.loadingState = LOADING_STATES.ERROR;
                break;
            case SET_EDIT_SUBSCRIBER:
                draft.editSubscriber.item = action.payload.subscriber;
                draft.editSubscriber.mode = action.payload.mode;
                break;
            case SET_EDIT_NOTIFICATION:
                draft.editNotification.item = action.payload.notification;
                draft.editNotification.mode = action.payload.mode;
                break;
            case UPDATE_NOTIFICATION_REQUEST:
                draft.editNotification.loadingState = LOADING_STATES.FETCHING;
                break;
            case UPDATE_NOTIFICATION_ERROR:
                draft.editNotification.loadingState = LOADING_STATES.ERROR;
                break;
            case UPDATE_NOTIFICATION_SUCCESS:
                draft.editNotification.mode = "closed";
                draft.notifications = state.notifications.map(notification =>
                    notification.id === action.payload.id
                        ? { ...action.payload, job: notification.job }
                        : notification,
                );
                draft.editNotification.loadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case SEND_EMAIL_NOTIFICATION_SUCCESS:
                draft.sendNotification.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.sendNotification.email = action.payload.email;
                draft.sendNotification.status = action.payload.status;
                break;
            case SEND_EMAIL_NOTIFICATION_ERROR:
                draft.sendNotification.loadingState = LOADING_STATES.ERROR;
                break;
            case SEND_EMAIL_NOTIFICATION_REQUEST:
                draft.sendNotification.loadingState = LOADING_STATES.FETCHING;
                break;
            case SEND_EMAIL_NOTIFICATION_RESET:
                draft.sendNotification.loadingState = LOADING_STATES.INITIAL;
                draft.sendNotification.email = undefined;
                draft.sendNotification.status = undefined;
                break;
            case SCHEDULE_NOTIFICATION_REQUEST:
                draft.scheduleNotification.loadingState = LOADING_STATES.FETCHING;
                break;
            case SCHEDULE_NOTIFICATION_SUCCESS:
                draft.scheduleNotification.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.notifications = state.notifications.map(notification =>
                    notification.id === +action.payload.resourceIdentifier
                        ? { ...notification, job: action.payload }
                        : notification,
                );
                break;
            case SCHEDULE_NOTIFICATION_ERROR:
                draft.scheduleNotification.loadingState = LOADING_STATES.ERROR;
                break;
            case DELETE_NOTIFICATION_REQUEST:
                draft.deleteNotification.loadingState = LOADING_STATES.FETCHING;
                break;
            case DELETE_NOTIFICATION_SUCCESS:
                draft.notifications = state.notifications.filter(
                    notification => notification.id !== action.payload,
                );
                break;
            case DELETE_NOTIFICATION_ERROR:
                draft.deleteNotification.loadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default networksReducer;
