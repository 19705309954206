import { CallHistoryMethodAction } from "connected-react-router";
import { ColumnMapping, ColumnMappingRequestBody, ImportUser } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { fetchImportUsers } from "../connectors/actions";
import {
    getColumnMappings,
    postNewColumnMapping,
    postUpdatedColumnMapping,
} from "../../modules/api";
import { selectApplyColumnMappingConfigName, selectColumnMappingsNetworkId } from "./selectors";
import { selectConfigItems, selectEditItemConfig } from "../configs/selectors";
import { selectConnectorsItems, selectImportUsersItems } from "../connectors/selectors";

export const FETCH_COLUMN_MAPPINGS_REQUEST = "FETCH_COLUMN_MAPPINGS_REQUEST";
export const FETCH_COLUMN_MAPPINGS_SUCCESS = "FETCH_COLUMN_MAPPINGS_SUCCESS";
export const FETCH_COLUMN_MAPPINGS_ERROR = "FETCH_COLUMN_MAPPINGS_ERROR";

export const SUBMIT_NEW_COLUMN_MAPPING_REQUEST = "SUBMIT_NEW_COLUMN_MAPPING_REQUEST";
export const SUBMIT_NEW_COLUMN_MAPPING_SUCCESS = "SUBMIT_NEW_COLUMN_MAPPING_SUCCESS";
export const SUBMIT_UPDATED_COLUMN_MAPPING_REQUEST = "SUBMIT_UPDATED_COLUMN_MAPPING_REQUEST";
export const SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS = "SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS";
export const SUBMIT_COLUMN_MAPPING_ERROR = "SUBMIT_COLUMN_MAPPING_ERROR";

export const APPLY_COLUMN_MAPPINGS_REQUEST = "APPLY_COLUMN_MAPPINGS_REQUEST";
export const APPLY_COLUMN_MAPPINGS_SUCCESS = "APPLY_COLUMN_MAPPINGS_SUCCESS";
export const APPLY_COLUMN_MAPPINGS_ERROR = "APPLY_COLUMN_MAPPINGS_ERROR";

interface FetchColumnMappingsRequestAction {
    type: typeof FETCH_COLUMN_MAPPINGS_REQUEST;
    payload: string;
}

interface FetchColumnMappingsSuccessAction {
    type: typeof FETCH_COLUMN_MAPPINGS_SUCCESS;
    payload: {
        columnMappings: Array<ColumnMapping>;
        networkId: string;
    };
}

interface FetchColumnMappingsErrorAction {
    type: typeof FETCH_COLUMN_MAPPINGS_ERROR;
}

interface SubmitNewColumnMappingRequestAction {
    type: typeof SUBMIT_NEW_COLUMN_MAPPING_REQUEST;
    payload: ColumnMappingRequestBody;
}

interface SubmitNewColumnMappingSuccessAction {
    type: typeof SUBMIT_NEW_COLUMN_MAPPING_SUCCESS;
    payload: ColumnMapping;
}

interface SubmitUpdatedColumnMappingRequestAction {
    type: typeof SUBMIT_UPDATED_COLUMN_MAPPING_REQUEST;
    payload: { activeRevisionId: number; columnMapping: ColumnMappingRequestBody };
}

export interface SubmitUpdatedColumnMappingSuccessAction {
    type: typeof SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS;
    payload: ColumnMapping;
}

interface SubmitColumnMappingErrorAction {
    type: typeof SUBMIT_COLUMN_MAPPING_ERROR;
}

interface ApplyColumnMappingsRequestAction {
    type: typeof APPLY_COLUMN_MAPPINGS_REQUEST;
    payload: {
        configName: string;
    };
}

interface ApplyColumnMappingsSuccessAction {
    type: typeof APPLY_COLUMN_MAPPINGS_SUCCESS;
    payload: Array<ImportUser>;
}

interface ApplyColumnMappingsErrorAction {
    type: typeof APPLY_COLUMN_MAPPINGS_ERROR;
}

export type ColumnMappingsAction =
    | ApplyColumnMappingsSuccessAction
    | ApplyColumnMappingsRequestAction
    | ApplyColumnMappingsErrorAction
    | FetchColumnMappingsSuccessAction
    | FetchColumnMappingsRequestAction
    | FetchColumnMappingsErrorAction
    | SubmitNewColumnMappingRequestAction
    | SubmitNewColumnMappingSuccessAction
    | SubmitUpdatedColumnMappingRequestAction
    | SubmitUpdatedColumnMappingSuccessAction
    | SubmitColumnMappingErrorAction;

type Thunk<T = void> = ThunkAction<
    Promise<T>,
    ApplicationState,
    void,
    CallHistoryMethodAction | ColumnMappingsAction
>;

export const fetchColumnMappings = (networkId: string): Thunk => async (dispatch, getState) => {
    const currentNetworkId = selectColumnMappingsNetworkId(getState());
    if (networkId !== currentNetworkId) {
        try {
            dispatch({ payload: networkId, type: FETCH_COLUMN_MAPPINGS_REQUEST });

            const response = await getColumnMappings({ active: true, networkId });
            dispatch({
                payload: { columnMappings: response.mappings, networkId },
                type: FETCH_COLUMN_MAPPINGS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                message: error instanceof Error ? error.message : "Error",
                type: FETCH_COLUMN_MAPPINGS_ERROR,
            });
        }
    }
};

export const submitNewColumnMapping = (
    formData: ColumnMappingRequestBody,
): Thunk<ColumnMapping | undefined> => async dispatch => {
    try {
        dispatch({ payload: formData, type: SUBMIT_NEW_COLUMN_MAPPING_REQUEST });

        const response = await postNewColumnMapping(formData);
        dispatch({ payload: response, type: SUBMIT_NEW_COLUMN_MAPPING_SUCCESS });
        return response;
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        notification.error({ message: `Error creating column mapping: ${errorMessage}` });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: SUBMIT_COLUMN_MAPPING_ERROR,
        });
    }
};

export const submitUpdatedColumnMapping = (
    activeRevisionId: number,
    columnMapping: ColumnMappingRequestBody,
): Thunk<[string | null, ColumnMapping | null]> => async dispatch => {
    try {
        dispatch({
            payload: { activeRevisionId, columnMapping },
            type: SUBMIT_UPDATED_COLUMN_MAPPING_REQUEST,
        });

        const response = await postUpdatedColumnMapping(activeRevisionId, columnMapping);
        dispatch({ payload: response, type: SUBMIT_UPDATED_COLUMN_MAPPING_SUCCESS });

        return [null, response];
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        dispatch({ message: errorMessage, type: SUBMIT_COLUMN_MAPPING_ERROR });
        return [errorMessage, null];
    }
};

export const prepareColumnMappingExamples = (configName: string): Thunk => async (
    dispatch,
    getState,
) => {
    const state = getState();
    const currentConfigName = selectApplyColumnMappingConfigName(state);
    if (configName === currentConfigName) {
        return;
    }

    dispatch({ payload: { configName }, type: APPLY_COLUMN_MAPPINGS_REQUEST });

    try {
        const configs = selectConfigItems(state);
        const editConfig = selectEditItemConfig(state);
        const config = configs[configName];
        if (!config) {
            throw new Error(`Cannot find config with name [${configName}]`);
        }
        const connectorName = editConfig?.connectorName || config.connectorName;
        if (!connectorName) {
            throw new Error("Selected configuration has not connector attached to it.");
        }
        const connectors = selectConnectorsItems(state);
        const connector = connectors[connectorName];
        if (!connector) {
            throw new Error(`Cannot find connector with name [${connectorName}]`);
        }
        await dispatch(fetchImportUsers(connector));
        const importUsers = selectImportUsersItems(getState());

        dispatch({ payload: [...importUsers.slice(0, 100)], type: APPLY_COLUMN_MAPPINGS_SUCCESS });
    } catch {
        dispatch({ type: APPLY_COLUMN_MAPPINGS_ERROR });
    }
};
