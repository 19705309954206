import { ConnectorRun } from "@speakap/types";
import produce from "immer";

import { LOADING_STATES } from "../../types";
import {
    RunsActions,
    SUBMIT_RUNS_ERROR,
    SUBMIT_RUNS_REQUEST,
    SUBMIT_RUNS_SUCCESS,
} from "./actions";

export interface RunsState {
    loadingState: LOADING_STATES;
    results: Array<number>;
    items: {
        [id: number]: ConnectorRun;
    };
    error: {
        connectorName?: string;
        data?: string;
        message: string;
        serviceName?: string;
    };
}

export const initialState = {
    error: {
        message: "",
    },
    items: {},
    loadingState: LOADING_STATES.INITIAL,
    results: [],
};

const networksReducer = (state: RunsState = initialState, action: RunsActions): RunsState =>
    produce(state, (draft: RunsState) => {
        switch (action.type) {
            case SUBMIT_RUNS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case SUBMIT_RUNS_SUCCESS: {
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items[action.payload.id] = action.payload;
                draft.results.push(action.payload.id);
                break;
            }
            case SUBMIT_RUNS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                draft.error = action.payload;
                break;
        }
    });

export default networksReducer;
