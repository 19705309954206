import { Config, Job, JobPatchRequest, JobPostRequest } from "@speakap/types";

import axios from "./request";

export const postJob = (config: Config, job: Omit<JobPostRequest, "url" | "type">): Promise<Job> =>
    axios.post<Job>(`/networks/${config.networkId}/sync-jobs/`, job).then(({ data }) => data);

export const patchJob = (config: Config, jobId: string, job: JobPatchRequest): Promise<Job> =>
    axios
        .patch<Job>(`/networks/${config.networkId}/sync-jobs/${jobId}/`, job)
        .then(({ data }) => data);

export const deleteJob = (config: Config, jobId: string): Promise<void> =>
    axios.delete(`/networks/${config.networkId}/sync-jobs/${jobId}/`);
