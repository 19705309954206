import { SyncProtocol } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { getSyncProtocols } from "../api";
import { selectSyncProtocolsOffset } from "./selectors";

export const FETCH_SYNC_PROTOCOLS_REQUEST = "FETCH_SYNC_PROTOCOLS_REQUEST";
export const FETCH_SYNC_PROTOCOLS_SUCCESS = "FETCH_SYNC_PROTOCOLS_SUCCESS";
export const FETCH_SYNC_PROTOCOLS_ERROR = "FETCH_SYNC_PROTOCOLS_ERROR";

interface FetchSyncProtocolsRequestAction {
    type: typeof FETCH_SYNC_PROTOCOLS_REQUEST;
}

interface FetchSyncProtocolsSuccessAction {
    type: typeof FETCH_SYNC_PROTOCOLS_SUCCESS;
    payload: {
        protocols: Array<SyncProtocol>;
        limit: number;
        hasMore: boolean;
    };
}

interface FetchSyncProtocolsErrorAction {
    type: typeof FETCH_SYNC_PROTOCOLS_ERROR;
}

export type SyncProtocolActions =
    | FetchSyncProtocolsSuccessAction
    | FetchSyncProtocolsRequestAction
    | FetchSyncProtocolsErrorAction;

type Thunk = ThunkAction<Promise<void>, ApplicationState, void, SyncProtocolActions>;

const limit = 10;

export const fetchSyncProtocols = (networkId: string, onMount: boolean): Thunk => async (
    dispatch,
    getState,
) => {
    try {
        if (onMount) {
            dispatch({ type: FETCH_SYNC_PROTOCOLS_REQUEST });
        }
        const offset = selectSyncProtocolsOffset(getState());
        const response = await getSyncProtocols({ limit, networkId, offset });
        dispatch({
            payload: { hasMore: response.length === limit, limit, protocols: response },
            type: FETCH_SYNC_PROTOCOLS_SUCCESS,
        });
    } catch (error) {
        const message = get(
            error,
            "response.data",
            error instanceof Error ? error.message : "Error",
        );
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_SYNC_PROTOCOLS_ERROR,
        });
        notification.error({
            message,
        });
    }
};
