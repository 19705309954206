import produce from "immer";

import {
    ExpressionHelpersActions,
    FETCH_EXPRESSION_HELPERS_ERROR,
    FETCH_EXPRESSION_HELPERS_REQUEST,
    FETCH_EXPRESSION_HELPERS_SUCCESS,
} from "./actions";
import { LOADING_STATES } from "../../types";

export interface ExpressionHelpersState {
    loadingState: LOADING_STATES;
    items: {
        [key: string]: (...args: Array<string>) => string;
    };
}

export const initialState: ExpressionHelpersState = {
    items: {},
    loadingState: LOADING_STATES.INITIAL,
};

const revisionsReducer = (
    state: ExpressionHelpersState = initialState,
    action: ExpressionHelpersActions,
): ExpressionHelpersState =>
    produce(state, (draft: ExpressionHelpersState) => {
        switch (action.type) {
            case FETCH_EXPRESSION_HELPERS_REQUEST:
                draft.loadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_EXPRESSION_HELPERS_SUCCESS: {
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = action.payload;
                break;
            }
            case FETCH_EXPRESSION_HELPERS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default revisionsReducer;
