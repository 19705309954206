import { ConnectorRun, ImportUser } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";

import { ApplicationState } from "../reducer";
import { postRuns, PostRunsParams } from "../api";

export const SUBMIT_RUNS_REQUEST = "SUBMIT_RUNS_REQUEST";
export const SUBMIT_RUNS_SUCCESS = "SUBMIT_RUNS_SUCCESS";
export const SUBMIT_RUNS_ERROR = "SUBMIT_RUNS_ERROR";

interface SubmitRunsRequestAction {
    type: typeof SUBMIT_RUNS_REQUEST;
}

export interface SubmitRunsSuccessAction {
    type: typeof SUBMIT_RUNS_SUCCESS;
    payload: ConnectorRun;
}

interface SubmitRunsErrorAction {
    type: typeof SUBMIT_RUNS_ERROR;
    payload: {
        connectorName?: string;
        data?: string;
        message: string;
        serviceName?: string;
    };
}

export type RunsActions = SubmitRunsRequestAction | SubmitRunsSuccessAction | SubmitRunsErrorAction;

export function submitRuns(
    payload: PostRunsParams,
): ThunkAction<
    Promise<[null | string, null | Array<ImportUser>]>,
    ApplicationState,
    void,
    RunsActions
> {
    return async dispatch => {
        try {
            dispatch({ type: SUBMIT_RUNS_REQUEST });

            const response = await postRuns(payload);
            dispatch({ payload: response, type: SUBMIT_RUNS_SUCCESS });
            return [null, response.importUsers];
        } catch (error) {
            const errorMessage =
                get(error, ["response", "data", "message"]) ||
                get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
            dispatch({ payload: errorMessage, type: SUBMIT_RUNS_ERROR });
            return [errorMessage, null];
        }
    };
}
