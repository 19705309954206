import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import React from "react";
import { Row, Col, Badge, Form, Input, Tooltip } from "antd";
import { get } from "lodash";

import FieldExamples from "./column-mappings-field-examples";
import { FieldOption, FieldComponentProps } from "./column-mappings-utils";
import styles from "./column-mappings-form.module.css";

export function createInputField(
    field: Omit<FieldOption, "key"> & { key: Array<string | number> | string },
) {
    return function InputFieldComponent(props: FieldComponentProps) {
        const { form, columnMapping, removeButton, modalButton } = props;
        const { label, selector, key, required, modal = true, example } = field;
        const initialValue = get(columnMapping, selector, "");
        const rules = [];

        if (required) {
            rules.push({
                message: `Please select a value for "${label}"!`,
                required: true,
            });
        }

        return (
            <Row key={key.toString()} align="top" gutter={{ lg: 12 }}>
                <Col lg={2} className={styles.inputLabel}>
                    {required ? <Badge dot>{label}</Badge> : label}
                </Col>
                <Col>
                    <Tooltip
                        title={
                            label === "Country code" || label === "Preferred language code"
                                ? `Example: ${example}.`
                                : example
                                ? `Example: ${example}`
                                : ""
                        }
                        placement="top"
                    >
                        {example ? <InfoCircleOutlined /> : null}
                    </Tooltip>
                </Col>
                <Col lg={21}>
                    <Form.Item
                        name={key}
                        initialValue={initialValue}
                        rules={rules}
                        extra={
                            (label === "Country code" || label === "Preferred language code") &&
                            (form.getFieldValue(key) === "" ||
                                form.getFieldValue(key) === undefined) &&
                            initialValue === "" ? (
                                <div className={styles.applyColumnMappingWarning}>
                                    <WarningOutlined />
                                    {
                                        " Make sure that the target column has valid ISO country codes or the column is normalized"
                                    }
                                </div>
                            ) : modal ? (
                                <FieldExamples
                                    selector={selector.slice(1)}
                                    value={form.getFieldValue(key) || initialValue}
                                />
                            ) : label === "Target" ? (
                                <strong>
                                    Make sure that your target name does not include whitespaces and
                                    does not overlap with other field names
                                </strong>
                            ) : (
                                <strong>
                                    Make sure to wrap your text in quotes (&quot;) for this field
                                </strong>
                            )
                        }
                    >
                        <Input
                            placeholder={`Select a value for "${label}"`}
                            addonAfter={!required && removeButton}
                            addonBefore={modal && modalButton && modalButton(field)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    };
}
