import {
    GroupMapping,
    GroupMappingRequestBody,
    SCIM_ENTERPRISE_USER_SCHEMA,
    SPEAKAP_PARAM_BAG_SCHEMA,
    ScimUser,
    SpeakapGroup,
    ConnectorType,
    ParamBagItem,
    EnhancedConfig,
} from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get, without, uniq, fromPairs, compact, sortBy, isArray } from "lodash";
import { goBack, CallHistoryMethodAction } from "connected-react-router";
import { mapColumns, flattenScimUser } from "@speakap/expression-engine";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { ConditionOption } from "../../components/expression-builder/types";
import { LOADING_STATES } from "../../types";
import { fetchColumnMappings } from "../column-mappings/actions";
import { fetchExpressionHelpers } from "../expression-helpers/actions";
import { fetchImportUsers } from "../connectors/actions";
import { getGroupMappings, getGroups, postNewGroupMapping, postUpdatedGroupMapping } from "../api";
import { selectColumnMappingsItems } from "../column-mappings/selectors";
import { selectConfigItems, selectEditItem } from "../configs/selectors";
import { selectConnectorsItems, selectImportUsersItems } from "../connectors/selectors";
import {
    selectUpdateSuggestionsLoadingState,
    selectGroupMappingsNetworkId,
    selectGroupsNetworkId,
} from "../group-mappings/selectors";
import { submitRuns } from "../runs/actions";
import * as utils from "../../components/column-mappings/column-mappings-form/column-mappings-utils";

export const FETCH_GROUP_MAPPINGS_REQUEST = "user-sync/group-mappings/FETCH_GROUP_MAPPINGS_REQUEST";
export const FETCH_GROUP_MAPPINGS_SUCCESS = "user-sync/group-mappings/FETCH_GROUP_MAPPINGS_SUCCESS";
export const FETCH_GROUP_MAPPINGS_ERROR = "user-sync/group-mappings/FETCH_GROUP_MAPPINGS_ERROR";

export const FETCH_SUGGESTIONS_REQUEST = "user-sync/group-mappings/FETCH_SUGGESTIONS_REQUEST";
export const FETCH_SUGGESTIONS_ERROR = "user-sync/group-mappings/FETCH_SUGGESTIONS_ERROR";
export const SET_SUGGESTIONS = "user-sync/group-mappings/SET_SUGGESTIONS";

export const UPDATE_SUGGESTIONS_REQUEST = "user-sync/group-mappings/UPDATE_SUGGESTIONS_REQUEST";
export const UPDATE_SUGGESTIONS_ERROR = "user-sync/group-mappings/UPDATE_SUGGESTIONS_ERROR";
export const UPDATE_SUGGESTIONS_SUCCESS = "user-sync/group-mappings/UPDATE_SUGGESTIONS_SUCCESS";

export const SUBMIT_NEW_GROUP_MAPPING_REQUEST =
    "user-sync/group-mappings/SUBMIT_NEW_GROUP_MAPPING_REQUEST";
export const SUBMIT_NEW_GROUP_MAPPING_SUCCESS =
    "user-sync/group-mappings/SUBMIT_NEW_GROUP_MAPPING_SUCCESS";
export const SUBMIT_UPDATED_GROUP_MAPPING_REQUEST =
    "user-sync/group-mappings/SUBMIT_UPDATED_GROUP_MAPPING_REQUEST";
export const SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS =
    "user-sync/group-mappings/SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS";
export const SUBMIT_GROUP_MAPPING_ERROR = "user-sync/group-mappings/SUBMIT_GROUP_MAPPING_ERROR";

export const FETCH_GROUPS_REQUEST = "user-sync/group-mappings/FETCH_GROUPS_REQUEST";
export const FETCH_GROUPS_SUCCESS = "user-sync/group-mappings/FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_ERROR = "user-sync/group-mappings/FETCH_GROUPS_ERROR";

export const PREPARE_FORM_REQUEST = "user-sync/group-mappings/PREPARE_FORM_REQUEST";
export const PREPARE_FORM_SUCCESS = "user-sync/group-mappings/PREPARE_FORM_SUCCESS";
export const PREPARE_FORM_ERROR = "user-sync/group-mappings/PREPARE_FORM_ERROR";

interface FetchGroupMappingsRequestAction {
    type: typeof FETCH_GROUP_MAPPINGS_REQUEST;
    payload: string;
}

interface FetchGroupMappingsSuccessAction {
    type: typeof FETCH_GROUP_MAPPINGS_SUCCESS;
    payload: {
        groupMappings: Array<GroupMapping>;
        networkId: string;
    };
}

interface FetchGroupMappingsErrorAction {
    type: typeof FETCH_GROUP_MAPPINGS_ERROR;
}

interface SubmitNewGroupMappingRequestAction {
    type: typeof SUBMIT_NEW_GROUP_MAPPING_REQUEST;
    payload: GroupMappingRequestBody;
}

interface SubmitNewGroupMappingSuccessAction {
    type: typeof SUBMIT_NEW_GROUP_MAPPING_SUCCESS;
    payload: GroupMapping;
}

interface SubmitUpdatedGroupMappingRequestAction {
    type: typeof SUBMIT_UPDATED_GROUP_MAPPING_REQUEST;
    payload: { activeRevisionId: number; groupMapping: GroupMapping };
}

export interface SubmitUpdatedGroupMappingSuccessAction {
    type: typeof SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS;
    payload: GroupMapping;
}

interface SubmitGroupMappingErrorAction {
    type: typeof SUBMIT_GROUP_MAPPING_ERROR;
}

interface FetchGroupsRequestAction {
    type: typeof FETCH_GROUPS_REQUEST;
    payload: string;
}

interface FetchGroupsSuccessAction {
    type: typeof FETCH_GROUPS_SUCCESS;
    payload: {
        networkId: string;
        groups: Array<SpeakapGroup>;
    };
}

interface FetchGroupsErrorAction {
    type: typeof FETCH_GROUPS_ERROR;
}

interface FetchSuggestionsRequestAction {
    type: typeof FETCH_SUGGESTIONS_REQUEST;
    payload: {
        networkId: string;
        configName: string;
    };
}

interface FetchSuggestionsErrorAction {
    type: typeof FETCH_SUGGESTIONS_ERROR;
}

interface SetSuggestionsPayload {
    subjects: Array<ConditionOption>;
    objects?: {
        [subjectKey: string]: Array<ConditionOption>;
    };
}

interface SetSuggestions {
    type: typeof SET_SUGGESTIONS;
    payload: SetSuggestionsPayload;
}

interface UpdateSuggestionsRequestAction {
    type: typeof UPDATE_SUGGESTIONS_REQUEST;
}

interface UpdateSuggestionsErrorAction {
    type: typeof UPDATE_SUGGESTIONS_ERROR;
}

interface UpdateSuggestionsSuccessAction {
    type: typeof UPDATE_SUGGESTIONS_SUCCESS;
}

interface PrepareFormRequestAction {
    type: typeof PREPARE_FORM_REQUEST;
    payload: {
        networkId: string;
    };
}

interface PrepareFormErrorAction {
    type: typeof PREPARE_FORM_ERROR;
}

interface PrepareFormSuccessAction {
    type: typeof PREPARE_FORM_SUCCESS;
    payload: {
        networkId: string;
        scimUsers: Array<ScimUser>;
    };
}

export type GroupMappingsAction =
    | FetchGroupMappingsSuccessAction
    | FetchGroupMappingsRequestAction
    | FetchGroupMappingsErrorAction
    | SubmitNewGroupMappingRequestAction
    | SubmitNewGroupMappingSuccessAction
    | SubmitUpdatedGroupMappingRequestAction
    | SubmitUpdatedGroupMappingSuccessAction
    | SubmitGroupMappingErrorAction
    | FetchGroupsSuccessAction
    | FetchGroupsRequestAction
    | FetchGroupsErrorAction
    | FetchSuggestionsRequestAction
    | FetchSuggestionsErrorAction
    | SetSuggestions
    | UpdateSuggestionsRequestAction
    | UpdateSuggestionsErrorAction
    | UpdateSuggestionsSuccessAction
    | PrepareFormRequestAction
    | PrepareFormSuccessAction
    | PrepareFormErrorAction;

type Thunk<Result = void> = ThunkAction<
    Promise<Result>,
    ApplicationState,
    void,
    CallHistoryMethodAction | GroupMappingsAction
>;

export const fetchGroupMappings = (networkId: string): Thunk => async (dispatch, getState) => {
    const currentNetworkId = selectGroupMappingsNetworkId(getState());
    if (networkId !== currentNetworkId) {
        try {
            dispatch({ payload: networkId, type: FETCH_GROUP_MAPPINGS_REQUEST });

            const response = await getGroupMappings({ networkId });
            dispatch({
                payload: { groupMappings: response.mappings, networkId },
                type: FETCH_GROUP_MAPPINGS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                message: error instanceof Error ? error.message : "Error",
                type: FETCH_GROUP_MAPPINGS_ERROR,
            });
        }
    }
};

export const fetchGroups = (networkId: string, force = false): Thunk => async (
    dispatch,
    getState,
) => {
    const currentNetworkId = selectGroupsNetworkId(getState());
    if (networkId !== currentNetworkId || force) {
        try {
            dispatch({ payload: networkId, type: FETCH_GROUPS_REQUEST });

            const response = await getGroups({ networkId });
            dispatch({
                payload: { groups: response.groups, networkId },
                type: FETCH_GROUPS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                message: error instanceof Error ? error.message : "Error",
                type: FETCH_GROUPS_ERROR,
            });
        }
    }
};

export const submitNewGroupMapping = (
    groupMapping: GroupMappingRequestBody,
): Thunk<GroupMapping | undefined> => async dispatch => {
    try {
        dispatch({ payload: groupMapping, type: SUBMIT_NEW_GROUP_MAPPING_REQUEST });

        const response = await postNewGroupMapping(groupMapping);
        dispatch({ payload: response, type: SUBMIT_NEW_GROUP_MAPPING_SUCCESS });
        return response;
    } catch (error) {
        const errorMessage =
            get(error, ["response", "data", "message"]) ||
            get(error, ["response", "data"], error instanceof Error ? error.message : "Error");
        notification.error({ message: `Error creating group mapping: ${errorMessage}` });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: SUBMIT_GROUP_MAPPING_ERROR,
        });
    }
};

export const submitUpdatedGroupMapping = (
    activeRevisionId: number,
    groupMapping: GroupMapping,
): Thunk => async dispatch => {
    try {
        dispatch({
            payload: { activeRevisionId, groupMapping },
            type: SUBMIT_UPDATED_GROUP_MAPPING_REQUEST,
        });

        const response = await postUpdatedGroupMapping(activeRevisionId, groupMapping);
        dispatch({ payload: response, type: SUBMIT_UPDATED_GROUP_MAPPING_SUCCESS });

        notification.success({ message: "Successfully updated" });
        dispatch(goBack());
    } catch (error) {
        notification.error({
            message: get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            ),
        });
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: SUBMIT_GROUP_MAPPING_ERROR,
        });
    }
};

const setSuggestions = (payload: SetSuggestionsPayload): SetSuggestions => ({
    payload,
    type: SET_SUGGESTIONS,
});

export const updateSuggestions = (networkId: string, configName: string): Thunk => async (
    dispatch,
    getState,
) => {
    try {
        const state = getState();
        const loadingState = selectUpdateSuggestionsLoadingState(state);

        if (loadingState === LOADING_STATES.FETCHING) {
            return;
        }

        dispatch({ type: UPDATE_SUGGESTIONS_REQUEST });

        const config = selectConfigItems(state)[configName];
        if (!config) {
            throw new Error(`No config found with name [${configName}]`);
        }
        if (!config.connectorName) {
            throw new Error("Selected config has not connector");
        }

        const connector = selectConnectorsItems(state)[config.connectorName];
        if (!connector) {
            throw new Error(`No connector found with name [${config.connectorName}]`);
        }
        // If connector is manual upload, we cannot update
        // employee data "behind the scenes", because users have
        // to actively select an upload file.
        if (connector.type === ConnectorType.ManualUpload) {
            throw new Error(
                `Cannot update suggestions from connector of type ${ConnectorType.ManualUpload}`,
            );
        }

        await dispatch(submitRuns({ connector, networkId }));
        // await dispatch(fetchSuggestions(networkId, configName));

        dispatch({ type: UPDATE_SUGGESTIONS_SUCCESS });
    } catch (error) {
        console.error(error);
        dispatch({ type: UPDATE_SUGGESTIONS_ERROR });
    }
};

const prepareConnectors = (connectorName: string): Thunk => async (dispatch, getState) => {
    const connectors = selectConnectorsItems(getState());
    const connector = connectors[connectorName];
    if (!connector) {
        throw new Error(`Cannot find connector with name [${connectorName}]`);
    }
    await dispatch(fetchImportUsers(connector));
};

const getSubjectKey = (selector: Array<string>): string =>
    without(selector.slice(1), SPEAKAP_PARAM_BAG_SCHEMA, SCIM_ENTERPRISE_USER_SCHEMA).join("__");

const getSelectedConfig = (state: ApplicationState, configName: string): EnhancedConfig => {
    const configs = selectConfigItems(state);
    const config = configs[configName];
    if (!config) {
        throw new Error(`Cannot find config with name [${configName}]`);
    }
    const editConfig = selectEditItem(state);
    if (editConfig?.config.name === configName) {
        return editConfig.config;
    }

    return config;
};

export const prepareGroupMappingsForm = (
    selectedConfig: string,
    loadGroups = true,
): Thunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const config = getSelectedConfig(state, selectedConfig);
        dispatch({ payload: { networkId: config.networkId }, type: PREPARE_FORM_REQUEST });
        const connectorName = config.connectorName;
        if (!connectorName) {
            throw new Error("Select configuration has not connector attached to it.");
        }

        const resourcesToPrepare = [dispatch(prepareConnectors(connectorName))];

        if (loadGroups) {
            resourcesToPrepare.push(dispatch(fetchGroups(config.networkId)));
        }

        if (config.columnMappingName) {
            resourcesToPrepare.push(dispatch(fetchColumnMappings(config.networkId)));
        }

        await Promise.all(resourcesToPrepare);

        const updatedState = getState();
        const importUsers = selectImportUsersItems(updatedState);

        if (config.columnMappingName) {
            const columnMapping = selectColumnMappingsItems(updatedState)[config.columnMappingName];
            if (!columnMapping) {
                throw new Error(`No column mapping found with name [${config.columnMappingName}]`);
            }

            const expressionHelpers = await dispatch(fetchExpressionHelpers());
            const scimUsers = importUsers.map(user =>
                mapColumns(
                    flattenScimUser(user) as Record<string, string>,
                    columnMapping.template,
                    expressionHelpers,
                ),
            );

            dispatch({
                payload: { networkId: config.networkId, scimUsers: scimUsers as Array<ScimUser> },
                type: PREPARE_FORM_SUCCESS,
            });

            const fields = utils.options.filter(({ selector }) => {
                const value = get(columnMapping, selector);
                return value && !isArray(value);
            });
            const paramBag: Array<ParamBagItem> = get(
                columnMapping,
                ["template", SPEAKAP_PARAM_BAG_SCHEMA],
                [],
            );
            const subjects = sortBy(
                [
                    ...fields.map(field => ({
                        label: field.label,
                        value: getSubjectKey(field.selector),
                    })),
                    ...paramBag.map(({ key }) => ({
                        label: key,
                        value: key,
                    })),
                ],
                subject => subject.label.toLowerCase(),
            );
            const objects = fromPairs([
                ...fields
                    // We need to remove arrays from objects because the do not have a literal value.
                    // To work with arrays, we have to use expert mode and use some or every helpers.
                    .filter(({ selector }) => !isArray(get(columnMapping, selector)))
                    .map(field => {
                        const values = uniq(
                            compact(scimUsers.map(user => get(user, field.selector.slice(1)))),
                        ).map((value: string) => ({
                            label: value,
                            value: `"${value}"`,
                        }));

                        return [getSubjectKey(field.selector), sortBy(values, "label")];
                    }),
                ...paramBag.map(({ key }) => {
                    const values = uniq(
                        compact(scimUsers.map(user => get(user, [SPEAKAP_PARAM_BAG_SCHEMA, key]))),
                    ).map((value: string) => ({
                        label: value,
                        value: `"${value}"`,
                    }));

                    return [key, sortBy(values, "label")];
                }),
            ]);

            dispatch(setSuggestions({ objects, subjects }));
        } else {
            const scimUsers = ([...importUsers] as unknown) as Array<ScimUser>;
            dispatch({
                payload: { networkId: config.networkId, scimUsers },
                type: PREPARE_FORM_SUCCESS,
            });
        }
    } catch (error) {
        dispatch({ type: PREPARE_FORM_ERROR });
    }
};
