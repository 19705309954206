import { ConfigRequestBody, EnhancedConfig, Config } from "@speakap/types";

import axios from "./request";

interface GetConfigsParams {
    networkId: string;
}

interface GetConfigsResponse {
    configs: Array<EnhancedConfig>;
}

export const getConfigs = (params: GetConfigsParams): Promise<GetConfigsResponse> =>
    axios.get(`/networks/${params.networkId}/configs/`).then(({ data }) => data);

export const postNewConfig = (config: ConfigRequestBody): Promise<EnhancedConfig> =>
    axios.post(`/networks/${config.networkId}/configs/`, config).then(({ data }) => data);

export const postUpdatedConfig = (
    activeRevisionId: number,
    config: ConfigRequestBody,
): Promise<EnhancedConfig> =>
    axios
        .post(
            `/networks/${config.networkId}/configs/?active_revision_id=${activeRevisionId}`,
            config,
        )
        .then(({ data }) => data);

export const syncUsers = (
    config: Config,
    dryRunToken: string,
    skipMismatch = false,
): Promise<void> =>
    axios.post(
        `/networks/${config.networkId}/configs/${
            config.name
        }/sync-requests/${dryRunToken}?skip_mismatch=${skipMismatch.toString()}`,
        {
            config,
        },
    );

export const getConfigsByName = (networkId: string, name: string): Promise<Array<Config>> =>
    axios.get(`/networks/${networkId}/configs/name/${name}`).then(({ data }) => data);
