import { RoleMapping, RoleMappingRequestBody } from "@speakap/types";

import axios from "./request";

interface GetRoleMappingsParams {
    networkId: string;
}

interface GetRoleMappingsResponse {
    mappings: Array<RoleMapping>;
}

export const getRoleMappings = (params: GetRoleMappingsParams): Promise<GetRoleMappingsResponse> =>
    axios.get(`/networks/${params.networkId}/role-mappings/`).then(({ data }) => data);

export const postNewRoleMapping = (roleMapping: RoleMappingRequestBody): Promise<RoleMapping> =>
    axios
        .post(`/networks/${roleMapping.networkId}/role-mappings/`, roleMapping)
        .then(({ data }) => data);

export const postUpdatedRoleMapping = (
    activeRevisionId: number,
    roleMapping: RoleMapping,
): Promise<RoleMapping> =>
    axios
        .post(
            `/networks/${roleMapping.networkId}/role-mappings/?active_revision_id=${activeRevisionId}`,
            roleMapping,
        )
        .then(({ data }) => data);

export const getRoleMappingsByName = (
    networkId: string,
    name: string,
): Promise<Array<RoleMapping>> =>
    axios.get(`/networks/${networkId}/role-mappings/name/${name}`).then(({ data }) => data);
