import { UserSyncUser } from "@speakap/types";
import produce from "immer";

import {
    AuthActions,
    FETCH_USERSYNC_USER_ERROR,
    FETCH_USERSYNC_USER_REQUEST,
    FETCH_USERSYNC_USER_SUCCESS,
    FETCH_GOOGLE_URL_ERROR,
    FETCH_GOOGLE_URL_REQUEST,
    FETCH_GOOGLE_URL_SUCCESS,
    LOGOUT_USERSYNC_USER_ERROR,
    LOGOUT_USERSYNC_USER_REQUEST,
    LOGOUT_USERSYNC_USER_SUCCESS,
    LOGIN_SPEAKAP_USER_ERROR,
    LOGIN_SPEAKAP_USER_REQUEST,
    LOGIN_SPEAKAP_USER_SUCCESS,
} from "./actions";
import { LOADING_STATES } from "../../types";

export interface AuthState {
    user?: UserSyncUser;
    userLoadingState: LOADING_STATES;
    logoutLoadingState: LOADING_STATES;
    googleLoadingState: LOADING_STATES;
    loginLoadingState: LOADING_STATES;
}

export const initialState = {
    googleLoadingState: LOADING_STATES.INITIAL,
    loginLoadingState: LOADING_STATES.INITIAL,
    logoutLoadingState: LOADING_STATES.INITIAL,
    user: undefined,
    userLoadingState: LOADING_STATES.INITIAL,
};

const networksReducer = (state: AuthState = initialState, action: AuthActions): AuthState =>
    produce(state, (draft: AuthState) => {
        switch (action.type) {
            case FETCH_USERSYNC_USER_REQUEST:
                draft.userLoadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_USERSYNC_USER_SUCCESS:
                draft.userLoadingState = LOADING_STATES.SUCCESSFUL;
                draft.user = action.payload;
                break;
            case FETCH_USERSYNC_USER_ERROR:
                draft.userLoadingState = LOADING_STATES.ERROR;
                break;
            case LOGOUT_USERSYNC_USER_REQUEST:
                draft.logoutLoadingState = LOADING_STATES.FETCHING;
                break;
            case LOGOUT_USERSYNC_USER_SUCCESS:
                draft.logoutLoadingState = LOADING_STATES.SUCCESSFUL;
                draft.user = undefined;
                break;
            case LOGOUT_USERSYNC_USER_ERROR:
                draft.logoutLoadingState = LOADING_STATES.ERROR;
                break;
            case FETCH_GOOGLE_URL_REQUEST:
                draft.googleLoadingState = LOADING_STATES.FETCHING;
                break;
            case FETCH_GOOGLE_URL_SUCCESS:
                draft.googleLoadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case FETCH_GOOGLE_URL_ERROR:
                draft.googleLoadingState = LOADING_STATES.ERROR;
                break;
            case LOGIN_SPEAKAP_USER_REQUEST:
                draft.loginLoadingState = LOADING_STATES.FETCHING;
                break;
            case LOGIN_SPEAKAP_USER_SUCCESS:
                draft.loginLoadingState = LOADING_STATES.SUCCESSFUL;
                break;
            case LOGIN_SPEAKAP_USER_ERROR:
                draft.loginLoadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default networksReducer;
