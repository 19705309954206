import { Config } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";

import { ApplicationState } from "../reducer";
import { deleteResourceByName, DeleteResourceType } from "../api";

export const DELETE_RESOURCE_REQUEST = "user-sync/resources/DELETE_RESOURCE_REQUEST";
export const DELETE_RESOURCE_SUCCESS = "user-sync/resources/DELETE_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_ERROR = "user-sync/resources/DELETE_RESOURCE_ERROR";

interface DeleteConnectorRequestAction {
    type: typeof DELETE_RESOURCE_REQUEST;
    payload: {
        resourceName: string;
        networkId: string;
        resourceType: DeleteResourceType;
    };
}

interface DeleteConnectorSuccessAction {
    type: typeof DELETE_RESOURCE_SUCCESS;
    payload: {
        resourceName: string;
        networkId: string;
        resourceType: DeleteResourceType;
    };
}

interface DeleteConnectorErrorAction {
    type: typeof DELETE_RESOURCE_ERROR;
    payload: Array<Config>;
}

export type DeleteResourcesAction =
    | DeleteConnectorRequestAction
    | DeleteConnectorSuccessAction
    | DeleteConnectorErrorAction;

type Thunk<T> = ThunkAction<Promise<T>, ApplicationState, void, DeleteResourcesAction>;

export const deleteResource = (
    networkId: string,
    resourceType: DeleteResourceType,
    resourceName: string,
): Thunk<boolean> => async dispatch => {
    try {
        const payload = { networkId, resourceName, resourceType };
        dispatch({
            payload,
            type: DELETE_RESOURCE_REQUEST,
        });

        await deleteResourceByName(networkId, resourceType, resourceName);
        dispatch({
            payload,
            type: DELETE_RESOURCE_SUCCESS,
        });
        return true;
    } catch (error) {
        dispatch({
            payload: get(error, ["response", "data", "configs"]) as Array<Config>,
            type: DELETE_RESOURCE_ERROR,
        });
        return false;
    }
};
