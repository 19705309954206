import { SyncedUser } from "@speakap/types";

import { LOADING_STATES } from "../../types";
import {
    SyncedUsersActions,
    FETCH_SYNCED_USERS_ERROR,
    FETCH_SYNCED_USERS_REQUEST,
    FETCH_SYNCED_USERS_SUCCESS,
    FETCH_SYNCED_USERS_BATCH,
} from "./actions";

export interface SyncedUsersState {
    loadingState: LOADING_STATES;
    isLoadingMore: boolean;
    networkId: string;
    syncedUsers: Array<SyncedUser>;
}

export const initialState: SyncedUsersState = {
    isLoadingMore: false,
    loadingState: LOADING_STATES.INITIAL,
    networkId: "",
    syncedUsers: [],
};

const syncedUsersReducer = (
    state: SyncedUsersState = initialState,
    action: SyncedUsersActions,
): SyncedUsersState => {
    switch (action.type) {
        case FETCH_SYNCED_USERS_REQUEST:
            return {
                ...initialState,
                loadingState: LOADING_STATES.FETCHING,
                networkId: action.payload,
            };
        case FETCH_SYNCED_USERS_BATCH:
            return {
                ...state,
                isLoadingMore: true,
                loadingState: LOADING_STATES.SUCCESSFUL,
                syncedUsers: [...state.syncedUsers, ...action.payload],
            };
        case FETCH_SYNCED_USERS_SUCCESS:
            return {
                ...state,
                isLoadingMore: false,
                loadingState: LOADING_STATES.SUCCESSFUL,
            };
        case FETCH_SYNCED_USERS_ERROR:
            return {
                ...state,
                isLoadingMore: false,
                loadingState: LOADING_STATES.ERROR,
                syncedUsers: [],
            };
        default:
            return state;
    }
};

export default syncedUsersReducer;
