import { GroupMapping, GroupMappingRequestBody, ScimUser, GroupMappingRule } from "@speakap/types";

import axios from "./request";

interface GetGroupMappingsParams {
    networkId: string;
}

interface GetGroupMappingsResponse {
    mappings: Array<GroupMapping>;
}

export const getGroupMappings = (
    params: GetGroupMappingsParams,
): Promise<GetGroupMappingsResponse> =>
    axios.get(`/networks/${params.networkId}/group-mappings/`).then(({ data }) => data);

export const postNewGroupMapping = (groupMapping: GroupMappingRequestBody): Promise<GroupMapping> =>
    axios
        .post(`/networks/${groupMapping.networkId}/group-mappings/`, groupMapping)
        .then(({ data }) => data);

export const postUpdatedGroupMapping = (
    activeRevisionId: number,
    groupMapping: GroupMapping,
): Promise<GroupMapping> =>
    axios
        .post(
            `/networks/${groupMapping.networkId}/group-mappings/?active_revision_id=${activeRevisionId}`,
            groupMapping,
        )
        .then(({ data }) => data);

export const getGroupMappingRules = (
    groupMapping: GroupMapping,
    users: Array<ScimUser>,
): Promise<Array<Array<GroupMappingRule>>> =>
    axios
        .post(`/networks/${groupMapping.networkId}/group-mappings/${groupMapping.id}/rules`, {
            users,
        })
        .then(({ data }) => data.rules);

export const getGroupMappingsByName = (
    networkId: string,
    name: string,
): Promise<Array<GroupMapping>> =>
    axios.get(`/networks/${networkId}/group-mappings/name/${name}`).then(({ data }) => data);
