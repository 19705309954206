import { ColumnMappingTemplate, SPEAKAP_PARAM_BAG_SCHEMA } from "@speakap/types";
import React, { useEffect } from "react";
import { get, set, compact, uniq } from "lodash";
import { mapColumns, flattenScimUser } from "@speakap/expression-engine";
import { useSelector, useDispatch } from "react-redux";

import { LOADING_STATES } from "../../../types";
import { fetchExpressionHelpers } from "../../../modules/expression-helpers/actions";
import { prepareColumnMappingExamples } from "../../../modules/column-mappings/actions";
import { selectApplyColumnMappingUsers } from "../../../modules/column-mappings/selectors";
import { selectConfigName } from "../../../modules/configs/selectors";
import {
    selectExpressionHelpersItems,
    selectExpressionHelpersLoadingState,
} from "../../../modules/expression-helpers/selectors";
import styles from "./column-mappings-form.module.css";

interface FieldExampleProps {
    selector: Array<string>;
    value?: string;
}

const FieldExample = ({ selector, value }: FieldExampleProps): JSX.Element | null => {
    const dispatch = useDispatch();
    const configName = useSelector(selectConfigName);
    const importUsers = useSelector(selectApplyColumnMappingUsers);
    const loadingState = useSelector(selectExpressionHelpersLoadingState);
    const expressionHelpers = useSelector(selectExpressionHelpersItems);
    useEffect(() => {
        if (configName) {
            dispatch(prepareColumnMappingExamples(configName));
        }
    }, [configName]);
    useEffect(() => {
        dispatch(fetchExpressionHelpers());
    }, []);
    if (
        selector[0] === SPEAKAP_PARAM_BAG_SCHEMA ||
        loadingState === LOADING_STATES.FETCHING ||
        loadingState === LOADING_STATES.INITIAL
    ) {
        return null;
    }
    const template: ColumnMappingTemplate = set(
        {
            externalId: "",
            userName: "",
        },
        selector,
        value,
    );
    try {
        const scimUsers = importUsers.map(user =>
            mapColumns(
                flattenScimUser(user) as Record<string, string>,
                template,
                expressionHelpers,
            ),
        );
        const examples = uniq(compact(scimUsers.map(user => get(user, selector)))).join(", ");

        return <div className={styles.applyColumnMapping}>{examples}</div>;
    } catch (error) {
        return (
            <div className={styles.applyColumnMappingError}>
                {error instanceof Error ? error.message : "Error"}
            </div>
        );
    }
};

export default FieldExample;
