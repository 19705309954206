import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { routerMiddleware } from "connected-react-router";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import history from "./history";
import reducer from "./reducer";

const persistConfig = {
    key: "networksRecent",
    storage,
    whitelist: ["networksRecent"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk)),
);
const persistor = persistStore(store);

if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducer", () => store.replaceReducer(persistedReducer));
}

export { persistor, store };
