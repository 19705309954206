import { Config, DryRunsResponse, ColumnMapping, GroupMapping } from "@speakap/types";

import axios from "./request";

interface GetDryRunsParams {
    columnMapping?: ColumnMapping;
    config: Config;
    groupMapping?: GroupMapping;
}

export const getDryRuns = (params: GetDryRunsParams): Promise<DryRunsResponse> =>
    axios.post(`/networks/${params.config.networkId}/dry-runs`, params).then(({ data }) => data);
