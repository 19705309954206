import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { SyncProtocolState } from "./reducer";

const selectSyncProtocolState = (state: ApplicationState): SyncProtocolState => state.syncProtocols;

export const selectSyncProtocolLoadingState = createSelector(
    selectSyncProtocolState,
    ({ loadingState }) => loadingState,
);
export const selectSyncProtocols = createSelector(
    selectSyncProtocolState,
    ({ protocols }) => protocols,
);
export const selectSyncProtocolsHasMore = createSelector(
    selectSyncProtocolState,
    ({ hasMore }) => hasMore,
);
export const selectSyncProtocolsOffset = createSelector(
    selectSyncProtocolState,
    ({ offset }) => offset,
);
