import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { ConnectorsState } from "./reducer";

const selectConnectors = (state: ApplicationState): ConnectorsState => state.connectors;

export const selectConnectorsItems = createSelector(selectConnectors, ({ items }) => items);

const selectConnectorsResults = createSelector(selectConnectors, ({ results }) => results);

export const selectConnectorsList = createSelector(
    [selectConnectorsItems, selectConnectorsResults],
    (items, results) => results.map(id => items[id]),
);

export const selectConnectorsLoadingState = createSelector(
    selectConnectors,
    ({ loadingState }) => loadingState,
);

const selectEdit = createSelector(selectConnectors, ({ edit }) => edit);

export const selectEditLoadingState = createSelector(
    selectEdit,
    ({ loadingState }) => loadingState,
);

const selectImportUsers = createSelector(selectConnectors, ({ importUsers }) => importUsers);

export const selectImportUsersLoadingState = createSelector(
    selectImportUsers,
    ({ loadingState }) => loadingState,
);

export const selectImportUsersItems = createSelector(selectImportUsers, ({ items }) => items);

export const selectImportUsersConnectorId = createSelector(
    selectImportUsers,
    ({ connectorId }) => connectorId,
);

export const selectConnectorsNetworkId = createSelector(
    selectConnectors,
    ({ networkId }) => networkId,
);

const selectConnectorStats = createSelector(selectConnectors, ({ stats }) => stats);

export const selectConnectorStatsLoadingState = createSelector(
    selectConnectorStats,
    ({ loadingState }) => loadingState,
);

export const selectConnectorStatsValue = createSelector(selectConnectorStats, ({ value }) => value);
