import { Network } from "@speakap/types";
import produce from "immer";

import { LOADING_STATES } from "../../types";
import {
    NetworksActions,
    FETCH_NETWORKS_ERROR,
    FETCH_NETWORKS_SUCCESS,
    FETCH_NETWORK_ROLES_ERROR,
    FETCH_NETWORK_ROLES_REQUEST,
    FETCH_NETWORK_ROLES_SUCCESS,
    FETCH_NETWORKS_PARTIAL_ERROR,
    FETCH_NETWORKS_PARTIAL_SUCCESS,
    SET_ACTIVE_NETWORK,
} from "./actions";

export interface NetworksState {
    activeNetworkId: string;
    activeNetworkName: string;
    activeNetworkSubdomain: string;
    loadingState: LOADING_STATES;
    items: Array<Network>;
    roles: {
        loadingState: LOADING_STATES;
        items: Array<{ id: string; name: string }>;
    };
    limit: number;
    offset: number;
    totalItems: number;
    isLoadingMore: boolean;
}

export const initialState: NetworksState = {
    activeNetworkId: "",
    activeNetworkName: "",
    activeNetworkSubdomain: "",
    isLoadingMore: false,
    items: [],
    limit: 0,
    loadingState: LOADING_STATES.INITIAL,
    offset: 0,
    roles: {
        items: [],
        loadingState: LOADING_STATES.INITIAL,
    },
    totalItems: 0,
};

const networksReducer = (
    state: NetworksState = initialState,
    action: NetworksActions,
): NetworksState =>
    produce(state, (draft: NetworksState) => {
        switch (action.type) {
            case FETCH_NETWORKS_SUCCESS:
                draft.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.items = action.payload;
                draft.limit = action.limit;
                draft.offset = action.offset;
                draft.totalItems = action.totalItems;
                draft.isLoadingMore = false;
                break;
            case FETCH_NETWORKS_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                draft.isLoadingMore = false;
                break;
            case FETCH_NETWORKS_PARTIAL_SUCCESS:
                draft.loadingState = LOADING_STATES.FETCHING;
                draft.items = action.payload;
                draft.limit = action.limit;
                draft.offset = action.offset;
                draft.totalItems = action.totalItems;
                draft.isLoadingMore = true;
                break;
            case FETCH_NETWORKS_PARTIAL_ERROR:
                draft.loadingState = LOADING_STATES.ERROR;
                draft.isLoadingMore = false;
                break;
            case SET_ACTIVE_NETWORK:
                draft.activeNetworkId = action.payload.networkId;
                draft.activeNetworkName = action.payload.networkName;
                draft.activeNetworkSubdomain = action.payload.networkSubdomain;
                break;
            case FETCH_NETWORK_ROLES_REQUEST:
                draft.roles.loadingState = LOADING_STATES.INITIAL;
                break;
            case FETCH_NETWORK_ROLES_SUCCESS:
                draft.roles.loadingState = LOADING_STATES.SUCCESSFUL;
                draft.roles.items = action.payload;
                break;
            case FETCH_NETWORK_ROLES_ERROR:
                draft.roles.loadingState = LOADING_STATES.ERROR;
                break;
        }
    });

export default networksReducer;
