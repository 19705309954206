import { SyncProtocol } from "@speakap/types";

import { LOADING_STATES } from "../../types";
import {
    SyncProtocolActions,
    FETCH_SYNC_PROTOCOLS_ERROR,
    FETCH_SYNC_PROTOCOLS_REQUEST,
    FETCH_SYNC_PROTOCOLS_SUCCESS,
} from "./actions";

export interface SyncProtocolState {
    loadingState: LOADING_STATES;
    protocols: Array<SyncProtocol>;
    offset: number;
    hasMore: boolean;
}

export const initialState: SyncProtocolState = {
    hasMore: false,
    loadingState: LOADING_STATES.INITIAL,
    offset: 0,
    protocols: [],
};

const syncProtocolsReducer = (
    state: SyncProtocolState = initialState,
    action: SyncProtocolActions,
): SyncProtocolState => {
    switch (action.type) {
        case FETCH_SYNC_PROTOCOLS_REQUEST:
            return {
                hasMore: false,
                loadingState: LOADING_STATES.FETCHING,
                offset: 0,
                protocols: [],
            };
        case FETCH_SYNC_PROTOCOLS_SUCCESS:
            return {
                hasMore: action.payload.hasMore,
                loadingState: LOADING_STATES.SUCCESSFUL,
                offset: state.offset + action.payload.limit,
                protocols: [...state.protocols, ...action.payload.protocols],
            };
        case FETCH_SYNC_PROTOCOLS_ERROR:
            return {
                hasMore: false,
                loadingState: LOADING_STATES.ERROR,
                offset: initialState.offset,
                protocols: state.protocols,
            };
        default:
            return state;
    }
};
export default syncProtocolsReducer;
