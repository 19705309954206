import { pathToRegexp } from "path-to-regexp";

export interface Route {
    children?: Record<RouteKey, RouteWithRegexp>;
    label: (...args: Array<string>) => string;
    pathname: string;
    url: (...args: Array<string>) => string;
}

export interface RouteWithRegexp extends Route {
    regexp: RegExp;
}

const addRegexp = (route: Route): RouteWithRegexp => ({
    ...route,
    regexp: pathToRegexp(route.pathname, [], { end: false }),
});

const columnMappingItem = addRegexp({
    label: (networkId: string, configName: string, columnMappingName: string) => columnMappingName,
    pathname: "/networks/:networkId/configs/:configName/column-mappings/:columnMappingName",
    url: (networkId: string, configName: string, columnMappingName: string) =>
        `/networks/${networkId}/configs/${configName}/column-mappings/${columnMappingName}`,
});

const connectorItem = addRegexp({
    label: (networkId: string, configName: string, connectorName: string) => connectorName,
    pathname: "/networks/:networkId/configs/:configName/connectors/:connectorName",
    url: (networkId: string, configName: string, connectorName: string) =>
        `/networks/${networkId}/configs/${configName}/connectors/${connectorName}`,
});

const groupMappingItem = addRegexp({
    label: (networkId: string, configName: string, groupMappingName: string) => groupMappingName,
    pathname: "/networks/:networkId/configs/:configName/group-mappings/:groupMappingName",
    url: (networkId: string, configName: string, groupMappingName: string) =>
        `/networks/${networkId}/configs/${configName}/group-mappings/${groupMappingName}`,
});

const roleMappingItem = addRegexp({
    label: (networkId: string, configName: string, roleMappingName: string) => roleMappingName,
    pathname: "/networks/:networkId/configs/:configName/role-mappings/:roleMappingName",
    url: (networkId: string, configName: string, roleMappingName: string) =>
        `/networks/${networkId}/configs/${configName}/role-mappings/${roleMappingName}`,
});

const invitationMessages = addRegexp({
    label: () => "Invitation messages",
    pathname: "/networks/:networkId/configs/:configName/invitation-messages",
    url: (networkId: string, configName: string) =>
        `/networks/${networkId}/configs/${configName}/invitation-messages`,
});

const newColumnMappingItem = addRegexp({
    label: () => "New column mapping",
    pathname: "/networks/:networkId/configs/:configName/new-column-mapping",
    url: (networkId: string, configName: string) =>
        `/networks/${networkId}/configs/${configName}/new-column-mapping`,
});

const newConnectorItem = addRegexp({
    label: () => "New connector",
    pathname: "/networks/:networkId/configs/:configName/new-connector",
    url: (networkId: string, configName: string) =>
        `/networks/${networkId}/configs/${configName}/new-connector`,
});

const newGroupMappingItem = addRegexp({
    label: () => "New group mapping",
    pathname: "/networks/:networkId/configs/:configName/new-group-mapping",
    url: (networkId: string, configName: string) =>
        `/networks/${networkId}/configs/${configName}/new-group-mapping`,
});

const newRoleMappingItem = addRegexp({
    label: () => "New role mapping",
    pathname: "/networks/:networkId/configs/:configName/new-role-mapping",
    url: (networkId: string, configName: string) =>
        `/networks/${networkId}/configs/${configName}/new-role-mapping`,
});

const configItem = addRegexp({
    children: {
        columnMappingItem,
        connectorItem,
        groupMappingItem,
        newColumnMappingItem,
        newConnectorItem,
        newGroupMappingItem,
        newRoleMappingItem,
        roleMappingItem,
    } as Record<RouteKey, RouteWithRegexp>,
    label: (networkId: string, configName: string) => configName,
    pathname: "/networks/:networkId/configs/:configName",
    url: (networkId: string, configName: string) => `/networks/${networkId}/configs/${configName}`,
});

const configOrganizationalOverview = addRegexp({
    label: () => "Organizational overview",
    pathname: "/organizational-overviews/:pathId",
    url: (pathId: string) => `/organizational-overviews/${pathId}`,
});

const configs = addRegexp({
    children: { configItem } as Record<RouteKey, RouteWithRegexp>,
    label: () => "Configs",
    pathname: "/networks/:networkId/configs",
    url: (networkId: string): string => `/networks/${networkId}/configs`,
});

const schedule = addRegexp({
    label: () => "Schedule",
    pathname: "/networks/:networkId/configs/:configName/schedule",
    url: (networkId: string, configName: string) =>
        `/networks/${networkId}/configs/${configName}/schedule`,
});

const syncProtocols = addRegexp({
    label: () => "Sync logs",
    pathname: "/networks/:networkId/sync-protocols",
    url: (networkId: string): string => `/networks/${networkId}/sync-protocols`,
});

const syncedUsers = addRegexp({
    label: () => "Synced users",
    pathname: "/networks/:networkId/synced-users",
    url: (networkId: string): string => `/networks/${networkId}/synced-users`,
});

const notifications = addRegexp({
    label: () => "Notifications",
    pathname: "/networks/:networkId/notifications",
    url: (networkId: string): string => `/networks/${networkId}/notifications`,
});

const accessManagement = addRegexp({
    label: () => "Access management",
    pathname: "/networks/:networkId/access-management",
    url: (networkId: string): string => `/networks/${networkId}/access-management`,
});

const routes = {
    accessManagement,
    columnMappingItem,
    configItem,
    configOrganizationalOverview,
    configs,
    connectorItem,
    groupMappingItem,
    invitationMessages,
    newColumnMappingItem,
    newConnectorItem,
    newGroupMappingItem,
    newRoleMappingItem,
    notifications,
    roleMappingItem,
    schedule,
    syncProtocols,
    syncedUsers,
};

export type RouteKey = keyof typeof routes;

export default routes;
