import { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import { get } from "lodash";

import { FieldOption, FieldComponentProps } from "./column-mappings-utils";
import { createInputField } from "./column-mappings-form-input";
import { createRadioButton } from "./column-mappings-form-radio";
import styles from "./column-mappings-form.module.css";

interface ArrayFieldOptions {
    selectPrimary?: boolean;
}

export function createArrayField<T>(fields: Array<FieldOption>, options: ArrayFieldOptions = {}) {
    return function(field: FieldOption) {
        return function ArrayFieldComponent(props: FieldComponentProps) {
            const { form, columnMapping, removeButton, modalButton, setDirty } = props;
            const { label, selector, key } = field;

            const [items, setItems] = useState(get(columnMapping, selector, []));
            const { getFieldValue, setFieldsValue } = form;

            const addItem = (): void => {
                setItems([...items, {}]);
                setDirty(true);
            };
            const removeItem = (itemIndex: number): void => {
                const newItems = items.filter((item: T, index: number) => itemIndex !== index);
                const formItems = getFieldValue(key);
                setFieldsValue({
                    [key]: formItems.filter((item: T, index: number) => itemIndex !== index),
                });
                setItems(newItems);
                setDirty(true);
            };

            return (
                <Row align="top" gutter={{ lg: 12 }}>
                    <Col lg={2} className={styles.arrayLabel}>
                        {label}
                    </Col>
                    <Col lg={22}>
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Button onClick={addItem}>Add</Button>
                            </Col>
                            <Col>{removeButton}</Col>
                        </Row>
                        <>
                            {items.map((item: T, index: number) => (
                                <div key={index} className={styles.arrayWrapper}>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<CloseOutlined />}
                                        onClick={() => removeItem(index)}
                                    />
                                    {fields.map(
                                        ({
                                            key: fieldKey,
                                            selector: fieldSelector,
                                            ...restField
                                        }) =>
                                            createInputField({
                                                ...restField,
                                                key: [key, index, fieldKey],
                                                selector: [
                                                    ...selector,
                                                    `${index}`,
                                                    ...fieldSelector,
                                                ],
                                            })({
                                                columnMapping,
                                                form,
                                                modalButton,
                                                setDirty,
                                            }),
                                    )}
                                    {options.selectPrimary &&
                                        createRadioButton({
                                            key: [key, index, "primary"],
                                            label: "Primary",
                                            selector: [...selector, `${index}`, "primary"],
                                            value: index,
                                        })({
                                            columnMapping,
                                            form,
                                            setDirty,
                                        })}
                                </div>
                            ))}
                        </>
                    </Col>
                </Row>
            );
        };
    };
}
