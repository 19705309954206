import { getColumnMappings } from "./column-mappings";
import { getConfigsByName } from "./configs";
import { getConnectorsByName } from "./connectors";
import { getGroupMappingsByName } from "./group-mappings";
import { getRoleMappingsByName } from "./role-mappings";

export interface BaseResource {
    id: number;
    name: string;
    createdAt: string;
    createdBy: number;
}

export const getColumnMappingsRevisions = (
    networkId: string,
    columnMappingsName: string,
): (() => Promise<Array<BaseResource>>) => () =>
    getColumnMappings({ name: columnMappingsName, networkId }).then(({ mappings }) => mappings);

export const getGroupMappingsRevisions = (
    networkId: string,
    groupMappingsName: string,
): (() => Promise<Array<BaseResource>>) => () =>
    getGroupMappingsByName(networkId, groupMappingsName);

export const getRoleMappingsRevisions = (
    networkId: string,
    roleMappingsName: string,
): (() => Promise<Array<BaseResource>>) => () => getRoleMappingsByName(networkId, roleMappingsName);

export const getConnectorsRevisions = (
    networkId: string,
    connectorsName: string,
): (() => Promise<Array<BaseResource>>) => () => getConnectorsByName(networkId, connectorsName);

export const getConfigsRevisions = (
    networkId: string,
    configsName: string,
): (() => Promise<Array<BaseResource>>) => () => getConfigsByName(networkId, configsName);
