import { combineReducers, Action } from "redux";
import { connectRouter, RouterState } from "connected-react-router";

import { SET_ACTIVE_NETWORK } from "./networks/actions";
import accessManagement, { AccessManagementState } from "./access-management/reducer";
import auth, { AuthState } from "./auth/reducer";
import columnMappings, { ColumnMappingsState } from "./column-mappings/reducer";
import comments, { CommentsState } from "./comments/reducer";
import configs, { ConfigsState } from "./configs/reducer";
import connectors, { ConnectorsState } from "./connectors/reducer";
import deleteResources, { DeleteResourcesState } from "./delete-resources/reducer";
import dryRuns, { DryRunsState } from "./dry-runs/reducer";
import expressionHelpers, { ExpressionHelpersState } from "./expression-helpers/reducer";
import groupMappings, { GroupMappingsState } from "./group-mappings/reducer";
import history from "./history";
import networks, { NetworksState } from "./networks/reducer";
import networksRecent, { NetworksRecentState } from "./networks-recent/reducer";
import notifications, { NotificationsState } from "./notifications/reducer";
import organizationalOverview, {
    OrganizationalOverviewState,
} from "./organizational-overviews/reducer";
import resyncRequests, { ResyncRequestsState } from "./resync-requests/reducer";
import revisions, { RevisionsState } from "./revisions/reducer";
import roleMappings, { RoleMappingsState } from "./role-mappings/reducer";
import runs, { RunsState } from "./runs/reducer";
import syncProtocols, { SyncProtocolState } from "./sync-protocols/reducer";
import syncedUsers, { SyncedUsersState } from "./synced-users/reducer";

export interface ApplicationState {
    accessManagement: AccessManagementState;
    auth: AuthState;
    columnMappings: ColumnMappingsState;
    comments: CommentsState;
    configs: ConfigsState;
    connectors: ConnectorsState;
    deleteResources: DeleteResourcesState;
    dryRuns: DryRunsState;
    expressionHelpers: ExpressionHelpersState;
    groupMappings: GroupMappingsState;
    networks: NetworksState;
    notifications: NotificationsState;
    networksRecent: NetworksRecentState;
    organizationalOverview: OrganizationalOverviewState;
    resyncRequests: ResyncRequestsState;
    revisions: RevisionsState;
    roleMappings: RoleMappingsState;
    router: RouterState;
    runs: RunsState;
    syncProtocols: SyncProtocolState;
    syncedUsers: SyncedUsersState;
}

const reducer = combineReducers({
    accessManagement,
    auth,
    columnMappings,
    comments,
    configs,
    connectors,
    deleteResources,
    dryRuns,
    expressionHelpers,
    groupMappings,
    networks,
    networksRecent,
    notifications,
    organizationalOverview,
    resyncRequests,
    revisions,
    roleMappings,
    router: connectRouter(history),
    runs,
    syncProtocols,
    syncedUsers,
});

export default function(state: ApplicationState | undefined, action: Action): ApplicationState {
    if (action.type === SET_ACTIVE_NETWORK) {
        // When we change the active network, we explicitly reset the entire Redux store
        // (except the auth and networks modules), to prevent lingering network-specific data:
        return reducer(
            state
                ? ({
                      auth: state.auth,
                      networks: state.networks,
                      networksRecent: state.networksRecent,
                      router: state.router,
                  } as ApplicationState)
                : undefined,
            action,
        );
    } else {
        return reducer(state, action);
    }
}
