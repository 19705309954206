import { UserSyncUser } from "@speakap/types";

import axios from "./request";
import { removeToken } from "../auth/tokens";

const getUserSyncUser = (): Promise<UserSyncUser> => axios.get("/me").then(({ data }) => data);

const getAuthenticationUrl = (redirectUri: string): Promise<string> =>
    axios
        .get(`/authenticate?redirect_uri=${encodeURIComponent(redirectUri)}`)
        .then(({ data }) => data.url);

const logoutUserSyncUser = (): Promise<void> => axios.get("/logout").then(() => removeToken());

const loginSpeakapUser = (username: string, password: string): Promise<string> =>
    axios
        .post("/speakap-login", {
            password,
            username,
        })
        .then(({ data }) => data.accessToken);

export { getUserSyncUser, getAuthenticationUrl, logoutUserSyncUser, loginSpeakapUser };
