import {
    EnhancedNotification,
    NotificationRequestBody,
    Subscriber,
    SubscriberRequestBody,
    EmailNotificationRequestBody,
    JobPostRequest,
    Job,
    Email,
    JobPatchRequest,
} from "@speakap/types";

import axios from "./request";

interface GetNotificationsResponse {
    notifications: Array<EnhancedNotification>;
}

interface GetSubscribersResponse {
    subscribers: Array<Subscriber>;
}

export const getNotifications = (networkId: string): Promise<GetNotificationsResponse> =>
    axios.get(`/networks/${networkId}/notifications/`).then(({ data }) => data);

export const postNewNotification = (
    notification: NotificationRequestBody,
): Promise<EnhancedNotification> =>
    axios
        .post(`/networks/${notification.networkId}/notifications/`, notification)
        .then(({ data }) => data);

export const updateNotification = (
    notification: EnhancedNotification,
): Promise<EnhancedNotification> =>
    axios
        .put(`/networks/${notification.networkId}/notifications/${notification.id}/`, notification)
        .then(({ data }) => data);

export const deleteNotification = (
    networkId: string,
    notificationId: number,
): Promise<EnhancedNotification> =>
    axios
        .delete(`/networks/${networkId}/notifications/${notificationId}/`)
        .then(({ data }) => data);

export const getSubscribers = (networkId: string): Promise<GetSubscribersResponse> =>
    axios.get(`/networks/${networkId}/subscribers/`).then(({ data }) => data);

export const postNewSubscriber = (
    subscriber: SubscriberRequestBody,
    networkId: string,
): Promise<Subscriber> =>
    axios.post(`/networks/${networkId}/subscribers/`, subscriber).then(({ data }) => data);

export const putSubscriber = (subscriber: Subscriber, networkId: string): Promise<Subscriber> =>
    axios
        .put(`/networks/${networkId}/subscribers/${subscriber.id}`, subscriber)
        .then(({ data }) => data);

export const sendEmailsForNotification = (
    notification: EmailNotificationRequestBody,
): Promise<{ status: number; email: Email }> =>
    axios
        .post(`/networks/${notification.networkId}/emails/`, notification)
        .then(({ status, data }) => ({ email: data, status }));

export const sendScheduleNotification = (
    jobPost: Omit<JobPostRequest, "url" | "type">,
): Promise<Job> =>
    axios
        .post(`/networks/${jobPost.networkId}/notification-jobs/`, jobPost)
        .then(({ data }) => data);

export const patchScheduleNotification = (
    jobPatch: JobPatchRequest,
    networkId: string,
    jobId: string,
): Promise<Job> =>
    axios
        .patch(`/networks/${networkId}/notification-jobs/${jobId}`, jobPatch)
        .then(({ data }) => data);

export const getEmailsNotificationOverview = (
    networkId: string,
    notificationId: number,
): Promise<Array<Email>> =>
    axios
        .get(`/networks/${networkId}/notifications/${notificationId}/emails`)
        .then(({ data }) => data.emails);
