import { ThunkAction } from "redux-thunk";
import { UserSyncUser, SpeakapUserPostPayload } from "@speakap/types";
import { get, sortBy } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { createSpeakapUser, getSpeakapUsers, deleteSpeakapUser, updateSpeakapUser } from "../api";

export const FETCH_SPEAKAP_USERS_REQUEST = "FETCH_SPEAKAP_USERS_REQUEST";
export const FETCH_SPEAKAP_USERS_SUCCESS = "FETCH_SPEAKAP_USERS_SUCCESS";
export const FETCH_SPEAKAP_USERS_ERROR = "FETCH_SPEAKAP_USERS_ERROR";

export const SUBMIT_SPEAKAP_USER_REQUEST = "SUBMIT_SPEAKAP_USER_REQUEST";
export const SUBMIT_SPEAKAP_USER_SUCCESS = "SUBMIT_SPEAKAP_USER_SUCCESS";
export const SUBMIT_SPEAKAP_USER_ERROR = "SUBMIT_SPEAKAP_USER_ERROR";

export const UPDATE_SPEAKAP_USER_REQUEST = "UPDATE_SPEAKAP_USER_REQUEST";
export const UPDATE_SPEAKAP_USER_SUCCESS = "UPDATE_SPEAKAP_USER_SUCCESS";
export const UPDATE_SPEAKAP_USER_ERROR = "UPDATE_SPEAKAP_USER_ERROR";

export const DELETE_SPEAKAP_USER_REQUEST = "DELETE_SPEAKAP_USER_REQUEST";
export const DELETE_SPEAKAP_USER_SUCCESS = "DELETE_SPEAKAP_USER_SUCCESS";
export const DELETE_SPEAKAP_USER_ERROR = "DELETE_SPEAKAP_USER_ERROR";

export const SET_EDIT_SPEAKAP_USER = "SET_EDIT_SPEAKAP_USER";

interface FetchSpeakapUsersRequestAction {
    type: typeof FETCH_SPEAKAP_USERS_REQUEST;
}

interface FetchSpeakapUsersSuccessAction {
    type: typeof FETCH_SPEAKAP_USERS_SUCCESS;
    payload: Array<UserSyncUser>;
}

interface FetchSpeakapUsersErrorAction {
    type: typeof FETCH_SPEAKAP_USERS_ERROR;
}

interface SubmitSpeakapUserRequestAction {
    type: typeof SUBMIT_SPEAKAP_USER_REQUEST;
}

interface SubmitSpeakapUserSuccessAction {
    type: typeof SUBMIT_SPEAKAP_USER_SUCCESS;
    payload: UserSyncUser;
}

interface SubmitSpeakapUserErrorAction {
    type: typeof SUBMIT_SPEAKAP_USER_ERROR;
}

interface UpdateSpeakapUserRequestAction {
    type: typeof UPDATE_SPEAKAP_USER_REQUEST;
}

interface UpdateSpeakapUserSuccessAction {
    type: typeof UPDATE_SPEAKAP_USER_SUCCESS;
    payload: UserSyncUser;
}

interface UpdateSpeakapUserErrorAction {
    type: typeof UPDATE_SPEAKAP_USER_ERROR;
}

interface DeleteSpeakapUserRequestAction {
    type: typeof DELETE_SPEAKAP_USER_REQUEST;
}

interface DeleteSpeakapUserSuccessAction {
    type: typeof DELETE_SPEAKAP_USER_SUCCESS;
    payload: number;
}

interface DeleteSpeakapUserErrorAction {
    type: typeof DELETE_SPEAKAP_USER_ERROR;
}

interface SetEditSpeakapUserAction {
    type: typeof SET_EDIT_SPEAKAP_USER;
    payload: {
        user?: UserSyncUser;
        mode: "new" | "edit" | "closed";
    };
}

export type AccessManagementActions =
    | FetchSpeakapUsersRequestAction
    | FetchSpeakapUsersSuccessAction
    | FetchSpeakapUsersErrorAction
    | SubmitSpeakapUserRequestAction
    | SubmitSpeakapUserSuccessAction
    | SubmitSpeakapUserErrorAction
    | UpdateSpeakapUserErrorAction
    | UpdateSpeakapUserRequestAction
    | UpdateSpeakapUserSuccessAction
    | DeleteSpeakapUserErrorAction
    | DeleteSpeakapUserRequestAction
    | DeleteSpeakapUserSuccessAction
    | SetEditSpeakapUserAction;

type Thunk<Result> = ThunkAction<Result, ApplicationState, void, AccessManagementActions>;

export const setEditSpeakapUser = ({
    user,
    mode,
}: {
    user?: UserSyncUser;
    mode: "new" | "edit" | "closed";
}): SetEditSpeakapUserAction => ({
    payload: {
        mode,
        user,
    },
    type: SET_EDIT_SPEAKAP_USER,
});

export const fetchSpeakapUsers = (networkId: string): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: FETCH_SPEAKAP_USERS_REQUEST });

        const response = await getSpeakapUsers(networkId);
        dispatch({ payload: sortBy(response, "name"), type: FETCH_SPEAKAP_USERS_SUCCESS });
    } catch (error) {
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_SPEAKAP_USERS_ERROR,
        });
    }
};

export const submitSpeakapUser = (
    user: SpeakapUserPostPayload,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: SUBMIT_SPEAKAP_USER_REQUEST });

        const response = await createSpeakapUser(user);
        dispatch({ payload: response, type: SUBMIT_SPEAKAP_USER_SUCCESS });
        notification.success({ message: "User successfully created!" });
    } catch (error) {
        dispatch({ type: SUBMIT_SPEAKAP_USER_ERROR });

        notification.error({
            message: `Error creating user: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const postUpdateSpeakapUser = (
    user: UserSyncUser,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: UPDATE_SPEAKAP_USER_REQUEST });

        const response = await updateSpeakapUser(user);
        dispatch({ payload: response, type: UPDATE_SPEAKAP_USER_SUCCESS });
        notification.success({ message: "User successfully updated" });
    } catch (error) {
        dispatch({ type: UPDATE_SPEAKAP_USER_ERROR });
        notification.error({
            message: `Error updating user: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};

export const postDeleteSpeakapUser = (
    userId: number,
    networkId: string,
): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: DELETE_SPEAKAP_USER_REQUEST });

        await deleteSpeakapUser(userId, networkId);
        dispatch({ payload: userId, type: DELETE_SPEAKAP_USER_SUCCESS });
        notification.success({ message: "User successfully deleted" });
    } catch (error) {
        dispatch({ type: DELETE_SPEAKAP_USER_ERROR });
        notification.error({
            message: `Error delete user: ${get(
                error,
                ["response", "data"],
                error instanceof Error ? error.message : "Error",
            )}`,
        });
    }
};
