import { Network } from "@speakap/types";

import axios from "./request";

interface GetNetworksResponse {
    networks: Array<Network>;
    totalItems: number;
}

export const getNetworks = (limit: number, offset: number): Promise<GetNetworksResponse> =>
    axios
        .get("/networks/", {
            params: { limit, offset },
        })
        .then(({ data }) => data);

export const getNetworkRoles = (networkId: string): Promise<Array<{ id: string; name: string }>> =>
    axios.get(`/networks/${networkId}/roles`).then(({ data }) => data.roles);
