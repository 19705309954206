import { createSelector } from "reselect";

import { ApplicationState } from "../reducer";
import { GroupMappingsState } from "./reducer";

const selectGroupMappings = (state: ApplicationState): GroupMappingsState => state.groupMappings;

export const selectBuilder = createSelector(selectGroupMappings, ({ builder }) => builder);

export const selectBuilderLoadingState = createSelector(
    selectBuilder,
    ({ loadingState }) => loadingState,
);

export const selectUpdateSuggestions = createSelector(
    selectGroupMappings,
    ({ updateSuggestions }) => updateSuggestions,
);

export const selectUpdateSuggestionsLoadingState = createSelector(
    selectUpdateSuggestions,
    ({ loadingState }) => loadingState,
);

export const selectSubjects = createSelector(selectBuilder, ({ subjects }) => subjects);

export const selectObjects = createSelector(selectBuilder, ({ objects }) => objects);

export const selectGroupMappingsItems = createSelector(selectGroupMappings, ({ items }) => items);

const selectGroupMappingsResults = createSelector(selectGroupMappings, ({ results }) => results);

export const selectGroupMappingsList = createSelector(
    [selectGroupMappingsItems, selectGroupMappingsResults],
    (items, results) => results.map(id => items[id]),
);

export const selectGroupMappingsLoadingState = createSelector(
    selectGroupMappings,
    ({ loadingState }) => loadingState,
);

const selectEdit = createSelector(selectGroupMappings, ({ edit }) => edit);

export const selectEditLoadingState = createSelector(
    selectEdit,
    ({ loadingState }) => loadingState,
);

const selectGroups = createSelector(selectGroupMappings, ({ groups }) => groups);

export const selectGroupsItems = createSelector(selectGroups, ({ items }) => items);

const selectGroupsResults = createSelector(selectGroups, ({ results }) => results);

export const selectGroupsList = createSelector(
    [selectGroupsItems, selectGroupsResults],
    (items, results) => results.map(id => items[id]),
);

export const selectGroupsLoadingState = createSelector(
    selectGroups,
    ({ loadingState }) => loadingState,
);

export const selectGroupsNetworkId = createSelector(selectGroups, ({ networkId }) => networkId);

export const selectGroupMappingsNetworkId = createSelector(
    selectGroupMappings,
    ({ networkId }) => networkId,
);

const selectPrepareForm = createSelector(selectGroupMappings, ({ prepareForm }) => prepareForm);

export const selectPrepareFormLoadingState = createSelector(
    selectPrepareForm,
    ({ loadingState }) => loadingState,
);

export const selectPrepareFormScimUsers = createSelector(
    selectPrepareForm,
    ({ scimUsers }) => scimUsers,
);
