import axios, { AxiosRequestConfig } from "axios";

import { getToken } from "../auth/tokens";

const { REACT_APP_USER_SYNC_API } = process.env;

const securedAxios = axios.create({ baseURL: REACT_APP_USER_SYNC_API });

securedAxios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.authorization = `Bearer ${getToken()}`;

    return config;
});

export default securedAxios;
